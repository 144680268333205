<template>
  <div class="input-container">

        <br>
        <br>

        <div class="input-div">
            <input @change="terminosCondiciones()" type="checkbox" name="terminos" ref="terminos" value="terminos">
            <label for="terminos"> Aceptar los <a @click="showModalTerminos()">Términos y Condiciones</a> (*)</label>
            <br>
            <br>


            <transition name="fade">
                <div class="modal-fade" v-if="terminosModal">
                    <div class="modal-content">
                        <br>
                        <br>
                        <h1>CONVENIO DE APERTURA DE CUENTA COMITENTE</h1>
                        <br>
                        <br>


                        <pre class="modalText">
            El/los firmantes al pie de la presente solicita/n la apertura o
            actualización de una cuenta comitente, quedando sujeta a
            aprobación por parte de Allaria Ledesma & Cía. S.A. La
            cuenta comitente operará bajo las siguientes condiciones:
            1) Las firmas, domicilios y demás datos que se encuentran en
            este registro de clientes se consideran válidos y vigentes hasta
            tanto Allaria Ledesma & Cía. S.A. reciba notificación en
            forma fehaciente de la modificación requerida, aun cuando
            la misma haya sido publicada y registrada en los organismos
            pertinentes. Asimismo, el cliente declara bajo juramento que
            la información detallada en los formularios que conforman
            el legajo, es fiel y completa expresión de la verdad.
            2) Allaria Ledesma & Cía. S.A. en su actuación como Agente
            registrado podrá realizar aquellas operaciones detalladas en
            los Capítulos IV y V del Título VI de las Normas N.T. 2013 y
            modificatorias, en Mercados autorizados por la Comisión
            Nacional de Valores. Dichas operaciones, su liquidación,
            tenencias y acreencias serán a través del depósito colectivo de
            los títulos valores del comitente en el depositante del
            Agente de Caja de Valores. Sin perjuicio de esto, el cliente
            podrá administrar sus tenencias directamente en Caja de
            Valores.
            3) Las operaciones por tratativas directas o negociación
            bilateral no garantizada bajo las condiciones establecidas
            por los Mercados en que intervenga Allaria Ledesma & Cía.
            S.A. serán conforme lo establecido en el Titulo VI Capítulo V
            Artículo 3 del texto de las Normas N.T. 2013 y
            modificatorias.
            4) En lo que respecta a operaciones del exterior, las mismas se
            ajustarán a lo establecido en las Normas N.T. 2013 y
            modificatorias. Allaria Ledesma & Cía. S.A. podrá realizar
            operaciones de compra y/o venta de instrumentos
            financieros en el exterior por cuentas de titularidad de la
            sociedad y a pedido del cliente, siempre que cuenten con
            autorización por parte de Comisiones de Valores u otros
            organismos de control extranjeros, que correspondan a
            países incluidos dentro del listado de países cooperadores
            previstos en el artículo 2º del inciso b) del Decreto Nº
            589/2013, para ser comercializados en esas jurisdicciones a
            inversores, acorde al perfil de riesgo del cliente. Dichas
            operaciones no constituyen una orden para el Agente, en
            calidad de tal, sino como mandatario del ordenante. El
            cliente declara conocer que Allaria Ledesma & Cía. S.A.
            ejecutará dichas órdenes a través de un broker del exterior a
            elección del agente, y que la operación resultante no se
            encuentra garantizada por BYMA ni por ningún otro Mercado
            de los autorizados por la Comisión Nacional de Valores, y
            que la misma será cumplida por terceros no sujetos al
            control de la mencionada Comisión. Es por este motivo que
            estas operaciones son reservadas en forma exclusiva a
            Inversores Calificados. Por último, el cliente autoriza a
            Allaria Ledesma & Cía. S.A., a mantener en custodia los
            títulos de su propiedad en cuentas del exterior registradas a
            nombre de la firma, con el objeto de agilizar la operatoria de
            referencia.
            5) El cliente deberá identificar el riesgo que desea afrontar en
            el Perfil de Riesgo del Inversor. Cuando la operatoria a
            realizarse en la cuenta comitente no esté incluida en el nivel
            de riesgo elegido, Allaria Ledesma & Cía. S.A. deberá contar
            con previa autorización por escrito del cliente o su
            convalidación fehaciente. Asimismo, el cliente declara
            conocer los riesgos inherentes de mercado, entendiendo
            que los mismos comprenden: riesgo de variación de precios de
            los activos, riesgo de liquidez, riesgo de crédito del emisor
            (privado o público) o de incumplimiento de pago, riesgo
            cambiario o de moneda y riesgo soberano, incluyendo el riesgo
            de cambios regulatorios o fiscales.
            6) El cliente manifiesta que las instrucciones que imparta a
            Allaria Ledesma & Cía. S.A., se emitirán cumpliendo alguno
            de los siguientes medios o modalidades de contacto:
            presencial, internet, teléfono, correo electrónico, redes
            privadas de comunicación y cualquier otro que pudiera surgir
            en el futuro. La instrucción impartida o la confirmación
            específica tendrá validez diaria. Asimismo, el cliente podrá
            otorgar a Allaria un Mandato de Administración Discrecional
            Total o Parcial para que actúe en su nombre administrando
            sus inversiones y tenencias.
            7) El cliente conserva la facultad de otorgar y/o revocar el
            Mandato de Administración Discrecional Total o Parcial que
            otorgue voluntariamente al Agente para que actúe en su
            nombre. La ausencia de dicho Mandato hace presumir,
            salvo prueba en contrario, que las operaciones realizadas
            por el Agente no contaron con el consentimiento del cliente.
            En caso de no suscribir dicho Mandato, el cliente deberá
            elegir alguna de las modalidades detalladas en el punto
            anterior para solicitar sus operaciones quedando a definición
            de Allaria Ledesma & Cía. S.A. si el medio elegido por el
            cliente le otorga la fuerza probatoria suficiente para su
            ejecución.
            8) En el caso de que la cuenta comitente arroje saldos
            deudores, tanto en pesos como en otras monedas, según lo
            establecido en el Titulo VII Capitulo II Artículo 11 del texto de
            las Normas N.T. 2013 y modificatorias, se autoriza a
            Allaria Ledesma & Cía. S.A. a cobrar recargos sobre
            los saldos monetarios descubiertos, utilizando como
            referencia la tasa de caución tomadora para el plazo de 7 días,
            más impuestos y gastos inherentes a dicha operatoria. Esta
            autorización también tendrá vigencia en el caso de que dichos
            saldos deudores se originen en gastos debitados por el
            Agente incluidos en la Tabla de Aranceles y Comisiones. Sin
            perjuicio de esto, Allaria podrá ejecutar en forma compulsiva
            activos de la cartera del cliente deudor, hasta la concurrencia
            de la deuda.
            9) Los saldos líquidos provenientes de operaciones instruidas
            por el cliente en forma específica, sólo serán reinvertidos a
            su solicitud y se autoriza al Agente a que los mantenga en el
            giro operativo de su actividad como Agente. En caso de ser
            solicitados en forma expresa por el cliente, los fondos
            líquidos serán transferidos a las cuentas bancarias
            declaradas en el acto de apertura de cuenta. En el marco
            de la actuación conforme el mandato de Administración
            Discrecional Total o Parcial y sin perjuicio de lo establecido
            en el Titulo VII, Capitulo II, Artículo 4 del texto de las
            Normas N.T. 2013, el administrador idóneo, invertirá los
            saldos líquidos cuando oportunamente lo considere, siempre
            priorizando el beneficio del cliente y dando cumplimiento a la
            estrategia elegida para dicha cuenta.
            10) El cliente tiene derecho a solicitar el retiro de los saldos
            monetarios disponibles en su cuenta comitente, incluidas
            acreencias depositadas en su subcuenta comitente abierta
            en el Agente de Deposito Colectivo, atendiendo a las
            normas vigentes en relación a los retiros de fondos, y en los
            días y horarios en que Allaria Ledesma & Cía. S.A. preste
            sus servicios. El cliente podrá completar el Instructivo
            Permanente para transferencias de fondos a cuentas de
            misma titularidad, en cuyo caso, deberá solicitar las mismas
            a través de alguna de las direcciones de mail declaradas en
            el formulario de apertura o actualización de cuenta.
            11) Allaria Ledesma & Cía. S.A. presta el servicio de cobro de
            dividendos, rentas, amortizaciones, etc., de títulos valores
            como así también suscripciones, prorrateos y en general
            todo tipo de servicios que hacen a la actividad, sin
            responsabilidad alguna en caso de omisión por parte del
            Agente pagador de los servicios antes mencionados.
            12) El cliente toma conocimiento que podrá consultar a Allaria
            Ledesma & Cía. S.A. si la operatoria realizada en su cuenta
            comitente tiene garantía de algún Mercado autorizado por la
            Comisión Nacional de Valores o Cámara Compensadora en su
            caso. Dicha situación estará expresada en el boleto de la
            operación concertada.
            13) Allaria Ledesma & Cía. S.A. no asegura rendimientos de
            ningún tipo y cuantía ya que las inversiones están sujetas a
            las fluctuaciones de las condiciones y precios de mercado.
            Asimismo, en ningún caso, se garantiza la protección del
            valor aportado a la cuenta comitente.
            14) En virtud del Régimen Informativo con Clientes, según el
            Título VII, Capítulo II, Artículo 17 de las Normas NT 2013 de
            la Comisión Nacional de Valores, el agente enviará un mail
            de notificación automático a la dirección de correo
            electrónico declarada por el/ los firmante/s al finalizar cada
            día en que se produzcan operaciones en la cuenta
            comitente. Dichas operaciones se podrán visualizar, sin
            límite de día y horario, ingresando con un nombre de usuario
            y clave personal en la página Web oficial de Allaria Ledesma
            & Cía. S.A. El cliente podrá solicitar dicha clave desde el
            sitio oficial del Agente cuando lo desee.Cualquier correo
            electrónico declarado por el cliente es vinculante a los efectos
            del envío de la documentación.
            15) El cliente declara bajo juramento, a los efectos de dar
            cumplimiento a la normativa vigente en materia de
            Prevención del Lavado de Dinero y Financiación del Terrorismo
            dictada por la UIF (Unidad de Información Financiera) y la CNV
            (Comisión Nacional de Valores), que la totalidad de los fondos
            y valores utilizados para realizar transacciones a través de
            Allaria Ledesma & Cía. S.A. son de origen lícito. Asimismo,
            el cliente se compromete a aportar toda la información y/o
            documentación que en el futuro le sea requerida por Allaria
            Ledesma & Cía. S.A. en cumplimiento de la citada normativa.
            16) El cliente autoriza a Allaria Ledesma & Cía. S.A. a entregar
            a las entidades financieras intervinientes en la liquidación de
            las operaciones cursadas por el mismo y a los organismos
            de contralor, tanto locales como internacionales,
            documentación requerida por ellos en atención al cumplimiento
            de la normativa vigente, en materia de Prevención del Lavado
            de Dinero y Financiación del Terrorismo o bien a regulaciones
            específicas que deba cumplimentar el Agente. La
            documentación a entregar será según cada caso, la aportada
            por el cliente a Allaria Ledesma & Cía. S.A., en virtud de
            esta solicitud de apertura de cuenta comitente o bien con
            posterioridad a ella en el transcurso de la relación comercial.
            17) En caso de que el cliente solicite el cierre de su cuenta
            comitente, deberá notificar a Allaria Ledesma & Cía. S.A. en
            forma expresa. Sin perjuicio de ello Allaria Ledesma & Cía.
            S.A. se reserva el derecho de proceder al cierre de la cuenta
            ante el incumplimiento por parte del cliente de la normativa
            de Prevención del Lavado de Dinero y Financiación del
            Terrorismo, así como por asuntos de índole comercial en los
            que el Agente crea conveniente discontinuar el vínculo con
            el cliente. En ambos casos Allaria Ledesma & Cía. S.A.
            notificará al cliente el cierre de su cuenta. Asimismo, Allaria
            Ledesma & Cía. S.A. podrá, sin notificar al cliente, cerrar
            cuentas inactivas y/o sin posición. Para proceder al cierre
            de la cuenta, la misma no deberá registrar saldos
            monetarios ni tenencias de títulos valores. Para ello, Allaria
            Ledesma & Cía. S.A. se limitará a liquidar operaciones
            pendientes y transferirá al cliente tenencias y saldos
            resultantes en su cuenta comitente sin que esta decisión
            otorgue al mismo derecho de exigir compensación alguna.
            18) El cliente manifiesta conocer los riesgos que asume ante
            posibles incumplimientos por parte del Agente en materia de
            custodia, operatoria y liquidación de operaciones, así como
            también los organismos a los cuales deberá dirigirse para
            efectuar el reclamo pertinente.
            19) El cliente durante todo el vínculo comercial con Allaria Ledesma
            & Cía. S.A., podrá acercar consultas o inquietudes al Agente
            a través del personal idóneo con el que se relacione en
            forma habitual. Asimismo, podrá formular eventuales reclamos,
            directamente al Responsable de Relaciones con el Público, a
            la dirección de mail responsable.cliente@allaria.com.ar. El
            nombre de dicho funcionario estará disponible en la página
            web, y el cliente podrá solicitar una reunión en forma personal
            si lo considera conveniente.
            20) El cliente se notifica que los costos intervinientes vigentes,
            Tabla de Aranceles y Comisiones y Derechos de Mercado,
            están disponibles para consulta, en el sitio oficial de la
            compañía www.allaria.com.ar.
            21) Allaria Ledesma & Cía. S.A. en su carácter de Agente registrado
            en la Comisión Nacional de Valores, se compromete a cumplir
            con lo establecido en el Título VII, Capítulo II, Artículo 16 del
            Texto de Normas N.T. 2013 referido a las obligaciones del
            Agente.
            22) El cliente autoriza expresamente a Allaria Ledesma y Cía.
            S.A. a compartir su legajo de cliente, así como la
            información y documentación en él contenido, con aquellos
            Agentes Registrados que sean Sujetos Obligados ante UIF que
            formen parte del Grupo ó con los que Allaria Ledesma y Cía.
            S.A. haya celebrado un acuerdo a tales efectos.
            23) En caso de que Allaria Ledesma & Cia S.A. celebre
            acuerdos con RENAPER u organismos similares que
            ofrecen el servicio de validación de datos personales, el
            cliente, en su carácter de titular de los datos presta
            consentimiento para que “Allaria Ledesma & Cia S.A” en su
            carácter de cesionario confronte sus datos personales con la
            base de datos del RENAPER, conforme a las siguientes
            especificaciones:
            a) el presente consentimiento para el tratamiento de los
            datos personales del cliente, alcanza a los incluidos en
            su DNI en confronte con lo que informa el web service
            del RENAPER,
            b) los datos serán tratados con la exclusiva finalidad de
            validar la identidad del cliente y verificar la vigencia de
            su DNI para
            Allaria Ledesma & Cia S.A
            c) Los datos son facilitados con carácter obligatorio, por
            cuanto es imprescindible identificar fehacientemente al
            titular, para asegurar el correcto proceso de identificación.
            d) El titular de los datos podrá ejercer los derechos de
            acceso, rectificación y supresión de sus datos en
            cualquier momento y a su sola solicitud ante el
            RENAPER.
            e) En cumplimiento de la Resolución AAIP Nº 14/2018,
            informamos que la Agencia de Acceso a la Información
            Pública, en su carácter de Órgano de Control de la Ley
            N° 25.326, tiene la atribución de atender las denuncias
            y reclamos que interpongan quienes resulten afectados
            en sus derechos por incumplimiento de las normas
            vigentes en materia de protección de datos personales.
            24) El cliente manifiesta que suscribió convenio con el siguiente
            AAGI:
            Asimismo, el cliente se compromete a informar en forma
            fehaciente a Allaria en caso que la información aquí declarada
            sufra modificaciones.
            25) Se podrá acceder a información y normativa relativa a la
            actividad del agente en las siguientes páginas web:
            • Allaria Ledesma & Cía. S.A. (www.allaria.com.ar)
            • Comisión Nacional de Valores (www.cnv.gob.ar)
            • Mercado de Valores de Buenos Aires
            (www.merval.sba.com.ar)
            • Bolsas y Mercados Argentinos (www.byma.com.ar)
            • Caja de Valores S.A. (www.cajval.sba.com.ar)
            • Bolsa de Comercio de Buenos Aires
            (www.bcba.sba.com.ar)
            • Ministerio de Economía y Finanzas
            Públicas (www.mecon.gov.ar)
            • Banco Central de laRepública Argentina
            (www.bcra.gov.ar)
            26) El cliente declara conocer y aceptar las siguientes Circulares
            del Mercado Argentino de Valores S.A. (MAV) a saber: N°
            808 del 17/08/2010, referente a la negociación de cheques
            de pago diferido patrocinado y avalado, N° 820 del
            14/03/2013, referente a la negociación directa de cheques de
            pago diferido y Circular del 04/12/2019 y modificatorias,
            referente a la negociación de pagaré bursátil. Asimismo declara
            conocer los riesgos de los distintos tipos de operaciones
            detalladas en las mismas, y en caso de solicitar una operación
            de este tipo, se compromete a informar a ALLARIA el
            segmento donde se opere. ALLARIA y MAV no asumen
            responsabilidad alguna respecto del crédito o solvencia de las
            partes.
            27) La relación entre las partes se regirá por la Ley de Mercado
            de Capitales Nº 26.831, el Decreto 1023/11, el texto de
            Normas N.T.
            2013, las normas específicas de los Mercados autorizados
            por la Comisión Nacional de Valores en que opere el agente
            y todas aquellas que sean dictadas en el futuro. Ante
            diferencias de criterios, el cliente y Allaria Ledesma & Cía. S.A.
            se someterán a la jurisdicción de los tribunales
            ordinarios de la Ciudad Autónoma de Buenos Aires.


            Mandato de Administración Discrecional Parcial


            Declaro que las operaciones en mi cuenta comitente N° ……………………. serán instruidas en forma específica
            por mí por alguno de los medios o modalidades de contacto habilitados por Allaria a tal fin -presencial, internet,
            teléfono, correo electrónico, redes privadas de comunicación y cualquier otro que pudiera surgir en el futuro. Serán
            válidas como instrucciones específicas aquellas que indiquen al menos los siguientes parámetros:
            especie/instrumento, cantidad, pecio o rango de precio, incluida la referencia a “precio de mercado”. También será
            considerada como instrucción precisa una confirmación específica posterior, otorgada dentro de las 24 hs. de
            notificada la operación.
            Asimismo, autorizo al personal idóneo de Allaria Ledesma & Cía. S.A. a que ejecute para mi cuenta comitente en
            mi nombre e interés, decisiones de inversión y/o tenencia cuando advierta una oportunidad que encuadre dentro
            del perfil de riesgo elegido por mí suscripto en oportunidad de la apertura de la cuenta comitente o de actualización
            de legajo. Este Mandato comprende la posibilidad de que el Agente actué sin necesidad de que yo imparta orden
            o instrucción específica e individual o consentimiento previo, y se ajusta a las limitaciones que las normas vigentes
            prevén para este tipo de Administración. Asimismo, es extensible a todas las operaciones vigentes, o aquellas que
            puedan surgir en el futuro, reguladas o fiscalizadas por los mercados registrados en la Comisión Nacional de
            Valores, y acordes al Perfil de Riesgo del Inversor por mi suscripto en oportunidad de Apertura o Actualización de
            cuenta comitente.
            Dejo expresa constancia que no habilito a Allaria Ledesma & Cía. S.A. a efectuar retiros de títulos valores, órdenes
            de pago o débito de títulos valores sobre mi cuenta comitente, que no se correspondan con la liquidación de las
            operaciones y acreencias, ni con sus costos operativos.
            Tengo pleno conocimiento que Allaria Ledesma & Cía. S.A. no asegura rendimientos de ningún tipo y cuantía sobre
            las inversiones incluidas en el presente Mandato, ya que las mismas están sujetas a las fluctuaciones de las
            condiciones y de precios de mercado. Asimismo, en ningún caso se me garantiza la protección del valor aportado
            a la cuenta comitente.
            Me notifico en este acto que, en virtud del Régimen Informativo con Clientes, según el Título VII Capítulo II Artículo
            17 de las Normas NT 2013 y modificatorias, el Agente enviará un mail automático a la/s dirección/es de correo
            electrónico declarada/s por mí en la Ficha de Cuenta Comitente al finalizar cada día en que se produzcan
            operaciones en mi cuenta, con el detalle de dichas operaciones. Además, las operaciones las podré visualizar, sin
            límite de día y horario, ingresando con un nombre de usuario y clave personal en la página Web oficial de Allaria
            Ledesma & Cía. S.A.; que podré solicitar desde el sitio oficial del Agente cuando lo desee.
            Declaro conocer los costos detallados en la Tabla de Aranceles y Comisiones, y doy conformidad de sus
            posteriores actualizaciones, que serán debidamente publicadas en la página oficial de la compañía.
            Este mandato se encontrará vigente hasta tanto yo exprese su revocación y/o cancelación anticipada en forma
            escrita.



                        </pre>

                        <br>
                        
                        <button @click="showModalTerminos()" class="cerrar-modal">
                            Ok
                        </button>
                    </div>
                </div>
            </transition>



        </div>

        <div class="input-div">
            <input @change="reglamentoGestion()" type="checkbox" name="acdi" ref="acdi" value="acdi">
            <label for="acdi"> Aceptar el <a @click="showModalAcdi()">Reglamento de gestión</a></label>
            <br>
            <br>

            <transition name="fade">
                <div class="modal-fade" v-if="acdiModal">
                    <div class="modal-content">
                        <br>
                        <br>
                        <h1>Reglamento de gestión (ACDI)</h1>
                        <br>
                        <br>
                        <br>
                        
                        <pre class="modalText">
        Constancia Reglamento de Gestión en Suscripción presencial
        Declaro conocer y aceptar los textos de los reglamentos de gestión de los fondos comunes de inversión
        (FONDOS) que se detallan a continuación, de los cuales recibo copia íntegra del texto vigente, obrando el
        presente como suficiente recibo.
        Tomo conocimiento que estos reglamentos pueden ser modificados, previa autorización de la COMISION
        NACIONAL DE VALORES y publicidad correspondiente, en cuyo caso las nuevas versiones regirán la
        operatoria de los FONDOS a partir de su entrada en vigencia.
        Los textos vigentes de los reglamentos, así como la información adicional sobre los FONDOS, el Agente
        de Administración y el Agente de Custodia, podrán ser consultados en forma gratuita, en todo momento,
        en www.allariafondos.com.ar y/o en www.cnv.gob.ar y/o en www.fondosargentina.org.ar
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA AHORRO FCI
        APROBADO/ POR RESOLUCIONES CNV N° 15.895 (5/6/08) y 19.118 (24/11/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA AHORRO PLUS FCI APROBADO/S POR
        RESOLUCIONES CNV N° 16.252 (6/1/10) y 19.118 (24/11/17) y DISPOSICIÓN CNV N° 12 (15/5/19).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA FONDO COMÚN DE INVERSION ABIERTO PYMES
        FCI APROBADO/S POR RESOLUCIONES CNV N° 16.089 (19/3/09) y 19.208 (21/12/17), y POR DISPOSICIÓN CNV Nº
        44 (2/12/19).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA ACCIONES FCI
        APROBADO/S POR RESOLUCIONES CNV N° 15.893 (5/6/08) y 20.246 (22/5/19), y DISPOSICIÓN CNV N° 6 (13/4/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA MIXTA II FCI
        APROBADO/S POR RESOLUCIONES CNV N° 16.022 (4/12/08) y 18.184 (11/8/16).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA DESARROLLO FONDO COMÚN DE INVERSIÓN
        ABIERTO PARA PROYECTOS PRODUCTIVOS DE ECONOMÍAS REGIONALES E INFRAESTRUCTURA FCI
        APROBADO/S POR RESOLUCIONES CNV N° 16.466 (30/11/10), 19.208 (21/12/17) y 20.356 (24/7/19).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA FIJA FCI
        APROBADO/S POR RESOLUCIONES CNV N° 16.466 (30/11/10) y 19.208 (21/12/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA MIXTA FCI
        APROBADO/S POR RESOLUCIONES CNV N° 16.633 (12/8/11) y 19.208 (21/12/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA BALANCEADA I FCI
        APROBADO/S POR RESOLUCIONES CNV N° 17.139 (29/7/13) y 19.208 (21/12/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA BALANCEADA II FCI
        APROBADO/S POR RESOLUCIONES CNV N° 17.609 (20/2/15) y 19.208 (21/12/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA GESTION FCI
        APROBADO/S POR RESOLUCIONES CNV N° 17.871 (5/11/15) y 19.208 (21/12/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA SINCERAMIENTO FISCAL LEY
        27.260 FCI APROBADO/S POR RESOLUCIÓN CNV N° 18.223 (22/09/16).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA RENTA DÓLAR LEY 27.260 FCI
        APROBADO/S POR RESOLUCIÓN CNV Nº 18.222 (22/9/16) y DISPOSICIÓN CNV N° 12 (15/5/19). 
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA DIVERSIFICADO FCI
        APROBADO/S POR RESOLUCIONES CNV N° 18.525 (23/2/17) y 19.349 (8/02/18).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA LATAM FCI
        APROBADO/S POR RESOLUCIONES CNV N° 18.526 (23/2/17) y 19.348 (08/02/18).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA ESTRATÉGICO FCI
        APROBADO/S POR RESOLUCIÓN CNV N° 18.828 (29/06/17).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA DÓLARES PLUS FCI APROBADO/S POR
        RESOLUCIONES CNV N° 18.913 (31/8/17) y 20.246 (22/5/19) y POR DISPOSICIÓN CNV Nº 6 (13/4/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA ESTRUCTURADO FCI
        APROBADO/S POR RESOLUCIONES CNV N° 18.828 (29/6/17) y 20.246 (22/5/19).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA CRECIMIENTO FONDO COMÚN DE INVERSIÓN
        ABIERTO PYMES FCI APROBADO/ POR RESOLUCIONES CNV N° 20.436 (189/19) y 20.636 (5/2/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA CAPITAL FCI APROBADO/ POR RESOLUCION
        CNV N° 20.435 (18/9/19).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA DINÁMICO FCI
        APROBADO/S POR RESOLUCIÓN CNV N° 20.764 (18/8/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA PATRIMONIO FCI
        APROBADO/S POR RESOLUCIÓN CNV N° 20.859 (5/11/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: ALLARIA PRINCIPAL FCI
        APROBADO/S POR RESOLUCIÓN CNV N° 20.859 (5/11/20).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: INTERVALORES AHORRO APROBADO/S POR
        RESOLUCIÓN CNV N° 21.025 (11/3/21).
        DENOMINACIÓN FONDO/S COMUN/ES DE INVERSIÓN: INTERVALORES TOTAL RETURN APROBADO/S POR
        RESOLUCIÓN CNV N° 21.025 (11/3/21).
        Las inversiones en cuotas del FONDO no constituyen depósitos en BANCO COMAFI S.A., así como
        tampoco
        en BANCO DE VALORES S.A., a los fines de la Ley de Entidades Financieras ni cuentan con ninguna
        de las garantías que tales depósitos a la vista o a plazo puedan gozar de acuerdo a la legislación y
        reglamentación aplicables en materia de depósitos en entidades financieras. Asimismo, BANCO
        COMAFI S.A. y BANCO DE VALORES S.A. se encuentran impedidos por normas del BANCO
        CENTRAL DE LA REPÚBLICA ARGENTINA de asumir, tácita o expresamente, compromiso alguno
        en cuanto al mantenimiento, en cualquier momento, del valor del capital invertido, al rendimiento,
        al valor de rescate de las cuotapartes o al otorgamiento de liquidez a tal fin.
                        </pre>
                        <br>
                        
                        <button @click="showModalAcdi()" class="cerrar-modal">
                            Ok
                        </button>
                    </div>
                </div>
            </transition>

        </div>

        <!-- <div class="input-div">
            <input @change="mandatoComafi()" type="checkbox" name="comafi" ref="comafi" value="comafi">
            <label for="comafi"> Aceptar el <a @click="showModalComafi()">Mandato Banco Comafi</a></label>
            <br>
            <br>

            <transition name="fade">
                <div class="modal-fade" v-if="comafiModal">
                    <div class="modal-content">
                        <br>
                        <br>
                        <h1>Mandato Allaria Ledesma & Cía SA - Banco Comafi</h1>
                        <br>
                        <br>
                        
                        <pre class="modalText">
                        </pre>
                        <br>
                        
                        <button @click="showModalComafi()" class="cerrar-modal">
                            Ok
                        </button>
                    </div>
                </div>
            </transition>

        </div> -->


        <!-- <div class="input-div">
            <input @change="mandatoValores()" type="checkbox" name="valores" ref="valores" value="valores">
            <label for="valores"> Aceptar el <a @click="showModalValores()">Mandato Banco de Valores</a></label>
            <br>
            <br>

            <transition name="fade">
                <div class="modal-fade" v-if="valoresModal">
                    <div class="modal-content">
                        <br>
                        <br>
                        <h1>Mandato Allaria Ledesma & Cía SA - Banco de Valores </h1>
                        <br>
                        <br>
                        
                        <pre class="modalText">
                        </pre>
                        <br>
                        
                        <button @click="showModalValores()" class="cerrar-modal">
                            Ok
                        </button>
                    </div>
                </div>
            </transition>

        </div> -->
        
        
        
        <div class="input-div">
            <input @change="retencionComisiones()" type="checkbox" name="retencion" ref="retencion" value="retencion">
            <label for="retencion"> Aceptar la <a @click="showModalRetencion()">Nota por retención de <br>comisiones SGR</a></label>
            <br>
            <br>

            <transition name="fade">
                <div class="modal-fade" v-if="retencionModal">
                    <div class="modal-content">
                        <br>
                        <br>
                        <h1>Negociación Bursatil de Instrumentos Avalados por S.G.R </h1>
                        <br>
                        <br>
                        
                        <pre class="modalText">
        Sres.
        ALLARIA LEDESMA & CIA S.A.
        25 de Mayo 359 Piso 12
        CABA
        Presente
        Ref.: Negociación Bursátil de Instrumentos Avalados por S.G.R. / Retención -
        Instructivo Permanente
        De nuestra consideración,
        Nos dirigimos a Uds., en virtud de la relación comercial que mantenemos con
        Sociedades de Garantías Recíprocas, para la negociación de Instrumentos Avalados por éstas.
        Con el objeto de cumplir con el pago de los siguientes conceptos:

        ✓ Comisión a SGR por Otorgamiento de Aval,
        ✓ Comisión Administrativa a la SGR por Instrumentos avalados (de
        corresponder),
        ✓ Instrumentos avalados Rechazados (de corresponder);

        expresamente autorizamos a Uds. a retener de nuestra cuenta comitente N° ……………………….
        abierta en vuestra entidad, el monto de dichos conceptos originados en la operatoria de
        Instrumentos Avalados por SGR, solicitando que los mismos sean transferidos hacia la Sociedad
        de Garantía Recíproca que corresponda, ya sea en su cuenta comitente, o en valores a
        depositar con cláusula no a la orden, o bien en cuenta bancaria de la Sociedad de Garantía
        Recíproca.
        Se entienden por Instrumentos Avalados a aquellos descriptos por la Secretaría de
        la Pequeña y Mediana Empresa y Desarrollo Regional en su normativa vigente, y los que surjan
        en el futuro.
        Por otra parte, solicitamos expresamente tengan a bien compartir a la Sociedades
        de Garantías Recíprocas que corresponda, nuestro legajo de cliente obrante en vuestra
        entidad, así como la información y documentación en él contenido. El motivo de esta solicitud
        radica en simplificar la gestión de apertura y actualización, evitar la duplicación en la
        información aportada y optimizar la tarea de cumplimiento, atento ser cliente de ambas
        entidades.
        Sin más, saludamos atte.

                        </pre>
                        <br>
                        
                        <button @click="showModalRetencion()" class="cerrar-modal">
                            Ok
                        </button>
                    </div>
                </div>
            </transition>

        </div>

            <br>

            <div class="input-div">
                <input @change="aceptarTodos($event)" type="checkbox" id="todos" name="todos" value="todos" ref="todos">
                <label for="todos"> Aceptar Todos</label>
                <br>
                <br>
            </div>


        <div class="obligatorio-container">
            <p class="obligatorio">
                (*) Obligatorio
            </p>
        </div>
        
  </div>
</template>

<script>
export default {
    data(){
        return{
            terminosAllaria: false,
            acdi: false,
            comafi: false,
            valores: false,
            comisiones: false,
            todos: false,
            terminosModal: false,
            acdiModal: false,
            comafiModal: false,
            valoresModal: false,
            retencionModal: false,
        }
    },
    methods:{
        showModalTerminos(){
            if (this.terminosModal == false) {
                this.terminosModal = true
            } else {
                this.terminosModal = false
            }
        },
        showModalAcdi(){
            if (this.acdiModal == false) {
                this.acdiModal = true
            } else {
                this.acdiModal = false
            }
        },
        showModalComafi(){
            if (this.comafiModal == false) {
                this.comafiModal = true
            } else {
                this.comafiModal = false
            }
        },
        showModalValores(){
            if (this.valoresModal == false) {
                this.valoresModal = true
            } else {
                this.valoresModal = false
            }
        },
        showModalRetencion(){
            if (this.retencionModal == false) {
                this.retencionModal = true
            } else {
                this.retencionModal = false
            }
        },
        terminosCondiciones(){
            if(this.terminosAllaria == false){
                this.terminosAllaria = true
            } else {
                this.terminosAllaria = false
            }
            this.checkTodos()
        },
        reglamentoGestion(){
            if(this.acdi == false){
                this.acdi = true
            } else {
                this.acdi = false
            }
            this.checkTodos()
        },
        mandatoComafi(){
            if(this.comafi == false){
                this.comafi = true
            } else {
                this.comafi = false
            }
            this.checkTodos()
        },
        mandatoValores(){
            if(this.valores == false){
                this.valores = true
            } else {
                this.valores = false
            }
            this.checkTodos()
        },
        retencionComisiones(){
            if(this.comisiones == false){
                this.comisiones = true
            } else {
                this.comisiones = false
            }
            this.checkTodos()
        },
        aceptarTodos(e){

            if (e.target.checked == true) {
                this.$refs.terminos.checked = true
                this.$refs.acdi.checked = true
                // this.$refs.comafi.checked = true
                // this.$refs.valores.checked = true
                this.$refs.retencion.checked = true

                this.terminosAllaria = true
                this.comisiones = true
                // this.valores = true
                // this.comafi = true
                this.acdi = true

                this.checkTodos()
            } else {
                this.$refs.terminos.checked = false
                this.$refs.acdi.checked = false
                // this.$refs.comafi.checked = false
                // this.$refs.valores.checked = false
                this.$refs.retencion.checked = false
                
                this.terminosAllaria = false
                this.comisiones = false
                // this.valores = false
                // this.comafi = false
                this.acdi = false

                this.checkTodos()

            }
        },
        checkTodos(){
            if (this.terminosAllaria == false){
                this.$emit("checkTerminos", false)
            } else {
                this.$emit("checkTerminos", true)
                // this.$emit("terminosChequeados", {terminos_condiciones: this.terminosAllaria, reglamento_gestion: this.acdi, mandato_comafi: this.comafi, mandato_valores: this.valores, nota_retencion: this.comisiones })
                this.$emit("terminosChequeados", {terminos_condiciones: this.terminosAllaria, reglamento_gestion: this.acdi, nota_retencion: this.comisiones })
            }

        },
    },
    // watch:{
    //     terminosAllaria: function(){
    //         if (this.$refs.terminos.checked == true){
    //             this.$emit("checkTerminos", true)
    //         } else {
    //             this.$emit("checkTerminos", false)
    //         }
    //     }
    // }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.modal-content{
    width: 35%;
    height: 700px;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.modalText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    height: 500px;
    overflow-y: scroll;
    white-space: pre-wrap;
}


*{
  font-family: 'Lato', sans-serif;
  font-size: 13.5px;
  font-weight: bolder;
}

a{
    color: #0044cc;
}

a:hover{
    text-decoration: underline;
}

label{
    text-align:left;
}

.input-container{
    width: 300px;
    margin:0 auto;
    display: flex;
    flex-direction: column;
}

.input-div{
    display: flex;
    justify-content: flex-start;
}

.obligatorio-container{
    display: flex;
    width: 300px;
    margin: 0 auto;
    justify-content: left;
}

.obligatorio{
    color: #e63d3d;
}

@media only screen and (max-width: 845px) {

    .modal-content{
        width: 90%;
    }

}

@media only screen and (max-width: 340px){
    .input-container{
        width: 215px;
    }
    .obligatorio-container{
        width: 215px;
    }
    .cerrar-modal{
        width: 215px;
    }
}
</style>