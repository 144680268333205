<template>
  <div>
      <Navbar/>
      <router-view/>
      <button @click="mailtoAllaria()" class="envio-mail">
        <fa icon="envelope" class="fa-2x" />
      </button>
  </div>
</template>

<script>
import Navbar from '../src/components/Navbar.vue'
export default{
  components:{
    Navbar
  },
  methods: {
    mailtoAllaria(){
      window.location.href = 'mailto:empresas@allaria.com.ar';
    }
  }
}
</script>


<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html{
  background-image: url('assets/fondo.png');
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

p, span, h1, h2, h3, h4, h5, h6, div{
  text-align: center;
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}



.loadingcomponent-container-standard{
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 0px;
    transform: translate(-50%);
    padding-top: 200px;
    height: 2000%;
}

.loading-standard{
    width: 80%;
}

.envio-mail{
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: #3498da;
  color: #fff;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  border: 0px;
}

@media only screen and (max-width: 1170px) {
  .loadingcomponent-container-standard{
    width: 100%;
  }
}


@media only screen and (max-width: 845px) {
  .loadingcomponent-container-standard{
    width: 100%;
  }

}

@media only screen and (max-width: 600px) {
  .loadingcomponent-container-standard{
    width: 100%;
    margin-top: 0;
  }
}

</style>
