<template>

  <div class="confirmacionDatos">



    <div class="comp-container">

          

      <h1>
        Confirmación de Datos
      </h1>

        <p class="nombreEmpresa">
          Nombre de la empresa
        </p>
        <h6 class="actividad">Actividad: actividad cambiaria</h6>
      
        <p class="email">Email: andy.glustman@allaria.com.ar</p>


        <div class="apoderados-container">
          <p class="text-apoderados">
            Apoderados
          </p>

          <!-- <input v-model="rubroSearch" class="rubroSearch" :placeholder="'Buscar...'" > -->
        </div>

        <div class="lista-apoderados">
          <div style="width: 100%; display: flex; justify-content: space-between;" v-for="apoderado in filteredApoderados" :key="apoderado.descripcion">
            <button @click="elegirActividad($event)" ref="{{ apoderado.descripcion }}" class="item-apoderado">{{ apoderado.descripcion }}</button><div><button><fa icon="edit" @click="openDialogApoderados()" style="color: #E8DD65;" /></button></div>
          </div>
        </div>

    
 

        












        <div class="apoderados-container">
          <p class="text-apoderados">
            Accionistas
          </p>

          <!-- <input v-model="rubroSearch" class="rubroSearch" :placeholder="'Buscar...'" > -->
        </div>

        <div class="lista-apoderados">
          <div style="width: 100%; display: flex; justify-content: space-between;" v-for="accionista in filteredAccionistas" :key="accionista.descripcion">
            <button @click="elegirActividad($event)" ref="{{ accionista.descripcion }}" class="item-apoderado">{{ accionista.descripcion }}</button><div><button><fa icon="edit" @click="openDialogAccionistas()" style="color: #E8DD65;" /></button></div>
          </div>
        </div>








        <!-- <div class="confError" v-show="confError">
          <span class="confErrorTest">El código ingresado ha expirado<br>o no es correcto</span>
        </div>   -->

      <!-- <button @click="getConfirmarMail()" class="confirmar-mail">
        Confirmar
      </button> -->

    </div>

    
    <!-- <loading v-if="loading" /> -->
    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loadingShow">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" :src="'/images/loading.gif'" alt="">
                </div>
            </div>
        </div> 
    </transition>
    
      

    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-confDatos" v-if="dialogApoderados">
            <div class="modalEditarApoderado">

                <h2 class="tituloApoderado">Editar Apoderado</h2>
                
                  <div style="width: 300px; margin: 0 auto; display: flex; flex-direction: column; margin-bottom: 20px;">
                    <div>
                      <input v-model="nuevoDniApoderado" class="nuevoInputMedium" :placeholder="'DNI'">
                      <input v-model="nuevoNombreApoderado" class="nuevoInputMedium" :placeholder="'Nombre/s'">
                      <input v-model="nuevoApellidoApoderado" class="nuevoInputMedium" :placeholder="'Apellido/s'">
                    </div>
                    <div>
                      <input v-model="nuevoCalleApoderado" class="nuevoInputMedium" :placeholder="'Calle'">
                      <div style="display: flex; justify-content: space-between;">
                        <input v-model="nuevoNumeroApoderado" class="nuevoInputSmall" :placeholder="'Número'">
                        <input v-model="nuevoPisoApoderado" class="nuevoInputSmall" :placeholder="'Piso'">
                        <input v-model="nuevoDptoApoderado" class="nuevoInputSmall" :placeholder="'Depto'">
                      </div>
                    </div>
                    <div>
                      <!-- <input v-model="nuevoProvinciaApoderado" class="nuevoInputMedium" :placeholder="'Provincia'"> -->
                      <input type="text" v-model="nuevoProvinciaApoderado" class="nuevoInputMedium" placeholder="Provincia">

                      <div class="provincia-container">
                        <div style="display: flex;" v-for="provincia in filteredProvincias" :key="provincia.descripcion">
                          <button @click="elegirProvincia($event)" ref="{{ provincia.descripcion }}" class="provincia">{{ provincia.descripcion }}</button>
                        </div>
                      </div>
                      <input v-model="nuevoLocalidadApoderado" class="nuevoInputMedium" :placeholder="'Localidad'">
                    </div>
                    <input v-model="nuevoCpApoderado" class="nuevoInputMedium" :placeholder="'Código Postal'">
                    <div style="display: flex; justify-content: space-between;">
                      <input v-model="nuevoCodAreaApoderado" class="nuevoInputSmall" :placeholder="'Cod área'">
                      <input v-model="nuevoNroTelefonoApoderado" class="nuevoInputSmallMedium" :placeholder="'Nro de telefono'">
                    </div>
                    <input v-model="nuevoEmailApoderado" class="nuevoInputMedium" :placeholder="'Email'">
                  </div>


                  <button @click="openDialogApoderados()" class="confirmar">
                    Confirmar
                  </button>


                
            </div>
        </div> 
    </transition>
















        <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-confDatos" v-if="dialogAccionistas">
            <div class="modalEditarApoderado">
                
               <h2 class="tituloApoderado">Editar Apoderado</h2>
                
                  <div style="width: 300px; margin: 0 auto; display: flex; flex-direction: column; margin-bottom: 20px;">
                    <div style="display: flex; justify-content: space-between;">

                        <select v-model="selectTipo" @change="selectTipoDoc($event)" name="tipoDoc" class="tipoDoc" ref="tipoDoc">
                          <option value="">Tipo</option>
                          <option value="cuit">Cuit</option>
                          <option value="cuil">Cuil</option>
                          <option value="nit">Nit</option>
                      </select>

                      <fa ref="caretArrow" class="drop-arrow" icon="caret-down" />

                      <input v-model="nuevoDniApoderado" class="nuevoInputSmallMedium" :placeholder="'CUIT/CUIL/NIT'">
                      
                    </div>
                    <input v-model="nuevoNombreApoderado" class="nuevoInputMedium" :placeholder="'Nombre'">
                    <div>
                      <div style="display: flex;">
                          <select v-model="selectTipoPersona" @change="handlerTipoPersona($event)" name="tipoPersona" class="tipoPersona" ref="tipoPersona">
                              <option value="">Tipo de persona</option>
                              <option value="J">Persona Jurídica</option>
                              <option value="F">Persona Física</option>
                          </select>
                          <fa ref="caretArrowPersona" class="drop-arrowP" icon="caret-down" />
                      </div>
                      <input v-model="nuevoNumeroApoderado" class="nuevoInputMedium" :placeholder="'Porc. Participación'">
                    </div>
                  </div>


                  <button @click="openDialogAccionistas()" class="confirmar">
                    Confirmar
                  </button>

            </div>
        </div> 
    </transition>






  </div>
</template>

<script>

export default {
  data() {
        return {
            // rubroSearch: '',
            token: false,
            actividades: [],
            accionistas: [],
            provincias: [],
            dialogApoderados: false,
            dialogAccionistas: false,

            nuevoDniApoderado: '',
            nuevoNombreApoderado: '',
            nuevoApellidoApoderado: '',
            nuevoCalleApoderado: '',
            nuevoNumeroApoderado: '',
            nuevoPisoApoderado: '',
            nuevoDptoApoderado: '',
            nuevoProvinciaApoderado: '',
            nuevoLocalidadApoderado: '',
            nuevoCpApoderado: '',
            nuevoCodAreaApoderado: '',
            nuevoNroTelefonoApoderado: '',
            nuevoEmailApoderado: '',

            tipoDoc: false,
            selectTipo: '',
            selectTipoPersona: '',
            loadingShow: false,
        }
    },
    mounted() {


      if (localStorage.token) {
        this.token = localStorage.token;
      }

      localStorage.accionistas = false;



      const optionsActividades = {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
        url: `https://bkpj.allaria.com.ar/api/v1/lista-actividades/`,
      };
      this.axios(optionsActividades)
        .then((result) => {
          this.actividades = result.data.results
      })
      .catch(() => {
        this.loadingShow = false
      })








      const optionsAccionistas = {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
        url: `https://bkpj.allaria.com.ar/api/v1/lista-actividades/`,
      };
      this.axios(optionsAccionistas)
        .then((result) => {
          this.accionistas = result.data.results
      })
      .catch(() => {
        this.loadingShow = false
      })


      const optionsProvincias = {
          method: 'get',
          headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
          url: `https://bkpj.allaria.com.ar/api/v1/provincias/`,
        };
        this.axios(optionsProvincias)
          .then((result) => {
            this.provincias = result.data.results
        })
        .catch(() => {
          this.loadingShow = false
        })
    },
    methods: {
      elegirActividad(e){
      let actividadElegida = e.target.innerText
      console.log(actividadElegida)
    },
    openDialogApoderados(){
        
        if (this.dialogApoderados == true){
            this.dialogApoderados = false
        } else {
            this.dialogApoderados = true
        }
        
    },
    openDialogAccionistas(){
        
        if (this.dialogAccionistas == true){
            this.dialogAccionistas = false
        } else {
            this.dialogAccionistas = true
        }
        
    }
    },
    computed:{
        filteredApoderados(){
        // return this.actividades.filter(actividad => {
        //     return actividad.descripcion.toLowerCase().match(this.rubroSearch.toLowerCase())
        // })

        return this.actividades
        },
        filteredAccionistas(){
        // return this.actividades.filter(actividad => {
        //     return actividad.descripcion.toLowerCase().match(this.rubroSearch.toLowerCase())
        // })

        return this.accionistas
        },

        filteredProvincias(){
          return this.provincias.filter(provincia => {
            return provincia.descripcion.toLowerCase().match(this.nuevoProvinciaApoderado.toLowerCase())
          })
        }
        
    },
    watch: {
        selectTipoPersona: function(){
            this.tipoAccionista = this.selectTipoPersona

            // if (this.tipoAccionista == "J"){
            //     // this.nombreAccionista
            //     // this.apellidoAccionista = "-"
            //     this.placeholderNombre = 'Razón social del Accionista'
            //     this.persona_api = "ORGANISATION"
            // } else {
            //     // this.apellidoAccionista = ""
            //     this.placeholderNombre = 'Nombre/s del accionista'
            //     this.persona_api = "INDIVIDUAL"
            // }
        }
    }
}








</script>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }
  
.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}

.modal-fade-confDatos{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 100px;
}

  .comp-container{
    padding-top: 20px;
    min-height: 700px;
    width: 30%;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nombreEmpresa{
    font-size: 25px;
    padding-top: 30px;
    margin: 0 auto;
    width: 300px;
    text-align: left;
    font-weight: bold;
  }

  .email{
    margin: 0 auto;
    width: 300px;
    text-align: left;
    margin-top: 20px;
    font-weight: bold;
  }

  .apoderados-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  .text-apoderados{
    width: 320px;
    color: #000000;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

  .rubroSearch{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
  }

  .lista-apoderados{
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .lista-apoderados::-webkit-scrollbar {
      width: 8px;
  }

  .lista-apoderados::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
      border-radius: 10px;
  }

  .lista-apoderados::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  }

  .actividad{
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
    width: 300px;
    margin: 0 auto;
  }

  .item-apoderado{
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

  .item-apoderado:hover{
    text-decoration: underline;
  }

  .modalEditarApoderado{
      background-color: #FBFBFB;
      width: 450px;
  }


  .tituloApoderado{
    margin: 30px;
  }

  .nuevoInputSmall{
    margin-top: 10px;
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
  }
  
  .nuevoInputMedium{
    margin-top: 10px;
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
  }

  .nuevoInputSmallMedium{
    margin-top: 10px;
    width: 190px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
  }

  .tipoDoc{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    margin-right: 5px;
}

  .confirmar{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    margin-bottom:20px;
  }


  .provincia-container{
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}

  .provincia{
    /* width: 320px; */
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

  .provincia:hover{
    text-decoration: underline;
  }

.provincia-container::-webkit-scrollbar {
    width: 12px;
}

.provincia-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
    border-radius: 10px;
}

.provincia-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
}


.tipoPersona{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    /* margin-right: 5px; */
}

.drop-arrow{
    position: absolute;
    margin-top: 25px;
    margin-left: 65px;
    pointer-events: none;
}

.drop-arrowP{
    position: absolute;
    margin-top: 25px;
    margin-left: 270px;
    pointer-events: none;
}


@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){
    
  .comp-container{
      overflow-x: hidden;
  }
  .text-apoderados{
      width: 215px;
  }
  .rubroSearch{
      width: 215px;
  }
  .lista-apoderados{
    width: 215px;
  }
  .drop-arrow{
        margin-left: 42px;
    }
  .drop-arrowP{
        margin-left: 190px;
    }
}

</style>
