<template>

  <div class="paso1">



    <div class="comp-container">

          

      <h1>
        Paso 1
      </h1>

      <span class="titulo">
        Validación del mail del apoderado
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
      </div>


      


      <div class="mail-container">
        <span class="errorMail">{{ errorMail }}</span>

        
        <!-- <input v-model="email" class="email" :placeholder="'Email del Firmante o Apoderado'" @change.passive="validarEmail()"> -->
        <input :disabled="desactivar" v-model="email" class="email" :placeholder="'Email del Firmante o Apoderado'" >
      </div>

      <div class="ref-container">
        <span class="ref">
          ¿Ud. fue referido por alguien<br>de la compañía?
        </span>

        <ToggleButton :disabled="desactivar" v-on:change.passive="getEsReferido" />
      </div>

      <div class="referente-container">
        <span class="errorReferente">{{ errorReferente }}</span>
        <!-- <input v-model="referente" v-show="referidoActive" class="referido" :placeholder="'Referido por'" @change.passive="validarReferente()" > -->
        <input :disabled="desactivar" v-model="referente" v-show="referidoActive" class="referido" :placeholder="'Nombre y apellido de quien lo refirió'" >
      </div>

      <div v-show="envioConfirmacion" class="conf-container">
        <span class="text-conf">
          Le enviamos un e-mail a “{{ email }}” con un código de confirmación. Por favor escríbalo aquí:
        </span>

        <input v-model="codConfirmacion" class="codConfirmacion" :placeholder="'Código de confirmación'" >


        <div class="confError" v-show="confError">
          <span class="confErrorTest">El código ingresado ha expirado<br>o no es correcto</span>
        </div>
      </div>

      <div class="confError" v-show="errorMailDuplicado">
        <span class="confErrorTest">El email ya esta asociado con una empresa. Por favor, comuníquese con <a @click="mailto()" class="link">empresas@allaria.com.ar</a></span>
      </div>      

      <button v-show="showButton" @click="getConfirmarMail()" class="confirmar-mail">
        Confirme su Email
      </button>

      <p class="disclosure">
        Este sitio está protegido por reCAPTCHA y Google, las Políticas de Privacidad y Términos de estos aplican.
      </p>

      <p class="ingresar">
        ¿Ya tiene su cuenta? Ingrese <a class="link-ingresa" @click="ingresa()">aquí</a>
      </p>

    </div>

    
    <!-- <loading v-if="loading" /> -->
    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loading">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" :src="'/images/loading.gif'" alt="">
                </div>
            </div>
        </div> 
    </transition>
    
      

  </div>
</template>

<script>

import ToggleButton from '../components/ToggleButton.vue'
// import loading from '../components/loadings/loading.vue'

export default {
  data() {
        return {
            referidoActive: false,
            email: null,
            errorMail: null,
            desactivar: false,
            referente: null,
            errorReferente: null,
            envioConfirmacion: null,
            validaciones: null,
            codConfirmacion: null,
            showButton: true,
            confError: false,
            token: false,
            error: null,
            result: null,
            sData: null,
            loading: false,
            errorMailDuplicado: false,
        }
    },
    components: {
      ToggleButton,
      // loading
    },
    mounted() {


      if (localStorage.token) {
        this.token = localStorage.token;
      }

      localStorage.accionistas = false;
    },
    methods: {
      
      mailto(){
        window.location.href = 'mailto:empresas@allaria.com.ar';
      },
      ingresa(){
        window.location.href = 'https://www.allaria.com.ar';
      },
      getEsReferido() {
        if (this.referidoActive == false){
          this.referidoActive = true
        } else {
          this.referidoActive= false
          this.errorReferente = null
          this.referente = null
        }
      },
      getConfirmarMail(){

      this.axios.get('https://bkpj.allaria.com.ar/api/v1/buscar-email/?email=' + this.email)
      .then(() => {
        // if (result.data.error){
        //   this.errorMailDuplicado = true
        // } else {
        //   this.errorMailDuplicado = false
        // }
        // if (result.data.message){
          
          const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          const email = this.email
          const matchesEmail = regexEmail.exec(email)

          // const regexRef = /^[a-zA-Z ]+$/
          const regexRef = /^([a-zñáéíóúA-Z]+[\s]*)+$/
          const referente = this.referente
          const matchesRef = regexRef.exec(referente)

          this.validaciones = null

          if (matchesRef == null){
            this.errorReferente = "Referente inválido"
            this.validaciones = true
          }
          else {
            this.errorReferente = null
          }

          if (matchesEmail == null){
            this.errorMail = "Email inválido"
            this.validaciones = true
          } else {
            this.errorMail = null
          }

          if (this.validaciones == null) {
            // alert("Validado")
            this.envioConfirmacion = true
            this.desactivar = true
            this.showButton = false
            this.registrarMail()
          }
        // }

        this.loading = false
      })
        
        

      },
      async registrarMail(){
        this.loading = true

        let payload = {
          email: this.email
        }
        this.axios.post('https://bkpj.allaria.com.ar/auth/email/', payload)

        localStorage.emailApoderado = this.email
      },
    },
    watch: {
      codConfirmacion: function(){

        if (this.codConfirmacion.length >= 6){
          let payload = {
            token: this.codConfirmacion,
            email: this.email
          }
          this.axios.post('https://bkpj.allaria.com.ar/auth/token/', payload)
          .then(response => {

            this.token = response.data["token"]

            localStorage.setItem('token', response.data["token"])

            this.confError = false

            if (this.referente == null){
              this.$router.push('/Paso2')
            } else {
              
              let data = {nombre: this.referente}

              const options = {
                method: 'POST',
                data,
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                url: `https://bkpj.allaria.com.ar/api/v1/referidos/`,
              };
              this.axios(options)
                .then((result) => {
                  this.result = result
                  this.$router.push('/Paso2')
              })
              .catch((error) => {
                this.error = error
              })
            }

            
          })
          .catch(error => {
            this.confError = true
            var data = error.response.data
            this.sData = data
          })
        }
        
      },
    }
}







</script>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }
  
.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}

  .comp-container{
    padding-top: 20px;
    min-height: 700px;
    width: 30%;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
  }

  .dot-div{
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dot{
    color: #0C5297;
    opacity: 0.4;
    width: 10px;
  }

  .this-dot{
    color: #0C5297;
    width: 10px;
  }

  .mail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .errorMail{
    color: red;
    margin: 0 auto;
    width: 300px;
    font-size: 14px;
    text-align: left;
  }

  .email{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
  }

  .ref-container{
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .ref{
    font-size: 13px;
    color: #000000;
    font-weight: 600;
    text-align: left;
  }

  .referente-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .errorReferente{
    color: red;
    margin: 0 auto;
    width: 300px;
    font-size: 14px;
    text-align: left;
  }

  .referido{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 25px;
  }

  .codConfirmacion{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
  }

  .conf-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-conf{
    width: 320px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
  }

  .confirmar-mail{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
  }


  .disclosure{
    color: #999999;
    font-size: 12px;
    width: 300px;
    margin: 0 auto;
  }

  .ingresar{
    font-size: 13px;
    color: #000000;
    width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 20px;
  }

.confError{
  width: 300px;
  margin: 0 auto;
  align-items: flex-start;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: rgba(12, 82, 151, 0.1);
  overflow: visible;
  padding-top:15px;
  padding-bottom:15px;
}


.confErrorTest{
  width: 300px;
  color: #e63d3d;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.link-ingresa{
  color: #0C5297;
}

.link-ingresa:hover{
  text-decoration: underline;
}

.link{
  color: #0C5297;
}

@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){
    .errorMail{
      width: 215px;
    }
    .email{
      width: 215px;
    }
    .ref-container{
      width: 215px;
    }
    .ref{
      width: 100px;
    }
    .errorReferente{
      width: 215px;
    }
    .referido{
      width: 215px;
    }
    .codConfirmacion{
      width: 215px;
    }
    .confirmar-mail{
      width: 215px;
    }
    .disclosure{
      width: 215px;
    }
    .ingresar{
      width: 215px;
    }
    .text-conf{
      width: 215px;
    }
    .confError{
      width: 215px;
    }
    .confErrorTest{
      width: 215px;
    }
    .comp-container{
        overflow-x: hidden;
    }
}

</style>
