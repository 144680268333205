<template>
    <div class="datos-container">

    <div class="nombreApellido">
      <span class="data"><b>Nombre</b><br>{{ nombre }}</span>
      <span class="data"><b>Apellido</b><br>{{ apellido }}</span>
    </div>
    
    <br>

    <span class="data"><b>Fecha de nacimiento</b><br>{{ fechaDeNacimiento }}</span>
    <br>
    <span class="data"><b>Lugar de nacimiento</b><br>{{ lugarNacimiento }}</span>
    <br>
    <span class="data"><b>Nacionalidad</b><br>{{ nacionalidad }}</span>
    <br>
    <span v-show="!nuevoDomicilio" class="data"><b>Domicilio</b><br>{{ domicilio }}</span>
    <br>

    <div v-show="nuevoDomicilio" class="nuevoDomicilio">
        <input type="text" v-model="newCalle" class="newCalle" placeholder="Calle *">

        <div class="direccion-container">
            <input type="text" v-model="newNumero" :maxlength="5" class="newNumero" placeholder="Número *">
            <input type="text" v-model="newPiso" :maxlength="3" class="newPiso" placeholder="Piso">
            <input type="text" v-model="newDpto" :maxlength="5" class="newDpto" placeholder="Dpto.">
        </div>

        <input type="text" v-model="newProvincia" class="newProvincia" placeholder="Provincia *">

        <div class="provincia-container">
          <div style="display: flex;" v-for="provincia in filteredProvincias" :key="provincia.descripcion">
            <button @click="elegirProvincia($event)" ref="{{ provincia.descripcion }}" class="provincia">{{ provincia.descripcion }}</button>
          </div>
        </div>

        <input type="text" v-model="newLocalidad" class="newLocalidad" placeholder="Localidad *">
        <input type="text" v-model="newCP" :maxlength="9" class="newCP" placeholder="Código Postal *">


    </div>

    <cuitApoderado v-on:nuevoCuit="nuevoCuit($event)" v-if="newCuit" style="margin-top:8px;" />

    <button  class="correcto" @click="domicilioCorrecto()">
      Ok
    </button>

    <button class="editarDom" @click="cambiarDomicilio()">Volver sin editar</button>

    <div class="sid-container">
      <img class="sid" src="../assets/sid.png" alt="">
    </div>


    <div class="disclosure-container">
      <span class="disclosure">Por favor, verifique que los datos sean correctos, de lo contrario comuníquese con RENAPER</span>
    </div> 





     <transition name="fade">
        <div class="modal-fade" v-show="vacio">
            <div class="modal-content-completar">

                <p class="completar">
                    {{ errorCambio }}
                </p>

                <button @click="cerrarModalError()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>





    </div>     
</template>

<script>
import cuitApoderado from '../components/cuitApoderado.vue'

export default {
    name: 'dataPersonal',
    props: {
        nombre: {
            type: String
        },
        apellido: {
            type: String
        },
        fechaDeNacimiento: {
            type: String
        },
        lugarNacimiento: {
            type: String
        },
        nacionalidad: {
            type: String
        },
        domicilio: {
            type: String
        },
        nuevoDomicilio: {
            type: Boolean,
            default: true
        },
        newCuit: {
            type: Boolean
        }
    },
    mounted() {

      this.token = localStorage["token"]

        const options = {
          method: 'get',
          headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
          url: `https://bkpj.allaria.com.ar/api/v1/provincias/`,
        };
        this.axios(options)
          .then((result) => {
            this.provincias = result.data.results
        })
        .catch(() => {
          this.loadingShow = false
        })
    },
    computed:{
      filteredProvincias(){
        return this.provincias.filter(provincia => {
          return provincia.descripcion.toLowerCase().match(this.newProvincia.toLowerCase())
        })
      }
    },
    data(){
        return{
            newCalle: '',
            newNumero: '',
            newPiso: '',
            newDpto: '',
            newProvincia: '',
            newLocalidad: '',
            newCP: '',
            validacion: false,
            vacio: false,
            errorCambio: "Por favor, verifique haber completado todos los campos antes de avanzar.",
            token: "",
            provincias: [],

        }
    },
    methods:{
        nuevoCuit(e){
          this.$emit("triggerNuevoCuit", e)
        },
        scrollToTop() {
          window.scrollTo(0,0);
        },
        cerrarModalError(){
          this.vacio = false
        },
        elegirProvincia(e){
          let provinciaElegida = e.target.innerText
          this.newProvincia = provinciaElegida
        }, 
        cambiarDomicilio(){

            this.$emit("cambiarDomicilio")
            
        },
        domicilioCorrecto(){

            // this.validacion

            if (this.newCalle == "") {
              this.scrollToTop()
              this.vacio = true
              this.errorCambio = "Por favor, verifique haber completado todos los campos antes de avanzar"
            }
            if (this.newNumero == "") {
              this.scrollToTop()
              this.vacio = true
              this.errorCambio = "Por favor, verifique haber completado todos los campos antes de avanzar"
            }
            // if (this.newPiso == "") {
            //   this.newPiso = "-"
            // }
            // if (this.newDpto == "") {
            //   this.newDpto = "-"
            // }
            if (this.newProvincia == "") {
              this.scrollToTop()
              this.vacio = true
              this.errorCambio = "Por favor, verifique haber completado todos los campos antes de avanzar"
            }
            if (this.newLocalidad == "") {
              this.scrollToTop()
              this.vacio = true
              this.errorCambio = "Por favor, verifique haber completado todos los campos antes de avanzar"
            }
            if (this.newCP == "") {
              this.scrollToTop()

              this.vacio = true
              this.errorCambio = "Por favor, verifique haber completado todos los campos antes de avanzar"
            }


            if (isNaN(this.newNumero)) {
              this.vacio = true
              this.errorCambio = "El campo Número no debe contener letras ni símbolos"
              this.scrollToTop()
            }
            // if (isNaN(this.newPiso)){
            //   this.vacio = true
            //   this.errorCambio = "El campo Piso no debe contener letras ni símbolos"
            //   this.scrollToTop()
            // }

            var exists = this.provincias.some(provincia => {
              return provincia.descripcion === this.newProvincia
            });

            if (exists == false){
              this.vacio = true
              this.errorCambio = "Por favor, seleccione una de las provincias en las opciones de provincia."
              this.scrollToTop()
              this.newProvincia = ''
            }


            if (this.vacio == true){
              this.validacion = false
            } else {
              this.validacion = true
            }


            if (this.validacion == true){

              let data = {
                calle: this.newCalle,
                numero: this.newNumero,
                piso: this.newPiso,
                dpto: this.newDpto,
                provincia: this.newProvincia,
                localidad: this.newLocalidad,
                cp: this.newCP
              }
              this.$emit("domicilioCorrecto", data)
            }

            

            
        }
    },
    components:{
        cuitApoderado
    }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
*{
  font-family: 'Lato', sans-serif;
}
.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.modal-content-completar{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.completar{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}



.datos-container{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;
  margin-top: 20px;
}

.nombreApellido{
  display: flex;
  width: 300px;
  margin: 0 auto;
  justify-content: space-between;
}

.data{
  font-size: 14px;
  color: #000000;
  text-align: left;
}

.correcto{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.newCalle {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.direccion-container{
    display: flex;
    width: 300px;
    justify-content: space-between;
    margin-top: 8px;
}

.newNumero{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newPiso{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newDpto{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newProvincia {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.newLocalidad {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.newCP {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}


.editarDom{
    font-weight: bold;
    font-size: 13px;
    /* text-decoration-line: underline; */
    color: #0C5297;
    width: 300px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
    padding-bottom: 20px;
}

.editarDom:hover{
  text-decoration: underline;
}

.sid-container{
  width: 300px;
  display: flex;
  margin: 0 auto;
  align-items: left;
}

.sid{
  width: 60%;
}

.disclosure-container{
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}

.disclosure{
  color: #000000;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
}


.provincia-container{
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}

  .provincia{
    /* width: 320px; */
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

  .provincia:hover{
    text-decoration: underline;
  }

.provincia-container::-webkit-scrollbar {
    width: 12px;
}

.provincia-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
    border-radius: 10px;
}

.provincia-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
}


@media only screen and (max-width: 845px) {
    .modal-content-completar{
        width: 90%;
    }
}


@media only screen and (max-width: 340px){
    .provincia-container{
      width: 215px;
    }

    .datos-container{
      width: 215px;
    }
    
    .nombreApellido{
      width: 215px;
    }

    .correcto{
      width: 215px;
    }

    .editarDom{
      width: 215px;
    }

    .sid-container{
      width: 215px;
    }

    .disclosure-container{
        width: 215px;
    }

    .newCalle {
        width: 215px;
    }

    .direccion-container{
        width: 215px;
    }

    .newNumero{
        width: 67px;
    }
    .newPiso{
        width: 67px;
    }
    .newDpto{
        width: 67px;
    }

    .newProvincia {
        width: 215px;
    }

    .newLocalidad {
        width: 215px;
    }

    .newCP {
        width: 215px;
    }

    .cerrar-modal{
      width: 200px;
    }

}

</style>