<template>
  <div class="comp-container">

        <div class="logo-container">
            <img class="allaria-img" src="../assets/allaria-transparent-background.png" alt="">
        </div>


        <div class="pep-container">
            <div class="dropdown-button">
                <p ref="preguntaPep" class="preguntaT-pep">¿Es una Persona expuesta políticamente (PEP)?*</p>
            </div>

            <div class="opciones-pep">

                <button ref="pep1" @click="pepSelect($event)" class="opcion-pep" value="no">No</button>
                <button ref="pep2" @click="pepSelect($event)" class="opcion-pep" value="si">Si</button>

                <p class="pregunta-pep">*¿No sabe que es PEP? <a class="clickAca" @click="modalPepShow()"><button><fa icon="question-circle" /></button></a></p>

                <input v-if="pepMotivoShow" class="motivoPep" type="text" placeholder="¿Cuál es el motivo por el que se considera PEP?" v-model="motivoPep">
                <br>
            </div>
        </div>
  
        <button :disabled="confirmarButton" @click="confirmacion()"  ref="confbutton" class="confirmar">
            Confirmar
        </button>

        <transition name="fade">
        <div class="modal-fade" v-show="modalPep">
            <div class="modal-content-pep">
                <br>
                <h2>¿Qué es ser PEP?</h2>
                <br>
                <br>

                <p class="pepText">
                                        Resoluciones 134/2018 de la UIF y modificatorias.<br><br>

                                        <br>ARTÍCULO 1º.- PERSONAS EXPUESTAS POLITICAMENTE EXTRANJERAS.<br>
                                        <br>a. Jefe de Estado, jefe de Gobierno, Gobernador, Intendente, Ministro, Secretario, Subsecretario de Estado u otro cargo gubernamental equivalente.
                                        <br>b. Miembro del Parlamento, Poder Legislativo, o de otro órgano de naturaleza equivalente.
                                        <br>c. Juez, Magistrado de Tribunales Superiores u otra alta instancia judicial, o administrativa, en el ámbito del Poder Judicial.
                                        <br>d. Embajador o cónsul, de un país u organismo internacional.
                                        <br>e. Autoridad, apoderado, integrantes del órgano de administración o control y miembros relevantes de partidos políticos extranjeros.
                                        <br>f. Oficial de alto rango de las fuerzas armadas (a partir de coronel o grado equivalente en la fuerza y/o país de que se trate) o de las fuerzas de seguridad pública (a partir de comisario o rango equivalente según la fuerza y/o país de que se trate).
                                        <br>g. Miembro de los órganos de dirección y control de empresas de propiedad estatal.
                                        <br>h. Miembro de los órganos de dirección o control de empresas de propiedad privada o mixta; cuando el Estado posea una participación igual o superior al VEINTE POR CIENTO (20%) del capital o del derecho a voto, o ejerza de forma directa o indirecta el control de la compañía.
                                        <br>i. Director, gobernador, consejero, síndico o autoridad equivalente de bancos centrales y otros organismos de regulación y/o supervisión.
                                        <br>j. Director, subdirector; miembro de la junta, directorio, alta gerencia, o cargos equivalentes, apoderados, representantes legales o autorizados, de una organización internacional, con facultades de decisión, administración o disposición.
                                        <br>ARTÍCULO 2º.- PERSONAS EXPUESTAS POLITICAMENTE NACIONALES.<br>
                                        <br>a. Presidente o Vicepresidente de la Nación.
                                        <br>b. Senador o Diputado de la Nación.
                                        <br>c. Magistrado del Poder Judicial de la Nación.
                                        <br>d. Magistrado del Ministerio Público de la Nación.
                                        <br>e. Defensor del Pueblo de la Nación o Defensor del Pueblo Adjunto.
                                        <br>f. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo Nacional.
                                        <br>g. Interventor federal, o colaboradores del interventor federal con categoría no inferior a Director o su equivalente.
                                        <br>h. Síndico General de la Nación o Síndico General Adjunto de la Sindicatura General de la Nación; Presidente o Auditor General de la Auditoría General de la Nación; autoridad superior de un ente regulador o de los demás órganos que integran los sistemas de control del sector público nacional; miembros de organismos jurisdiccionales administrativos, o personal de dicho organismo, con categoría no inferior a la de director o su equivalente.
                                        <br>i. Miembro del Consejo de la Magistratura de la Nación o del Jurado de Enjuiciamiento.
                                        <br>j. Embajador o Cónsul.
                                        <br>k. Personal de las Fuerzas Armadas, de la Policía Federal Argentina, de Gendarmería Nacional, de la Prefectura Naval Argentina, del Servicio Penitenciario Federal o de la Policía de Seguridad Aeroportuaria con jerarquía no menor de coronel o grado equivalente según la fuerza.
                                        <br>l. Rector, Decano o Secretario de las Universidades Nacionales.
                                        <br>m. Funcionario o empleado con categoría o función no inferior a la de Director General o Nacional, de la Administración Pública Nacional, centralizada o descentralizada, de entidades autárquicas, bancos y entidades financieras del sistema oficial, de las obras sociales administradas por el Estado, de empresas del Estado, las sociedades del Estado y el personal con similar categoría o función, designado a propuesta del Estado en sociedades de economía mixta, sociedades anónimas con participación estatal o en otros entes del sector público.
                                        <br>n. Funcionario o empleado público nacional encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                                        <br>o. Funcionario público de algún organismo de control de servicios públicos, con categoría no inferior a la de Director General o Nacional.
                                        <br>p. Personal del Poder Legislativo de la Nación, con categoría no inferior a la de Director.
                                        <br>q. Personal del Poder Judicial de la Nación o del Ministerio Público de la Nación, con categoría no inferior a Secretario.
                                        <br>r. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                                        <br>s. Funcionario público responsable de administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                                        <br>t. Director o Administrador de alguna entidad sometida al control externo del Honorable Congreso de la Nación, de conformidad con lo dispuesto en el artículo 120 de la Ley Nº 24.156.
                                        <br>ARTÍCULO 3º.- PERSONAS EXPUESTAS POLITICAMENTE PROVINCIALES, MUNICIPALES Y DE LA CIUDAD AUTONOMA DE BUENOS AIRES.<br>
                                        <br>a. Gobernador o Vicegobernador, Intendente o Vice-intendente, Jefe de Gobierno o Vicejefe de Gobierno.
                                        <br>b. Ministro de Gobierno, Secretario, Subsecretario, Ministro de los Tribunales Superiores de Justicia de las provincias o de la Ciudad Autónoma de Buenos Aires.
                                        <br>c. Juez o Secretario de los Poderes Judiciales Provinciales o de la Ciudad Autónoma de Buenos Aires.
                                        <br>d. Magistrado perteneciente al Ministerio Público, o su equivalente, en las provincias o en la Ciudad Autónoma de Buenos Aires.
                                        <br>e. Miembro del Consejo de la Magistratura o del Jurado de Enjuiciamiento, o su equivalente, de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                                        <br>f. Defensor del Pueblo o Defensor del Pueblo Adjunto, en las Provincias o en la Ciudad Autónoma de Buenos Aires.
                                        <br>g. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                                        <br>h. Legislador provincial, municipal o de la Ciudad Autónoma de Buenos Aires.
                                        <br>i. Máxima autoridad de los organismos de control o de los entes autárquicos provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                                        <br>j. Máxima autoridad de las sociedades de propiedad de los estados provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                                        <br>k. Rector, Decano o Secretario de universidades provinciales.
                                        <br>l. Funcionario o empleado público encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                                        <br>m. Funcionario de organismos de control de los servicios públicos provinciales o de la Ciudad de Buenos Aires, con categoría no inferior a la de Director General o Provincial.
                                        <br>n. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                                        <br>o. Funcionario público que tenga por función administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                                        <br>ARTÍCULO 4º.- OTRAS PERSONAS EXPUESTAS POLITICAMENTE.<br>
                                        <br>a. Autoridad, apoderado, candidato o miembro relevante de partidos políticos o alianzas electorales, ya sea a nivel nacional o distrital, de conformidad con lo establecido en las Leyes N° 23.298 y N° 26.215.
                                        <br>b. Autoridad de los órganos de conducción de organizaciones sindicales y empresariales (cámaras, asociaciones y otras formas de agrupación corporativa). El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio sindical.
                                        <br>c. Autoridad, representante legal o integrante de la Comisión Directiva de las obras sociales contempladas en la Ley Nº 23.660. El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio de las obras sociales.
                                        <br>d. Las personas humanas con capacidad de decisión, administración, control o disposición del patrimonio de personas jurídicas privadas en los términos del 148 del Código Civil y Comercial de la Nación, que reciban fondos públicos destinados a terceros.
                                        <br>ARTÍCULO 5º.- PERSONAS EXPUESTAS POLITICAMENTE POR CERCANÍA O AFINIDAD.<br>
                                        <br>a. Cónyuge o conviviente reconocido legalmente.
                                        <br>b. Familiares en línea ascendente, descendente, y colateral hasta el tercer grado de consanguinidad o afinidad.
                                        <br>c. Personas allegadas o cercanas: debe entenderse como tales a aquellas personas públicas y comúnmente conocidas por su íntima asociación a la persona definida como Persona Expuesta Políticamente.
                                        <br>d. Personas con las cuales se hayan establecido relaciones jurídicas de negocios del tipo asociativa, aún de carácter informal, cualquiera fuese su naturaleza.
                                        <br>e. Toda otra relación o vínculo que por sus características y en función de un análisis basado en riesgo, a criterio del sujeto obligado, pueda resultar relevante.
                                    </p>

                                    <button @click="modalPepShow()" class="cerrar-modal">
                                        Ok
                                    </button>

                                </div>
                            </div>
                    </transition>
      </div>

      
</template>

<script>
export default {
    data(){
        return{
            modalPep: false,
            pep: null,
            pepMotivoShow: false,
            motivoPep: '',
            confirmarButton: true
        }
    },
    methods:{
        pepSelect(e){
            this.$refs.pep1.style.color = "#000";
            this.$refs.pep2.style.color = "#000";

            this.$refs.confbutton.style.backgroundColor = "#0C5297";
            this.confirmarButton = false

            let elementValue = e.target.getAttribute('value')
            this.$refs.preguntaPep.style.color = "#5BABBE"
            e.target.style.color = "#5BABBE"

            if(elementValue == "si"){
                this.pepMotivoShow = true
            } else {
                this.pepMotivoShow = false
            }

            this.pep = elementValue
        },
        modalPepShow(){
            if (this.modalPep == true) {
                this.modalPep = false
            } else {
                this.modalPep = true
            }
        },
        confirmacion(){
            console.log("wololo")
        }
    }
}
</script>

<style scoped>
*{
    font-family: 'Lato', sans-serif;
    color: #212121;
  }
  

  .modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.comp-container{
    padding-top: 20px;
    width: 30%;
    min-height: 700px;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .tituloh4{
    align-self: flex-start;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
    font-size: 14px;
    font-weight: bolder;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
  }

  .subtitulo{
    font-weight: 600;
    letter-spacing: 0.14em;
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
    margin: 0 auto;
  }

  .titulodos{
    letter-spacing: 0.14em;
    font-size: 14px;
    font-weight: bolder;
    margin-left: 50px;
    margin-right: 50px;
  }

  .subtitulodos{
    font-weight: 600;
    letter-spacing: 0.14em;
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
    margin: 0 auto;
  }

  .tagstyle{
    color: #000;
  }

  .logo-container{
      width: 300px;
      margin: 0 auto;
      margin-top: 40px;
  }

.allaria-img{
      width: 300px;
      margin-bottom: 80px;
  }

  .allaria{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-top: 40px;
    margin-bottom:20px;
  }

  .logos-container{
    width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    padding-bottom: 40px;
  }

  .iconosociales{
    height: 40px;
    width: 40px;
  }

  .modal-content-pep{
    width: 35%;
    height: 700px;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}
.pepText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    height: 500px;
    overflow-y: scroll;
}
.motivoPep{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    align-self: center;
}
.pep-container{
    width: 300px;
    margin: 0 auto;
}

.preguntaT-pep{
    width: 280px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.pregunta-pep{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.opciones-pep{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-pep{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-pep:hover{
    text-decoration: underline;
}
.clickAca{
    color: #0C5297;
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.confirmar{
    background-color: #6a839c;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}


@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
  .modal-content-pep{
        width: 90%;
    }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

}

@media only screen and (max-width: 340px){
  .logos-container{
    width: 215px;
  }
  .allaria{
    width: 215px;
  }

  .comp-container{
      overflow-x: hidden;
  }
  .motivoPep{
        width: 215px;
    }
    .preguntaT-pep{
        width: 215px;
    }
    .pep-container{
        width: 215px;
    }
    .opciones-pep{
        width: 215px;
        margin: 0 auto;
    }

    .motivoPep{
        width: 215px;
    }
    .cerrar-modal{
      width: 200px;
    }
}

</style>