<template>
    <div class="cuit-container">
        <span class="cuitInvalido">{{ nuevoCuitInvalido }}</span>
        <input v-model="cuit" class="cuit" :placeholder="'CUIT/CUIL'" @change="buscarCuit()" >
    </div>
</template>

<script>
export default {
    data(){
        return{
          cuit: null,
          nuevoCuitInvalido: ''
        }
    },
    methods: {
        buscarCuit(){
          let validacionCuit = this.validarCuit(this.cuit)

          if (validacionCuit == true){
            this.nuevoCuitInvalido = null
            this.nuevoCuit()
          } else {
            this.nuevoCuitInvalido = "CUIT inválido"
          }
        },
        validarCuit(sCUIT){
            var aMult = '5432765432';
            aMult = aMult.split('');
            
            
            if (sCUIT)
            {
                var aCUIT = sCUIT.split('');
                // aCUIT = aCUIT.filter(function(value, index, arr){
                aCUIT = aCUIT.filter(function(value){ 
                    return value != '-';
                });
            }

            if (sCUIT && aCUIT.length == 11) {
                var iResult = 0;
                var i = 0
                for (i = 0; i <= 9; i++)
                {
                    iResult += aCUIT[i] * aMult[i];
                }
                iResult = (iResult % 11);
                iResult = 11 - iResult;

                if (iResult == 11)
                    iResult = 0;
                if (iResult == 10)
                    iResult = 9;

                if (iResult == aCUIT[10] || sCUIT == "___")
                {
                    return true;
                }
            }
            return false;
        },
        nuevoCuit(){
          this.$emit("nuevoCuit", this.cuit)
        }
    }
}
</script>

<style scoped>
  .cuitCuil-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

.cuit{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
}

.cuitInvalido{
    color: red;
    margin: 0 auto;
    width: 300px;
    font-size: 14px;
    text-align: left;
}

@media only screen and (max-width: 340px){

  .cuit{
    width: 215px;
  }

  .cuitInvalido{
      width: 215px;
  }

}
</style>