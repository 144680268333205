<template>
  <div>
        <div class="residencia-container">

            <div>
                <input v-model="paisSearch" class="paisSearch" :placeholder="'Buscar...'" >

                <div class="opciones-residencia">
                    <div class="div-opciones" v-for="pais in filteredPaises" :key="pais.id">
                        <button class="opcion-residencia" @click="elegirPais($event)" :value="pais.descripcion" >{{ pais.descripcion }}</button>
                    </div>
                </div>

                <div class="paisesElegidos">
                    <h4 v-if="showElegidos" style="text-align: left;">Paises elegidos</h4>
                    <div v-for="paisElegido in arrayPaisesElegidos" :key="paisElegido.id" class="elegido">
                        <p class="paisElegido">{{ paisElegido.name }}</p>
                        <button><fa v-on:click.once="borrarPais(paisElegido.name)" class="timesPais" icon="times-circle" /></button>
                    </div>
                </div>

                <button @click="enviarPaises()" class="cerrar-modal">
                    Ok
                </button>
                
            </div>
        </div>
  </div>


</template>

<script>

export default {
    data(){
        return{
            paisSearch: '',
            paises: [],
            arrayPaisesElegidos: [],
            showElegidos: false,
            contador: 0
        }
    },
    computed:{
        filteredPaises(){
            return this.paises.filter(pais => {
                return pais.descripcion.toLowerCase().match(this.paisSearch.toLowerCase())
            })
        }
    },
    mounted() {             
      this.token = localStorage["token"]


        const options = {
          method: 'get',
          headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
          url: `https://bkpj.allaria.com.ar/api/v1/paises/`,
        };
        this.axios(options)
          .then((result) => {
            this.paises = result.data.results
        })
    },
    methods:{
        modalRiesgoSiShow(){
            this.$emit("modalRiesgoSiShow")
        },
        borrarPais(e){
            for (let i = 0; i <= this.arrayPaisesElegidos.length; i++) {
                if (this.arrayPaisesElegidos[i]["name"] == e) {
                    this.arrayPaisesElegidos.splice(i, 1)
                }
            }
        },
        elegirPais(e) {
            this.paisSearch = e.target.innerText

            this.arrayPaisesElegidos.push({"id": this.contador,"name": this.paisSearch})

            this.contador += 1

            this.paisSearch = ''
            this.showElegidos = true 
        },
        enviarPaises(){
            var nombres = []
            for (let i = 0; i < this.arrayPaisesElegidos.length; i++) {
                let nombre = this.arrayPaisesElegidos[i]["name"];
                nombres.push(nombre)
            }
            
            this.$emit("residenciaElegida", nombres)
            this.$emit("modalRiesgoSiShow")
        }
    },
    watch:{
        paisSearch: function(){
            
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.opciones-residencia::-webkit-scrollbar {
    width: 12px;
}

.opciones-residencia::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
    border-radius: 10px;
}

.opciones-residencia::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
}

.residencia-container{
    width: 300px;
    padding-bottom: 20px;
    margin: 0 auto;
}

.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pregunta-residencia{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
    border-bottom: 1px solid #999999;
    padding-bottom: 10px;
}

.paisSearch{
  width: 300px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 20px;
}

.opciones-residencia{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 150px;
    overflow-y: scroll;
}


.div-opciones{
    display: flex;
}

.opcion-residencia{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-residencia:hover{
    text-decoration: underline;
}

.continuar{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}





.paisesElegidos::-webkit-scrollbar {
    width: 12px;
}

.paisesElegidos::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
    border-radius: 10px;
}

.paisesElegidos::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153); 
}

.paisesElegidos{
    margin-top: 20px;
    height: 170px;
    overflow-y: auto;
}

.elegido{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
}

.paisElegido{
    font-size: 12px;
    font-weight: 600;
}

.timesPais{
    color: #E8DD65;
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media only screen and (max-width: 340px){
    .residencia-container{
        width: 215px;
    }

    .pregunta-residencia{
        width: 215px;
    }

    .paisSearch{
        width: 215px;
    }

    .continuar{
      width: 215px;
    }
    .cerrar-modal{
    width: 150px;
  }

}
</style>