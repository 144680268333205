import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Paso1 from '../views/Paso1.vue'
import Paso2 from '../views/Paso2.vue'
import Paso3 from '../views/Paso3.vue'
import Paso4 from '../views/Paso4.vue'
import Paso5 from '../views/Paso5.vue'
import composicionAccionaria from '../views/composicionAccionaria.vue'
import Paso6 from '../views/Paso6.vue'
import confirmacionDatos from '../views/confirmacionDatos.vue'
import daruma from '../views/daruma.vue'
import listo from '../views/listo.vue'
import confirmacion from '../views/confirmacion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Paso1',
    component: Paso1
  },
  {
    path: '/Paso2',
    name: 'Paso2',
    component: Paso2
  },
  {
    path: '/Paso3',
    name: 'Paso3',
    component: Paso3
  },
  {
    path: '/Paso4',
    name: 'Paso4',
    component: Paso4
  },
  {
    path: '/Paso5',
    name: 'Paso5',
    component: Paso5
  },
  {
    path: '/paso5-accionistas',
    name: 'ComposicionAccionaria',
    component: composicionAccionaria
  },
  {
    path: '/Paso6',
    name: 'Paso6',
    component: Paso6
  },
  {
    path: '/confirmacion-datos',
    name: 'confirmacionDatos',
    component: confirmacionDatos
  },
  
  {
    path: '/ultimos-pasos',
    name: 'ultimos-pasos',
    component: daruma
  },
  {
    path: '/listo',
    name: 'listo',
    component: listo
  },
  {
    path: '/confirmacion',
    name: 'confirmacion',
    component: confirmacion
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
