<template>

  <div class="paso5">

    <div class="comp-container">

      <h1>
        Paso 5
      </h1>

      <span class="titulo">
        Datos de la Persona Jurídica
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
      </div>

      <!-- <residencia v-on:residenciaElegida="residenciaElegida($event)" /> -->

      <!-- <composicionAccionaria v-on:agregarAccionista="agregarAccionista($event)" /> -->


    <div class="grupo-container">

        <div class="dropdown-button" @click="dropGrupo()">
            <p ref="preguntaGrupo" class="pregunta-grupo">¿La empresa integra algún grupo económico?</p><fa icon="chevron-down" v-if="chevronDownGrupo" /><fa icon="chevron-up" v-if="chevronUpGrupo" />
        </div>

        <div v-show="dropContentGrupo" class="opciones-grupo">
            <button style="text-align: left; padding: 0px;"><fa class="grupo-question" @click="modalGrupoShow()" icon="question-circle" /></button>
            <br>
            <button ref="grupo1" @click="grupoSelect($event)" class="opcion-grupo" value="true">Si</button>
            <button ref="grupo2" @click="grupoSelect($event)" class="opcion-grupo" value="false">No</button>

        </div>
    </div>




    <div class="estructura-container">

        <div class="dropdown-button" @click="dropEstructura()">
            <p ref="preguntaEstructura" class="pregunta-estructura">¿La empresa es una estructura societaria compleja?</p><fa icon="chevron-down" v-if="chevronDownEstructura" /><fa icon="chevron-up" v-if="chevronUpEstructura" />
        </div>

        <div v-show="dropContentEstructura" class="opciones-estructura">
            <button style="text-align: left; padding: 0px;"><fa class="estructura-question" @click="modalEstructuraShow()" icon="question-circle" /></button>
            <br>
            <button ref="estructura1" @click="estructuraSelect($event)" class="opcion-estructura" value="true">Si</button>
            <button ref="estructura2" @click="estructuraSelect($event)" class="opcion-estructura" value="false">No</button>

            
        </div>
    </div>







    <div class="entidad-container">

        <div class="dropdown-button" @click="dropEntidad()">
            <p ref="preguntaEntidad" class="pregunta-entidad">¿La empresa es entidad pública?</p><fa icon="chevron-down" v-if="chevronDownEntidad" /><fa icon="chevron-up" v-if="chevronUpEntidad" />
        </div>

        <div v-show="dropContentEntidad" class="opciones-entidad">
            <button style="text-align: left; padding: 0px;"><fa class="entidad-question" @click="modalEntidadShow()" icon="question-circle" /></button>
            <br>
            <button ref="entidad1" @click="entidadSelect($event)" class="opcion-entidad" value="true">Si</button>
            <button ref="entidad2" @click="entidadSelect($event)" class="opcion-entidad" value="false">No</button>

            
        </div>
    </div>



        <button class="continuar" @click="continuar()">
            Continuar
        </button>




    </div>




<transition name="fade">
        <div class="modal-fade" v-show="modalGrupo">
            <div class="modal-content-grupo">

                <br>
                <h2>¿La empresa integra algún grupo económico?</h2>
                <br>
                <br>

                <p class="grupoText">
                    Cuando dos o más empresas resultan vinculadas entre sí por una relación de control<br>o pertenecen a una misma organización económica o societaria
                </p>
                <br>

                <button @click="modalGrupoShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<transition name="fade">
        <div class="modal-fade" v-show="modalEstructura">
            <div class="modal-content-estructura">

                <br>
                <h2>¿La empresa es una estructura societaria compleja?</h2>
                <br>
                <br>

                <p class="estructuraText">
                    Estructura accionaria compleja: cuando la propiedad está muy atomizada y/o el cliente no puede identificar o brindar información sobre el controlante o beneficiario final.
                </p>
                <br>

                <button @click="modalEstructuraShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>






<transition name="fade">
        <div class="modal-fade" v-show="modalEntidad">
            <div class="modal-content-entidad">

                <br>
                <h2>¿La empresa es Entidad Pública?</h2>
                <br>
                <br>

                <p class="entidadText">
                    Se considera Entidad Pública aquellas empresas que posean una participación estatal del 20% o más.
                </p>
                <br>

                <button @click="modalEntidadShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<transition name="fade">
    <div class="modal-fade" v-show="completarError">
        <div class="modal-content">
            <br>
            <p class="completar">
                Por favor, verifique haber completado todos los campos antes de avanzar
            </p>
            <br>
            <br>

            <button @click="cerrarModalError()" class="cerrar-modal">
                Ok
            </button>

        </div>
    </div>
</transition>









  </div>
</template>

<script>
// import residencia from '../components/residencia.vue'
// import composicionAccionaria from '../components/composicionAccionaria.vue'

export default {
  data() {
        return {
            residencia: "Argentina",
            residenciaShow: true,
            dropContentGrupo: true,
            chevronUpGrupo: false,
            chevronDownGrupo: true,
            grupo: null,
            modalGrupo: false,
            dropContentEstructura: true,
            chevronUpEstructura: false,
            chevronDownEstructura: true,
            estructura: null,
            modalEstructura: false,
            dropContentEntidad: true,
            chevronUpEntidad: false,
            chevronDownEntidad: true,
            entidad: null,
            modalEntidad: false,
            completarError: null,
            accionistas: [],
            token: null
        }
    },
    components: {
        // residencia,
        // composicionAccionaria
    },
    mounted() {             
      this.token = localStorage["token"]
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        // residenciaElegida(r){
        //     this.residencia = r
        // },
        dropGrupo(){
            if (this.dropContentGrupo == true){
                this.dropContentGrupo = false
                this.chevronUpGrupo = true
                this.chevronDownGrupo = false
            } else {
                this.dropContentGrupo = true
                this.chevronDownGrupo = true
                this.chevronUpGrupo = false
            }
        },
        grupoSelect(e){
            this.$refs.grupo1.style.color = "#000";
            this.$refs.grupo2.style.color = "#000";

            this.$refs.preguntaGrupo.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.grupo = elementValue
        },
        modalGrupoShow(){
            if (this.modalGrupo == true) {
                this.modalGrupo = false
            } else {
                this.modalGrupo = true
                this.scrollToTop()
            }
        },
        dropEstructura(){
            if (this.dropContentEstructura == true){
                this.dropContentEstructura = false
                this.chevronUpEstructura = true
                this.chevronDownEstructura = false
            } else {
                this.dropContentEstructura = true
                this.chevronDownEstructura = true
                this.chevronUpEstructura = false
            }
        },
        estructuraSelect(e){
            this.$refs.estructura1.style.color = "#000";
            this.$refs.estructura2.style.color = "#000";

            this.$refs.preguntaEstructura.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.estructura = elementValue
        },
        modalEstructuraShow(){
            if (this.modalEstructura == true) {
                this.modalEstructura = false
            } else {
                this.modalEstructura = true
                this.scrollToTop()
            }
        },
        dropEntidad(){
            if (this.dropContentEntidad == true){
                this.dropContentEntidad = false
                this.chevronUpEntidad = true
                this.chevronDownEntidad = false
            } else {
                this.dropContentEntidad = true
                this.chevronDownEntidad = true
                this.chevronUpEntidad = false
            }
        },
        entidadSelect(e){
            this.$refs.entidad1.style.color = "#000";
            this.$refs.entidad2.style.color = "#000";

            this.$refs.preguntaEntidad.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.entidad = elementValue
        },
        modalEntidadShow(){
            if (this.modalEntidad == true) {
                this.modalEntidad = false
            } else {
                this.modalEntidad = true
                this.scrollToTop()
            }
        },
        cerrarModalError(){
            this.completarError = false
        },
        continuar(){
            if (this.residencia == null || this.grupo == null || this.estructura == null || this.entidad == null){
                this.completarError = true
                this.scrollToTop()
            } else {
                this.completarError = false

                    localStorage.pais_residencia = this.residencia;
                    localStorage.grupo_economico = this.grupo;
                    localStorage.estructura_compleja = this.estructura;
                    localStorage.entidad_publica = this.entidad;
 
                    this.$router.push("/Paso5-accionistas")

                    

                // this.$router.push("/Paso6")
            }


        }
    }
}




</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
  font-family: 'Lato', sans-serif;
}

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.modal-content-grupo{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}
.grupoText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.modal-content-estructura{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.estructuraText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.modal-content-entidad{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.entidadText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}



.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}










.modal-content{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

























.comp-container{
  padding-top: 20px;
  min-height: 700px;
  width: 30%;
  margin: 0 auto;
  background-color: #FBFBFB;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}

.close{
  display: flex;
  justify-content: flex-end;
}

.cross{
  margin-right: 20px;
  margin-top: 15px;
}

h1{
  font-size: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.titulo{
  font-weight: 600;
  letter-spacing: 0.14em;
}

.dot-div{
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dot{
  color: #0C5297;
  opacity: 0.4;
  width: 10px;
}

.this-dot{
  color: #0C5297;
  width: 10px;
}





.grupo-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-grupo{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-grupo{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-grupo{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-grupo:hover{
    text-decoration: underline;
}

.grupo-question{
    color: #0C5297;
}






.estructura-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-estructura{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-estructura{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-estructura{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-estructura:hover{
    text-decoration: underline;
}

.estructura-question{
    color: #0C5297;
}








.entidad-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-entidad{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-entidad{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-entidad{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-entidad:hover{
    text-decoration: underline;
}

.entidad-question{
    color: #0C5297;
}








@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
  .modal-content-grupo{
      width: 90%;
  }
  .modal-content-estructura{
      width: 90%;
  }
  .modal-content-entidad{
      width: 90%;
  }
  .modal-content{
      width: 90%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){

    .grupo-container{
        width: 215px;
    }
    .pregunta-grupo{
        width: 215px;
    }
    .cerrar-modal{
      width: 200px;
    }
    .estructura-container{
        width: 215px;
    }
    .pregunta-estructura{
        width: 215px;
    }
    .entidad-container{
        width: 215px;
    }
    .pregunta-entidad{
        width: 215px;
    }
    .comp-container{
        overflow-x: hidden;
    }

    

}


</style>
