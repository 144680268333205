<template>
  <div class="comp-container">

      <h1>
        Paso 5
      </h1>

      <span class="titulo">
        Datos de la Persona Jurídica
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
      </div>

    <div class="accionista-container">
        

        <div class="paisRiesgo-container">
            <div class="dropdown-button" @click="dropPaisRiesgo()">
                <p ref="preguntaPaisRiesgo" class="pregunta-paisRiesgo">¿Posee accionistas y/o beneficiarios con residencia/nacionalidad en algún país o territorio de riesgo/no colaborador? (Seleccionar lo que corresponda)</p><fa icon="chevron-down" v-if="chevronDownPaisRiesgo" /><fa icon="chevron-up" v-if="chevronUpPaisRiesgo" />
            </div>

            <div v-show="dropContentPaisRiesgo" class="opciones-paisRiesgo">
                <button ref="paisRiesgo1" @click="paisRiesgoSelect($event)" value="true" class="opcion-paisRiesgo">Si</button>
                <button ref="paisRiesgo2" @click="paisRiesgoSelect($event)" value="false" class="opcion-paisRiesgo">No</button>
            </div>
        </div>


        <transition name="fade">
            <div class="modal-fade" v-if="modalRiesgoSi">
                <div class="modal-content-riesgoSi">
                    <br>

                    <h3>¿En que país residen en su mayoría los accionistas extranjeros?</h3>

                    <br>

                    <residenciaRiesgo v-on:residenciaElegida="residenciaElegida($event)" v-on:modalRiesgoSiShow="modalRiesgoSiShow()" />

                </div>
            </div>
        </transition>


        <div class="dropdown-button">
            <p ref="preguntaAccionista" class="pregunta-accionista">¿Cuál es la composición accionaria?</p><fa icon="chevron-down" v-if="chevronDownPorcentaje" /><fa icon="chevron-up" v-if="chevronUpPorcentaje" />
        </div>

        <div v-show="dropContentPorcentaje">

            <div class="data-accionista">
                <p class="disclosureAccionista">*Agregar todos los accionistas que superen el 10% de participación.  En caso de estructuras atomizadas, por favor, agregar accionistas con menos del 10%</p>

                <div style="display: flex;">
                    
                    <select v-model="selectTipo" @change="selectTipoDoc($event)" name="tipoDoc" class="tipoDoc" ref="tipoDoc">
                        <option value="">Tipo</option>
                        <option value="cuit">Cuit</option>
                        <option value="cuil">Cuil</option>
                        <option value="nit">Nit</option>
                    </select>

                    <fa ref="caretArrow" class="drop-arrow" icon="caret-down" />

                    <input v-model="cuitAccionista" :maxlength="cuitCuilNitLen" class="cuitAccionista" :placeholder="'CUIT/CUIL/NIT del accionista'" >
                </div>

                <h6 class="aclaracion">NIT: Número de Identificación Tributaria de su lugar de residencia fiscal.</h6>


                <div style="display: flex;">
                    <select v-model="selectTipoPersona" @change="handlerTipoPersona($event)" name="tipoPersona" class="tipoPersona" ref="tipoPersona">
                        <option value="">Tipo de persona</option>
                        <option value="J">Persona Jurídica</option>
                        <option value="F">Persona Física</option>
                    </select>
                    <fa ref="caretArrowPersona" class="drop-arrowP" icon="caret-down" />
                </div>
                

                <input v-model="nombreAccionista" class="dataAccionista" :placeholder="placeholderNombre" >
                
                <input v-model="apellidoAccionista" class="dataAccionista" :placeholder="'Apellido/s del accionista'" v-if="selectTipoPersona=='F'||selectTipoPersona==''" >

                <input v-model="porcParticipacion" :maxlength="7" class="porcParticipacion" :placeholder="'Porcentaje de Participación (Solo números)'" @keypress="esNumero($event)">

                <div class="pep-container">
                    <div class="dropdown-button">
                        <p ref="preguntaPep" class="preguntaT-pep">¿Es una Persona expuesta políticamente (PEP)?*</p>
                    </div>

                    <div class="opciones-pep">

                        <button ref="pep1" @click="pepSelect($event)" class="opcion-pep" value="no">No</button>
                        <button ref="pep2" @click="pepSelect($event)" class="opcion-pep" value="si">Si</button>

                        <p class="pregunta-pep">*¿No sabe que es PEP? <a class="clickAca" @click="modalPepShow()"><button><fa icon="question-circle" /></button></a></p>

                        <input v-if="pepMotivoShow" class="motivoPep" type="text" placeholder="¿Cuál es el motivo por el que se considera PEP?" v-model="motivoPep">
                        <br>
                    </div>
                </div>
            </div>


            <div style="width: 100%;" v-if="nuevoAccionistaError">
                <p class="nuevo-accionista-error">{{ nuevoAccionistaErrorText }}</p>
            </div>

            <button @click="validarAccionista(true)" class="agregar">¿Desea agregar otro accionista?</button>

            <div class="accionistas-agregados">
                <div v-for="accionista in nuevosAccionistas" :key="accionista.cuit" style="width: 100%; display: flex; justify-content: space-between;">
                    <p class="nuevo-accionista">{{ accionista.cuit }} - {{ accionista.porc }}%</p><fa v-on:click.prevent.self="borrarAccionistaNuevo(accionista.id, accionista.porc)" style="font-size: 13px; color: #E8DD65;" icon="times-circle" />
                </div>
            </div>

        </div>

        <button class="continuar" @click="continuar()">
            Continuar
        </button>

        <!-- <div v-if="omitirShow" class="boton-omitir">
            <button @click="omitir()" class="omitir">
              <br>
              Omitir Paso&nbsp;&nbsp;<fa icon="arrow-circle-right" />
            </button>
        </div> -->

        <div v-if="!omitirShow" class="boton-omitir">
            <button @click="enviarAccionistasSinAgregar()" class="omitir">
              <br>
              Continuar sin agregar otro accionista&nbsp;&nbsp;<fa icon="arrow-circle-right" />
            </button>
        </div>

    </div>

<transition name="fade">
        <div class="modal-fade" v-show="modalPep">
            <div class="modal-content-pep">
                <br>
                <h2>¿Qué es ser PEP?</h2>
                <br>
                <br>

                <p class="pepText">
                                        Resoluciones 134/2018 de la UIF y modificatorias.<br><br>

                    <br>ARTÍCULO 1º.- PERSONAS EXPUESTAS POLITICAMENTE EXTRANJERAS.<br>
                    <br>a. Jefe de Estado, jefe de Gobierno, Gobernador, Intendente, Ministro, Secretario, Subsecretario de Estado u otro cargo gubernamental equivalente.
                    <br>b. Miembro del Parlamento, Poder Legislativo, o de otro órgano de naturaleza equivalente.
                    <br>c. Juez, Magistrado de Tribunales Superiores u otra alta instancia judicial, o administrativa, en el ámbito del Poder Judicial.
                    <br>d. Embajador o cónsul, de un país u organismo internacional.
                    <br>e. Autoridad, apoderado, integrantes del órgano de administración o control y miembros relevantes de partidos políticos extranjeros.
                    <br>f. Oficial de alto rango de las fuerzas armadas (a partir de coronel o grado equivalente en la fuerza y/o país de que se trate) o de las fuerzas de seguridad pública (a partir de comisario o rango equivalente según la fuerza y/o país de que se trate).
                    <br>g. Miembro de los órganos de dirección y control de empresas de propiedad estatal.
                    <br>h. Miembro de los órganos de dirección o control de empresas de propiedad privada o mixta; cuando el Estado posea una participación igual o superior al VEINTE POR CIENTO (20%) del capital o del derecho a voto, o ejerza de forma directa o indirecta el control de la compañía.
                    <br>i. Director, gobernador, consejero, síndico o autoridad equivalente de bancos centrales y otros organismos de regulación y/o supervisión.
                    <br>j. Director, subdirector; miembro de la junta, directorio, alta gerencia, o cargos equivalentes, apoderados, representantes legales o autorizados, de una organización internacional, con facultades de decisión, administración o disposición.
                    <br>ARTÍCULO 2º.- PERSONAS EXPUESTAS POLITICAMENTE NACIONALES.<br>
                    <br>a. Presidente o Vicepresidente de la Nación.
                    <br>b. Senador o Diputado de la Nación.
                    <br>c. Magistrado del Poder Judicial de la Nación.
                    <br>d. Magistrado del Ministerio Público de la Nación.
                    <br>e. Defensor del Pueblo de la Nación o Defensor del Pueblo Adjunto.
                    <br>f. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo Nacional.
                    <br>g. Interventor federal, o colaboradores del interventor federal con categoría no inferior a Director o su equivalente.
                    <br>h. Síndico General de la Nación o Síndico General Adjunto de la Sindicatura General de la Nación; Presidente o Auditor General de la Auditoría General de la Nación; autoridad superior de un ente regulador o de los demás órganos que integran los sistemas de control del sector público nacional; miembros de organismos jurisdiccionales administrativos, o personal de dicho organismo, con categoría no inferior a la de director o su equivalente.
                    <br>i. Miembro del Consejo de la Magistratura de la Nación o del Jurado de Enjuiciamiento.
                    <br>j. Embajador o Cónsul.
                    <br>k. Personal de las Fuerzas Armadas, de la Policía Federal Argentina, de Gendarmería Nacional, de la Prefectura Naval Argentina, del Servicio Penitenciario Federal o de la Policía de Seguridad Aeroportuaria con jerarquía no menor de coronel o grado equivalente según la fuerza.
                    <br>l. Rector, Decano o Secretario de las Universidades Nacionales.
                    <br>m. Funcionario o empleado con categoría o función no inferior a la de Director General o Nacional, de la Administración Pública Nacional, centralizada o descentralizada, de entidades autárquicas, bancos y entidades financieras del sistema oficial, de las obras sociales administradas por el Estado, de empresas del Estado, las sociedades del Estado y el personal con similar categoría o función, designado a propuesta del Estado en sociedades de economía mixta, sociedades anónimas con participación estatal o en otros entes del sector público.
                    <br>n. Funcionario o empleado público nacional encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                    <br>o. Funcionario público de algún organismo de control de servicios públicos, con categoría no inferior a la de Director General o Nacional.
                    <br>p. Personal del Poder Legislativo de la Nación, con categoría no inferior a la de Director.
                    <br>q. Personal del Poder Judicial de la Nación o del Ministerio Público de la Nación, con categoría no inferior a Secretario.
                    <br>r. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                    <br>s. Funcionario público responsable de administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                    <br>t. Director o Administrador de alguna entidad sometida al control externo del Honorable Congreso de la Nación, de conformidad con lo dispuesto en el artículo 120 de la Ley Nº 24.156.
                    <br>ARTÍCULO 3º.- PERSONAS EXPUESTAS POLITICAMENTE PROVINCIALES, MUNICIPALES Y DE LA CIUDAD AUTONOMA DE BUENOS AIRES.<br>
                    <br>a. Gobernador o Vicegobernador, Intendente o Vice-intendente, Jefe de Gobierno o Vicejefe de Gobierno.
                    <br>b. Ministro de Gobierno, Secretario, Subsecretario, Ministro de los Tribunales Superiores de Justicia de las provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>c. Juez o Secretario de los Poderes Judiciales Provinciales o de la Ciudad Autónoma de Buenos Aires.
                    <br>d. Magistrado perteneciente al Ministerio Público, o su equivalente, en las provincias o en la Ciudad Autónoma de Buenos Aires.
                    <br>e. Miembro del Consejo de la Magistratura o del Jurado de Enjuiciamiento, o su equivalente, de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>f. Defensor del Pueblo o Defensor del Pueblo Adjunto, en las Provincias o en la Ciudad Autónoma de Buenos Aires.
                    <br>g. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>h. Legislador provincial, municipal o de la Ciudad Autónoma de Buenos Aires.
                    <br>i. Máxima autoridad de los organismos de control o de los entes autárquicos provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                    <br>j. Máxima autoridad de las sociedades de propiedad de los estados provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                    <br>k. Rector, Decano o Secretario de universidades provinciales.
                    <br>l. Funcionario o empleado público encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                    <br>m. Funcionario de organismos de control de los servicios públicos provinciales o de la Ciudad de Buenos Aires, con categoría no inferior a la de Director General o Provincial.
                    <br>n. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                    <br>o. Funcionario público que tenga por función administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                    <br>ARTÍCULO 4º.- OTRAS PERSONAS EXPUESTAS POLITICAMENTE.<br>
                    <br>a. Autoridad, apoderado, candidato o miembro relevante de partidos políticos o alianzas electorales, ya sea a nivel nacional o distrital, de conformidad con lo establecido en las Leyes N° 23.298 y N° 26.215.
                    <br>b. Autoridad de los órganos de conducción de organizaciones sindicales y empresariales (cámaras, asociaciones y otras formas de agrupación corporativa). El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio sindical.
                    <br>c. Autoridad, representante legal o integrante de la Comisión Directiva de las obras sociales contempladas en la Ley Nº 23.660. El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio de las obras sociales.
                    <br>d. Las personas humanas con capacidad de decisión, administración, control o disposición del patrimonio de personas jurídicas privadas en los términos del 148 del Código Civil y Comercial de la Nación, que reciban fondos públicos destinados a terceros.
                    <br>ARTÍCULO 5º.- PERSONAS EXPUESTAS POLITICAMENTE POR CERCANÍA O AFINIDAD.<br>
                    <br>a. Cónyuge o conviviente reconocido legalmente.
                    <br>b. Familiares en línea ascendente, descendente, y colateral hasta el tercer grado de consanguinidad o afinidad.
                    <br>c. Personas allegadas o cercanas: debe entenderse como tales a aquellas personas públicas y comúnmente conocidas por su íntima asociación a la persona definida como Persona Expuesta Políticamente.
                    <br>d. Personas con las cuales se hayan establecido relaciones jurídicas de negocios del tipo asociativa, aún de carácter informal, cualquiera fuese su naturaleza.
                    <br>e. Toda otra relación o vínculo que por sus características y en función de un análisis basado en riesgo, a criterio del sujeto obligado, pueda resultar relevante.
                </p>

                <button @click="modalPepShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>




<transition name="fade">
        <div class="modal-fade" v-show="modalExito">
            <div class="modal-content-exito">

                <br>
                <h3>Accionista agregado exitosamente</h3>
                <br>

                <p class="exitoText">
                    A continuación, agregue al próximo accionista.
                </p>

                <br>

                <button @click="modalExitoShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<transition name="fade">
        <div class="modal-fade" v-if="borrarAccionista">
            <div class="modal-content-exito">
                <br>
                <h3>Accionista eliminado</h3>
                <br>
                <button @click="borrarAccionistaHide()" class="cerrar-modal">
                    Ok
                </button>
            </div>
        </div>
</transition>

    <transition name="fade">
        <div class="modal-fade" v-show="completarError">
            <div class="modal-content-completar">

                <p class="completar">
                    Por favor, verifique haber completado todos los campos antes de avanzar
                </p>

                <button @click="cerrarModalError()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>

<transition class="loadingcomponent-container-standard" name="fade-standard">
    <div class="modal-fade-standard" v-if="loadingShow">
        <div class="modal-content-completar-standard">
            <div class="loading-container-standard">
                <img class="loading-standard" src="../assets/loading.gif" alt="">
            </div>
        </div>
    </div> 
</transition>


  </div>
</template>

<script>
import residenciaRiesgo from '../components/residenciaRiesgo.vue'


export default {
    data(){
        return{
            cuitCuilNitLen: 11,
            dropContentPorcentaje: true,
            chevronUpPorcentaje: false,
            chevronDownPorcentaje: true,
            modalPep: false,
            cuitAccionista: null,
            nombreAccionista: '',
            apellidoAccionista: '',
            porcParticipacion: null,
            porcParticipacionTotal: 0,
            pep: null,
            persona_api: "INDIVIDUAL",
            accionista_riesgo_api: false,
            modalExito: null,
            borrarAccionista: false,
            nuevosAccionistas: [],
            accionistaId: 0,
            nuevoAccionistaError: false,
            nuevoAccionistaErrorText: "",
            token: null,
            completarError: false,
            loadingShow: false,
            cuitsCargados: [],
            omitirShow: true,
            pepMotivoShow: false,
            motivoPep: '',
            modalRiesgoSi: false,
            residencia: null,
            paisRiesgo: null,
            dropContentPaisRiesgo: true,
            chevronUpPaisRiesgo: false,
            chevronDownPaisRiesgo: true,
            tipoDoc: false,
            selectTipo: '',
            selectTipoPersona: '',
            tipoAccionista: 'F',
            placeholderNombre: 'Nombre/s del accionista'
        }
    },
    components: {
        residenciaRiesgo
    },
    mounted() {    

      if (localStorage["accionistas"] == "true"){
          this.$router.push("/Paso6")
      }

      this.token = localStorage["token"]
    },
    methods: {
        esNumero(evt){
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        slicePorc(){
            this.porcParticipacion = this.porcParticipacion.slice(0, -1)
        },
        omitir () {
            var data = {
                    conocimiento: localStorage.conocimiento_api,
                    objetivo: localStorage.objetivo_api,
                    horizonte: localStorage.horizonte_api,
                    porcentaje: localStorage.porcentaje_api,
                    riesgo: localStorage.riesgo_api,
                    accionista_riesgo: this.accionista_riesgo_api,
                    pais_fuera_arg: this.residencia
                }

                const options = {
                method: 'POST',
                data,
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                url: `https://bkpj.allaria.com.ar/api/v1/perfil-riesgo/`,
                };
                this.loadingShow = true
                this.axios(options)
                .then((result) => {
                    this.result = result
                    this.$router.push("/Paso6")
                })
                .catch((error) => {
                this.error = error
                })
        },
        dropPaisRiesgo(){
            if (this.dropContentPaisRiesgo == true){
                this.dropContentPaisRiesgo = false
                this.chevronUpPaisRiesgo = true
                this.chevronDownPaisRiesgo = false
            } else {
                this.dropContentPaisRiesgo = true
                this.chevronDownPaisRiesgo = true
                this.chevronUpPaisRiesgo = false
            }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        pepSelect(e){
            this.$refs.pep1.style.color = "#000";
            this.$refs.pep2.style.color = "#000";

            let elementValue = e.target.getAttribute('value')
            this.$refs.preguntaPep.style.color = "#5BABBE"
            e.target.style.color = "#5BABBE"

            if(elementValue == "si"){
                this.pepMotivoShow = true
            } else {
                this.pepMotivoShow = false
            }

            this.pep = elementValue
        },
        pepUnselect(){
            this.$refs.pep1.style.color = "#000";
            this.$refs.pep2.style.color = "#000";

            this.$refs.preguntaPep.style.color = "#000"

            this.pep = null
        },
        paisRiesgoSelect(e){
            this.$refs.paisRiesgo1.style.color = "#000";
            this.$refs.paisRiesgo2.style.color = "#000";

            this.$refs.preguntaPaisRiesgo.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.paisRiesgo = elementValue
            localStorage.accionista_riesgo_api = this.paisRiesgo
            this.accionista_riesgo_api = this.paisRiesgo
            if (this.paisRiesgo == "true") {
                this.modalRiesgoSiShow()
            } else{
                this.residencia = ''
            }
        },
        residenciaElegida(e){
            this.residencia = e.toString()
        },
        modalRiesgoSiShow(){
            if (this.modalRiesgoSi == true) {
                this.modalRiesgoSi = false
                this.$refs.caretArrow.style.display = "block";
                this.$refs.caretArrowPersona.style.display = "block";
            } else {
                this.modalRiesgoSi = true
                this.$refs.caretArrow.style.display = "none";
                this.$refs.caretArrowPersona.style.display = "none";
            }
            this.scrollToTop()
        },
        modalPepShow(){
            if (this.modalPep == true) {
                this.modalPep = false
            } else {
                this.modalPep = true
            }
        },
        validarCuit(sCUIT){
            var aMult = '5432765432';
            aMult = aMult.split('');
            
            
            if (sCUIT)
            {
                var aCUIT = sCUIT.split('');
                // aCUIT = aCUIT.filter(function(value, index, arr){
                aCUIT = aCUIT.filter(function(value){ 
                    return value != '-';
                });
            }

            if (sCUIT && aCUIT.length == 11) {
                var iResult = 0;
                var i = 0
                for (i = 0; i <= 9; i++)
                {
                    iResult += aCUIT[i] * aMult[i];
                }
                iResult = (iResult % 11);
                iResult = 11 - iResult;

                if (iResult == 11)
                    iResult = 0;
                if (iResult == 10)
                    iResult = 9;

                if (iResult == aCUIT[10] || sCUIT == "___")
                {
                    return true;
                }
            }
            return false;
        },
        validarAccionista(modal){
            let validarCuit = this.validarCuit(this.cuitAccionista)

            let validacion = true

            let sumaPorc = parseInt(this.porcParticipacionTotal, 10) + parseInt(this.porcParticipacion, 10)

        
            
            if (sumaPorc > 100){
                this.nuevoAccionistaErrorText = "Los porcentajes de participacion superan el 100%"
                this.nuevoAccionistaError = true
                validacion = false
            }


            if (this.porcParticipacion == 0 || this.porcParticipacion == null){
                this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser 0"
                this.nuevoAccionistaError = true
                validacion = false
            }

            // if (this.porcParticipacion < 10){
            //     this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser menor a 10%"
            //     this.nuevoAccionistaError = true
            //     validacion = false
            // }


            if (this.porcParticipacion > 100){
                this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser mayor a 100%"
                this.nuevoAccionistaError = true
                validacion = false
            }


            if (this.pep == null){
                this.nuevoAccionistaErrorText = "Por favor elija si el accionista es PEP"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.nombreAccionista == '') {
                this.nuevoAccionistaErrorText = "Por favor introduzca el Nombre del accionista"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.tipoAccionista == 'F' && this.apellidoAccionista == '') {
                this.nuevoAccionistaErrorText = "Por favor introduzca el Apellido del accionista"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.tipoAccionista == 'J' && this.apellidoAccionista == '') {
                this.apellidoAccionista = "-"
            }

            if (this.tipoDoc == '') {
                this.nuevoAccionistaErrorText = "Por favor elija entre CUIT/CUIL/NIT"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (validarCuit == false){

               if (this.tipoDoc != "nit") {
                    this.nuevoAccionistaErrorText = "CUIT/CUIL/NIT inválido"
                    this.nuevoAccionistaError = true
                    validacion = false
               }
               
            }

            var esta = this.cuitsCargados.some(cuitCargado => cuitCargado === this.cuitAccionista)


            if (esta == true) {
                this.nuevoAccionistaErrorText = "Dos accionistas tienen el mismo CUIT/CUIL/NIT"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if(this.motivoPep == '' && this.pep == "si"){
                this.nuevoAccionistaErrorText = "Por favor introduzca el motivo por el que se considera PEP"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (validacion == true){
                this.nuevoAccionistaError = false
                this.cuitsCargados.push(this.cuitAccionista)
            
                this.agregarAccionista(modal);
            }
        },
        agregarAccionista(modal){

            this.porcParticipacionTotal = parseInt(this.porcParticipacionTotal) + parseInt(this.porcParticipacion)

            if (modal == true){
                this.modalExitoShow()
            }

            this.nuevosAccionistas.push({ id: this.accionistaId, tipoDoc: this.tipoDoc, cuit: this.cuitAccionista, porc: this.porcParticipacion, es_pep: this.pep, nombre: this.nombreAccionista, apellido: this.apellidoAccionista, motivoPep: this.motivoPep, tipoAccionista: this.tipoAccionista, persona_api: this.persona_api})

            this.accionistaId = this.accionistaId + 1

            this.omitirShow = false

            this.cuitAccionista = null
            this.porcParticipacion = null
            this.nombreAccionista = ''
            this.apellidoAccionista = ''
            this.pepMotivoShow = false
            this.motivoPep = ''
            
            this.selectTipo = ''

            this.pepUnselect()
        },
        modalExitoShow(){
            if (this.modalExito == true) {
                this.modalExito = false
            } else {
                this.modalExito = true
                this.scrollToTop()
            }
        },
        borrarAccionistaShow(){
            this.borrarAccionista = true
            this.scrollToTop()
        },
        borrarAccionistaHide(){
            this.borrarAccionista = false
        },
        borrarAccionistaNuevo(a, porc){
            let esta = this.nuevosAccionistas.some(nuevoAccionista => nuevoAccionista.id === a)
            
            this.porcParticipacionTotal = parseInt(this.porcParticipacionTotal, 10) - parseInt(porc)

            if (esta == true) {
                var removeIndex = this.nuevosAccionistas.map(function(nuevoAccionista) { return nuevoAccionista.id; }).indexOf(a);
                this.nuevosAccionistas.splice(removeIndex, 1)
                this.cuitsCargados.splice(removeIndex, 1)

            }
            
            this.borrarAccionistaShow()
        },
        // borrarNuevoAccionista(esta, a) {
        //     if (esta == true) {
        //         var removeIndex = this.nuevosAccionistas.map(function(item) { return item.id; }).indexOf(a);
        //         this.nuevosAccionistas.splice(removeIndex, 1)
        //     }
        // },
        enviarAccionistasSinAgregar(){
            this.enviarAccionistas()
            var data = {
                    conocimiento: localStorage.conocimiento_api,
                    objetivo: localStorage.objetivo_api,
                    horizonte: localStorage.horizonte_api,
                    porcentaje: localStorage.porcentaje_api,
                    riesgo: localStorage.riesgo_api,
                    accionista_riesgo: this.accionista_riesgo_api,
                    pais_fuera_arg: this.residencia
                }


                const options = {
                method: 'POST',
                data,
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                url: `https://bkpj.allaria.com.ar/api/v1/perfil-riesgo/`,
                };
                this.loadingShow = true
                this.axios(options)
                .then((result) => {
                    this.result = result
                    this.$router.push("/Paso6")
                })
                .catch((error) => {
                this.error = error
                })
        },
        enviarAccionistas(){
            for (let i = 0; i < this.nuevosAccionistas.length; i++) {

                let porcApi = this.nuevosAccionistas[i].porc.replace(',', '.').replace(' ', '')
            
                let data = {
                    cuit: this.nuevosAccionistas[i].cuit,
                    porcentaje_part: porcApi,
                    es_pep: this.nuevosAccionistas[i].es_pep,
                    nombre: this.nuevosAccionistas[i].nombre,
                    apellido: this.nuevosAccionistas[i].apellido,
                    motivo_pep: this.nuevosAccionistas[i].motivoPep,
                    tipoDoc: this.nuevosAccionistas[i].tipoDoc
                }

            const options = {
                method: 'POST',
                data,
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                url: `https://bkpj.allaria.com.ar/api/v1/accionistas/`,
              };
              this.loadingShow = true
              this.axios(options)
                .then(() => {

                  localStorage.accionistas = true;

                if (this.nuevosAccionistas[i].tipoAccionista == "J") {
                    this.dni_api = this.nuevosAccionistas[i].cuit
                } else {

                    if (this.nuevosAccionistas[i].tipoDoc != "nit") {
                        var cuitToDni = ''
                        if(this.nuevosAccionistas[i].tipoDoc != false){
                            cuitToDni = this.nuevosAccionistas[i].cuit.substr(1)
                            cuitToDni = cuitToDni.substr(1)
                            cuitToDni = cuitToDni.slice(0, -1)
                        }
                        // this.dni_api = cuitToDni
                        this.nuevosAccionistas[i].cuit = cuitToDni
                    }

                }


                  let data = new FormData()
                    data.append('dni', this.nuevosAccionistas[i].cuit)
                    data.append('nombre', this.nuevosAccionistas[i].nombre + " " + this.nuevosAccionistas[i].apellido)
                    data.append('persona', this.nuevosAccionistas[i].persona_api)
                    
                    const worldcheckData = {
                    method: 'POST',
                    data,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Token ${this.token}` },
                    url: `https://bkpj.allaria.com.ar/api/v1/worldcheck/`,
                    };
                    this.axios(worldcheckData)
                    .then(() => {


                        this.loadingShow = true
                        this.loadingShow = false
                        return true
                    })
                    .catch((error) => {
                        this.error = error
                        this.loadingShow = false
                    })

              })
              .catch(() => {
                return false
              })
            }
        },
        cerrarModalError(){
            this.completarError = false
        },
        continuar(){

            // this.completarError

            if (this.cuitAccionista != null && this.porcParticipacion != null && this.pep != null) {

                

            let validarCuit = this.validarCuit(this.cuitAccionista)

            let validacion = true

            let sumaPorc = parseInt(this.porcParticipacionTotal, 10) + parseInt(this.porcParticipacion, 10)

        
            
            if (sumaPorc > 100){
                this.nuevoAccionistaErrorText = "Los porcentajes de participacion superan el 100%"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.porcParticipacion > 100){
                this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser mayor a 100%"
                this.nuevoAccionistaError = true
                validacion = false
            }


            if (this.porcParticipacion == 0 || this.porcParticipacion == null){
                this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser 0"
                this.nuevoAccionistaError = true
                validacion = false
            }

            
            // if (this.porcParticipacion < 10){
            //     this.nuevoAccionistaErrorText = "El porcentaje de un accionista no puede ser menor a 10%"
            //     this.nuevoAccionistaError = true
            //     validacion = false
            // }


            if (this.pep == null){
                this.nuevoAccionistaErrorText = "Por favor elija si el accionista es PEP"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.nombreAccionista == '') {
                this.nuevoAccionistaErrorText = "Por favor introduzca el Nombre del accionista"
                this.nuevoAccionistaError = true
                validacion = false
            }


            if (this.tipoAccionista == 'F' && this.apellidoAccionista == '') {
                this.nuevoAccionistaErrorText = "Por favor introduzca el Apellido del accionista"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (this.tipoAccionista == 'J' && this.apellidoAccionista == '') {
                this.apellidoAccionista = "-"
            }



            if (this.tipoDoc == '') {
                this.nuevoAccionistaErrorText = "Por favor elija entre CUIT/CUIL/NIT"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if (validarCuit == false){
               if (this.tipoDoc != "nit") {
                    this.nuevoAccionistaErrorText = "CUIT/CUIL/NIT inválido"
                    this.nuevoAccionistaError = true
                    validacion = false
               }
            }

            var esta = this.cuitsCargados.some(cuitCargado => cuitCargado === this.cuitAccionista)

            if (esta == true) {
                this.nuevoAccionistaErrorText = "Dos accionistas tienen el mismo CUIT/CUIL/NIT"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if(this.motivoPep == '' && this.pep == "si"){
                this.nuevoAccionistaErrorText = "Por favor introduzca el motivo por el que se considera PEP"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if(this.paisRiesgo == null){
                this.nuevoAccionistaErrorText = "Por favor elija si posee accionistas con residencia y/o nacionalidad en algún país diferente de Argentina"
                this.nuevoAccionistaError = true
                validacion = false
            }

            if(this.paisRiesgo == true && this.residencia == null){
                this.nuevoAccionistaErrorText = "Por favor elija los países donde sus accionistas residen."
                this.nuevoAccionistaError = true
                validacion = false
            }


            if (validacion == true){
                this.nuevoAccionistaError = false
                this.agregarAccionista(false);
                this.enviarAccionistas()

                var data = {
                    conocimiento: localStorage.conocimiento_api,
                    objetivo: localStorage.objetivo_api,
                    horizonte: localStorage.horizonte_api,
                    porcentaje: localStorage.porcentaje_api,
                    riesgo: localStorage.riesgo_api,
                    accionista_riesgo: this.accionista_riesgo_api,
                    pais_fuera_arg: this.residencia
                }


                const options = {
                method: 'POST',
                data,
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                url: `https://bkpj.allaria.com.ar/api/v1/perfil-riesgo/`,
                };
                this.loadingShow = true
                this.axios(options)
                .then((result) => {
                    this.result = result
                    this.$router.push("/Paso6")
                })
                .catch((error) => {
                this.error = error
                })
                // this.$router.push("/Paso6")
                this.completarError = false
            }

            } else {
                this.completarError = true
            }
        },
        selectTipoDoc(e){
            let doc = e.target.value
            this.tipoDoc = doc

            if (doc == ''){
                this.tipoDoc = false
            }

            if (this.tipoDoc == "nit"){
                this.cuitCuilNitLen = 20
            } else {
                this.cuitCuilNitLen = 11
            }

            
        },
        handlerTipoPersona(e){
            let doc = e.target.value
            this.tipoPersona = doc

            if (doc == ''){
                this.tipoPersona = false
            }
        },
        stringToBoolean(string) {
            switch(string.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    },
    },
    watch:{
        selectTipo: function(){
        },
        selectTipoPersona: function(){
            this.tipoAccionista = this.selectTipoPersona

            if (this.tipoAccionista == "J"){
                // this.nombreAccionista
                // this.apellidoAccionista = "-"
                this.placeholderNombre = 'Razón social del Accionista'
                this.persona_api = "ORGANISATION"
            } else {
                // this.apellidoAccionista = ""
                this.placeholderNombre = 'Nombre/s del accionista'
                this.persona_api = "INDIVIDUAL"
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
  font-family: 'Lato', sans-serif;
}

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.comp-container{
  padding-top: 20px;
  min-height: 700px;
  width: 30%;
  margin: 0 auto;
  background-color: #FBFBFB;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}


.close{
  display: flex;
  justify-content: flex-end;
}

.cross{
  margin-right: 20px;
  margin-top: 15px;
}

h1{
  font-size: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.titulo{
  font-weight: 600;
  letter-spacing: 0.14em;
}

.dot-div{
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dot{
  color: #0C5297;
  opacity: 0.4;
  width: 10px;
}

.this-dot{
  color: #0C5297;
  width: 10px;
}







.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.modal-content-pep{
    width: 35%;
    height: 700px;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.pepText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    height: 500px;
    overflow-y: scroll;
}




.modal-content-exito{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.exitoText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}








.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accionista-container{
    width: 300px;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-accionista{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.data-accionista{
    width: 300px;
}

.disclosureAccionista{
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    margin-top: 0px;
}
.cuitAccionista{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}

.tipoDoc{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    margin-right: 5px;
}

.tipoPersona{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    /* margin-right: 5px; */
}


.dataAccionista{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}

.porcParticipacion{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
}

.motivoPep{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
    align-self: center;
}
.pep-container{
    width: 300px;
    margin: 0 auto;
}

.preguntaT-pep{
    width: 280px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.pregunta-pep{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}

.opciones-pep{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-pep{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-pep:hover{
    text-decoration: underline;
}
.clickAca{
    color: #0C5297;
}

.agregar{
    width: 300px;
    color: #0C5297;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
}

.agregar:hover{
    text-decoration: underline;
}

.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.accionistas-agregados{
    width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.nuevo-accionista{
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
}

.nuevo-accionista-error{
    font-size: 12px;
    font-weight: bolder;
    margin: 0;
    margin-top: 5px;
    color: red;
}


.boton-omitir{
  width: 300px;
  margin: 0 auto;
  display:flex;
  justify-content: left;
}

.omitir{
  background-color: #FBFBFB;
  border: none;
  font-size: 14px;
  color: #0C5297;
}

.omitir:hover{
    text-decoration: underline;
}

.modal-content-completar{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.completar{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.paisRiesgo-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pregunta-paisRiesgo{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-paisRiesgo{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-paisRiesgo{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-paisRiesgo:hover{
    text-decoration: underline;
}

.modal-content-riesgoSi{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.drop-arrow{
    position: absolute;
    margin-top: 25px;
    margin-left: 50px;
    pointer-events: none;
}

.drop-arrowP{
    position: absolute;
    margin-top: 25px;
    margin-left: 270px;
    pointer-events: none;
}

  .aclaracion{
    margin: 0 auto;
    width: 300px;
    text-align: left;
    margin-top: 3px;
  }

@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}



@media only screen and (max-width: 845px) {

    .modal-content-pep{
        width: 90%;
    }
    .modal-content-exito{
        width: 90%;
    }
    .modal-content-completar{
        width: 90%;
    }
    .comp-container{
        width: 80%;
    }
    .modal-content-riesgoSi{
        width: 90%;
    }

}


@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }
}




@media only screen and (max-width: 340px){
    .accionista-container{
        width: 215px;
    }
    .data-accionista{
        width: 215px;
    }
    .dataAccionista{
        width: 215px;
    }
    .cuitAccionista{
        width: 215px;
    }
    .porcParticipacion{
        width: 215px;
    }
    .motivoPep{
        width: 215px;
    }
    .cerrar-modal{
      width: 200px;
    }
    .preguntaT-pep{
        width: 215px;
    }
    .boton-omitir{
        width: 215px;
        display:flex;
    }
    .omitir{
        width: 215px;
        text-align: left;
    }
    .comp-container{
        overflow-x: hidden;
    }
    .paisRiesgo-container{
        width: 215px;
    }
    .pregunta-paisRiesgo{
        width: 215px;
    }
    .drop-arrow{
        margin-left: 37px;
    }
    .drop-arrowP{
        margin-left: 190px;
    }
    .aclaracion{
        width: 215px;
    }
    .pep-container{
        width: 215px;
    }

    .opciones-pep{
        width: 215px;
        margin: 0 auto;
    }

    .motivoPep{
        width: 215px;
    }

}


</style>