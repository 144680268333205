<template>

  <div class="paso6">

    <div class="comp-container">


      <h1>
        Paso 6
      </h1>

      <span class="titulo">
        Documentación Societaria
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
      </div>

      <br>


      <div v-if="elegirSociedad">
        <div class="sociedad-container">
          <p ref="preguntaSociedad" class="pregunta-sociedad">Por favor, seleccione el tipo de sociedad</p>

          <br>
          <div class="opciones-sociedad">
              <button ref="sociedad1" @click="sociedadSelect($event)" value="sa" class="opcion-sociedad">Sociedad Anónima (SA)</button>
              <br>
              <button ref="sociedad2" @click="sociedadSelect($event)" value="srl" class="opcion-sociedad">Sociedad de Responsabilidad Limitada (SRL)</button>
              <br>
              <button ref="sociedad3" @click="sociedadSelect($event)" value="afcc" class="opcion-sociedad">Asociaciones / Fundaciones / Cooperativas / Cámaras</button>
              <br>
              <button ref="sociedad4" @click="sociedadSelect($event)" value="fc" class="opcion-sociedad">Fideicomisos</button>
              <br>
              <button ref="sociedad5" @click="sociedadSelect($event)" value="sas" class="opcion-sociedad">Sociedades por Acciones Simplificadas (S.A.S)</button>
              <br>
              <button ref="sociedad6" @click="sociedadSelect($event)" value="sgr" class="opcion-sociedad">Sociedades de Garantía Recíproca (S.G.R.)</button>
              <br>
              <button ref="sociedad7" @click="sociedadSelect($event)" value="cons" class="opcion-sociedad">Consorcio</button>
              <br>
              <button ref="sociedad8" @click="sociedadSelect($event)" value="otra" class="opcion-sociedad">Otro tipo de sociedad</button>
              <p class="aclaracion">*Por ejemplo: Sociedad de Hecho, Sociedad Civil, Sociedad en comandita, etc.</p>
          </div>
        </div>

      </div>


      
      <sa v-if="saShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <srl v-if="srlShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <afcc v-if="afccShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <fc v-if="fcShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <sas v-if="sasShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <sgr v-if="sgrShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <cons v-if="consShow" v-on:sociedadUnselect="sociedadUnselect()" />
      <otra v-if="otraShow" v-on:sociedadUnselect="sociedadUnselect()" />

        
    
    </div>
  </div>

</template>

<script>
import sa from '../components/sa.vue'
import srl from '../components/srl.vue'
import afcc from '../components/afcc.vue'
import fc from '../components/fc.vue'
import sas from '../components/sas.vue'
import sgr from '../components/sgr.vue'
import cons from '../components/cons.vue'
import otra from '../components/otra.vue'


export default {
  data(){
    return {
      sociedad: null,
      elegirSociedad: true,
      desactivar: true,
      sinEstatuto: true,
      estatuto: false,
      cargarEstatuto: true,
      saShow: false,
      srlShow: false,
      afccShow: false,
      fcShow: false,
      sasShow: false,
      sgrShow: false,
      consShow: false,
      otraShow: false,
      token: null
    }
  },
  mounted() {
    
    this.token = localStorage["token"]

  },
  methods: {
    sociedadSelect(e){
      this.$refs.sociedad1.style.fontWeight = "normal";
      this.$refs.sociedad2.style.fontWeight = "normal";
      this.$refs.sociedad3.style.fontWeight = "normal";
      this.$refs.sociedad4.style.fontWeight = "normal";
      this.$refs.sociedad5.style.fontWeight = "normal";
      this.$refs.sociedad6.style.fontWeight = "normal";
      this.$refs.sociedad7.style.fontWeight = "normal";
      this.$refs.sociedad8.style.fontWeight = "normal";

      this.$refs.preguntaSociedad.style.color = "#5BABBE";

      let elementValue = e.target.getAttribute('value')
      e.target.style.fontWeight = "bolder"
      this.sociedad = elementValue
      this.desactivar = false

      if (elementValue == "sa") {
        this.saShow = true
      }
      if (elementValue == "srl") {
        this.srlShow = true
      }
      if (elementValue == "afcc") {
        this.afccShow = true
      }
      if (elementValue == "fc") {
        this.fcShow = true
      }
      if (elementValue == "sas") {
        this.sasShow = true
      }
      if (elementValue == "sgr") {
        this.sgrShow = true
      }
      if (elementValue == "cons") {
        this.consShow = true
      }
      if (elementValue == "otra") {
        this.otraShow = true
      }
      this.elegirSociedad = false
      // this.volver = true

    },
    sociedadUnselect(){
      this.saShow = false
      this.srlShow = false
      this.afccShow = false
      this.fcShow = false
      this.sasShow = false
      this.sgrShow = false
      this.consShow = false
      this.otraShow = false
      this.elegirSociedad = true
      // this.volver = false
    }
  },
  components: {
    sa,
    srl,
    afcc,
    fc,
    sas,
    sgr,
    cons,
    otra
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }

  .comp-container{
    padding-top: 20px;
    min-height: 700px;
    width: 30%;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
  }

  .dot-div{
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dot{
    color: #0C5297;
    opacity: 0.4;
    width: 10px;
  }

  .this-dot{
    color: #0C5297;
    width: 10px;
  }

  .boton-volver{
    width: 300px;
    margin: 0 auto;
    display:flex;
    justify-content: left;
  }

  .volver{
    background-color: #ffffff;
    border: none;
    font-size: 14px;
    color: #0C5297;
  }









  .sociedad-container{
    width: 300px;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-sociedad{
    width: 300px;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    text-align: left;
}

.opciones-sociedad{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-sociedad{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-sociedad:hover{
    text-decoration: underline;
}

.aclaracion{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000000;
  /* font-weight: bold; */
  text-align: left;
  font-size: 11px;
  letter-spacing: 0.018em;
}




.archivo-button{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:20px;
}





.continuar{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:20px;
}





.botonOk{
  background-color: #5BABBE;;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){

    .sociedad-container{
        width: 215px;
    }
    .pregunta-sociedad{
        width: 215px;
    }
    .archivo-button{
        width: 215px;
    }
    .botonOk{
        width: 215px;
    }
    .continuar{
        width: 215px;
    }
    .boton-volver{
      width: 215px;
    }
    .comp-container{
        overflow-x: hidden;
    }


}
</style>