<template>
    <div class="datos-container">

    <div class="nombreApellido">
      <span class="data"><b>Nombre</b><br>{{ nombre }}</span>
      <span class="data"><b>Apellido</b><br>{{ apellido }}</span>
    </div>
    
    <br>

    <span class="data"><b>Fecha de nacimiento</b><br>{{ fechaDeNacimiento }}</span>
    <br>
    <span class="data"><b>Lugar de nacimiento</b><br>{{ lugarNacimiento }}</span>
    <br>
    <span class="data"><b>Nacionalidad</b><br>{{ nacionalidad }}</span>
    <br>
    <span v-show="!nuevoDomicilio" class="data"><b>Domicilio</b><br>{{ domicilio }}</span>
    <br>

    <div v-show="nuevoDomicilio" class="nuevoDomicilio">
        <input type="text" v-model="newCalle" class="newCalle" placeholder="Calle">

        <div class="direccion-container">
            <input type="text" v-model="newNumero" class="newNumero" placeholder="Número">
            <input type="text" v-model="newPiso" class="newPiso" placeholder="Piso">
            <input type="text" v-model="newDpto" class="newDpto" placeholder="Dpto.">
        </div>

        <input type="text" v-model="newProvincia" class="newProvincia" placeholder="Provincia">
        <input type="text" v-model="newLocalidad" class="newLocalidad" placeholder="Localidad">
        <input type="text" v-model="newCP" class="newCP" placeholder="Código Postal">


    </div>

    <cuitApoderado v-on:nuevoCuit="nuevoCuit($event)" v-if="newCuit"/>

    <button class="correcto" @click="correcto()">
      Si, es correcto
    </button>

    <button style="text-align: left; padding-top: 5.5px; padding-bottom: 5.5px;"><a @click="incorrecto()" class="incorrecto">No es correcto, volver al paso anterior</a></button>

    <button style="text-align: left; padding-top: 5.5px; padding-bottom: 10px;"><a class="editarDom" @click="cambiarDomicilio()">Editar domicilio</a></button>

    <div class="sid-container">
      <img class="sid" src="../assets/sid.png" alt="">
    </div>


    <div class="disclosure-container">
      <span class="disclosure">Por favor, verifique que los datos sean correctos, de lo contrario comuníquese con RENAPER</span>
    </div> 

    </div>     
</template>

<script>
import cuitApoderado from '../components/cuitApoderado.vue'

export default {
    name: 'dataPersonal',
    props: {
        nombre: {
            type: String
        },
        apellido: {
            type: String
        },
        fechaDeNacimiento: {
            type: String
        },
        lugarNacimiento: {
            type: String
        },
        nacionalidad: {
            type: String
        },
        domicilio: {
            type: String
        },
        nuevoDomicilio: {
            type: Boolean,
            default: false
        },
        newCuit: {
            type: Boolean
        }
    },
    data(){
        return{
          newCalle: null,
          newNumero: null,
          newPiso: null,
          newDpto: null,
          newProvincia: null,
          newLocalidad: null,
          newCP: null
        }
    },
    methods:{
        incorrecto(){
          this.$emit("volver")
        },
        cambiarDomicilio(){
            this.$emit("cambiarDomicilio")
        },
        correcto(){
            this.$emit("correcto")
        },
        nuevoCuit(e){
            this.$emit("triggerNuevoCuit", e)
        },
    },
    components:{
        cuitApoderado
    }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }

.datos-container{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;
  margin-top: 20px;
}

.nombreApellido{
  display: flex;
  width: 300px;
  margin: 0 auto;
  justify-content: space-between;
}

.data{
  font-size: 14px;
  color: #000000;
  text-align: left;
}

.correcto{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.newCalle {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.direccion-container{
    display: flex;
    width: 300px;
    justify-content: space-between;
    margin-top: 8px;
}

.newNumero{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newPiso{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newDpto{
    width: 90px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    font-size: 14px;
    line-height: 17px;
}

.newProvincia {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.newLocalidad {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.newCP {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.incorrecto{
    font-weight: bold;
    font-size: 13px;
    /* text-decoration-line: underline; */
    color: #0C5297;
    width: 300px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
}

.incorrecto:hover{
  text-decoration: underline;
}

.editarDom{
    font-weight: bold;
    font-size: 13px;
    /* text-decoration-line: underline; */
    color: #0C5297;
    width: 300px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
    padding-bottom: 20px;
}

.editarDom:hover{
  text-decoration: underline;
}

.sid-container{
  width: 300px;
  display: flex;
  margin: 0 auto;
  align-items: left;
}

.sid{
  width: 60%;
}

.disclosure-container{
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}

.disclosure{
  color: #000000;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
}

@media only screen and (max-width: 340px){
    .datos-container{
      width: 215px;
    }
    
    .nombreApellido{
      width: 215px;
    }

    .correcto{
      width: 215px;
    }

    .editarDom{
      width: 215px;
    }

    .sid-container{
      width: 215px;
    }

    .disclosure-container{
        width: 215px;
    }




.newCalle {
    width: 215px;
}

.direccion-container{
    width: 215px;
}

.newNumero{
    width: 67px;
}
.newPiso{
    width: 67px;
}
.newDpto{
    width: 67px;
}

.newProvincia {
    width: 215px;
}

.newLocalidad {
    width: 215px;
}

.newCP {
    width: 215px;
}

}

</style>