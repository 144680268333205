<template>
    <div class="datos-container">

        <div class="nombreApellido">
          <span class="data"><b>Nombre</b><br>{{ nombre }}</span>
          <span class="data"><b>Apellido</b><br>{{ apellido }}</span>
        </div>
        <br>
        <span class="data"><b>Teléfono de contacto</b></span>

        <div class="numero-container">
            <input type="text" v-model="codArea" class="codArea" placeholder="Cod. Area" :maxlength="4">
            <input type="text" v-model="numero" class="numero" placeholder="Número" :maxlength="10">
        </div>

        <br>
        
        <span class="data"><b>Email del apoderado</b></span>

        <div class="email-container">
            <input type="text" v-model="email" class="email" placeholder="Email">
        </div>

        <div class="confError" v-show="errorMailShow">
            <span class="confErrorTest">Email inválido</span>
        </div>

        <div class="estado-civil-container">
            <br>
            <div class="dropdown-button" @click="dropEstado()">
                <p ref="preguntaEstado" class="pregunta-estado">¿Cuál es el estado civil del apoderado?</p><fa ref="chevronEstado1" icon="chevron-down" v-if="chevronDownEstado" /><fa ref="chevronEstado2" icon="chevron-up" v-if="chevronUpEstado" />
            </div>
            

            <div v-show="dropContentEstado" class="opciones-estado">
                <button ref="estado1" @click="estadoSelect($event)" class="opcion-estado" value="soltero">Soltero (a)</button>
                <button ref="estado2" @click="estadoSelect($event)" class="opcion-estado" value="casado">Casado (a)</button>
                <button ref="estado3" @click="estadoSelect($event)" class="opcion-estado" value="divorciado">Divorciado (a)</button>
                <button ref="estado4" @click="estadoSelect($event)" class="opcion-estado" value="viudo">Viudo (a)</button>
            </div>
        </div>



        <div class="pep-container">
            <br>
            <div class="dropdown-button" @click="dropPep()">
                <p ref="preguntaPep" class="preguntaT-pep">¿Es una Persona expuesta políticamente (PEP)?*</p>
                    <fa ref="chevronPep1" icon="chevron-down" v-if="chevronDownPep" />
                    <fa ref="chevronPep2" icon="chevron-up" v-if="chevronUpPep" />
                
            </div>

            <div v-show="dropContentPep" class="opciones-pep">
                
                <button ref="pep1" @click="pepSelect($event)" class="opcion-pep" value="true">Si</button>
                <button ref="pep2" @click="pepSelect($event)" class="opcion-pep" value="false">No</button>

                <p class="pregunta-pep">*¿No sabe que es PEP? <a class="clickAca" @click="modalPepShow()"><button><fa icon="question-circle" /></button></a></p>

                <input v-model="motivoPepText" v-if="pepMotivoShow" class="motivoPep" type="text" placeholder="¿Cuál es el motivo por el que se considera PEP?">
            </div>

            
        </div>

        <br>
        <button @click="agregarApoderado()" class="agregar">¿Desea agregar otro apoderado?</button>


        <button class="continuar" @click="setPersonalData()">
            Continuar
        </button>





<transition name="fade">
        <div class="modal-fade" v-show="modalPep">
            <div class="modal-content-pep">
                <br>
                <h2>¿Qué es ser PEP?</h2>
                <br>
                <br>

                <p class="pepText">
                    Resoluciones 134/2018 de la UIF y modificatorias.<br><br>

                    <br>ARTÍCULO 1º.- PERSONAS EXPUESTAS POLITICAMENTE EXTRANJERAS.<br>
                    <br>a. Jefe de Estado, jefe de Gobierno, Gobernador, Intendente, Ministro, Secretario, Subsecretario de Estado u otro cargo gubernamental equivalente.
                    <br>b. Miembro del Parlamento, Poder Legislativo, o de otro órgano de naturaleza equivalente.
                    <br>c. Juez, Magistrado de Tribunales Superiores u otra alta instancia judicial, o administrativa, en el ámbito del Poder Judicial.
                    <br>d. Embajador o cónsul, de un país u organismo internacional.
                    <br>e. Autoridad, apoderado, integrantes del órgano de administración o control y miembros relevantes de partidos políticos extranjeros.
                    <br>f. Oficial de alto rango de las fuerzas armadas (a partir de coronel o grado equivalente en la fuerza y/o país de que se trate) o de las fuerzas de seguridad pública (a partir de comisario o rango equivalente según la fuerza y/o país de que se trate).
                    <br>g. Miembro de los órganos de dirección y control de empresas de propiedad estatal.
                    <br>h. Miembro de los órganos de dirección o control de empresas de propiedad privada o mixta; cuando el Estado posea una participación igual o superior al VEINTE POR CIENTO (20%) del capital o del derecho a voto, o ejerza de forma directa o indirecta el control de la compañía.
                    <br>i. Director, gobernador, consejero, síndico o autoridad equivalente de bancos centrales y otros organismos de regulación y/o supervisión.
                    <br>j. Director, subdirector; miembro de la junta, directorio, alta gerencia, o cargos equivalentes, apoderados, representantes legales o autorizados, de una organización internacional, con facultades de decisión, administración o disposición.
                    <br>ARTÍCULO 2º.- PERSONAS EXPUESTAS POLITICAMENTE NACIONALES.<br>
                    <br>a. Presidente o Vicepresidente de la Nación.
                    <br>b. Senador o Diputado de la Nación.
                    <br>c. Magistrado del Poder Judicial de la Nación.
                    <br>d. Magistrado del Ministerio Público de la Nación.
                    <br>e. Defensor del Pueblo de la Nación o Defensor del Pueblo Adjunto.
                    <br>f. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo Nacional.
                    <br>g. Interventor federal, o colaboradores del interventor federal con categoría no inferior a Director o su equivalente.
                    <br>h. Síndico General de la Nación o Síndico General Adjunto de la Sindicatura General de la Nación; Presidente o Auditor General de la Auditoría General de la Nación; autoridad superior de un ente regulador o de los demás órganos que integran los sistemas de control del sector público nacional; miembros de organismos jurisdiccionales administrativos, o personal de dicho organismo, con categoría no inferior a la de director o su equivalente.
                    <br>i. Miembro del Consejo de la Magistratura de la Nación o del Jurado de Enjuiciamiento.
                    <br>j. Embajador o Cónsul.
                    <br>k. Personal de las Fuerzas Armadas, de la Policía Federal Argentina, de Gendarmería Nacional, de la Prefectura Naval Argentina, del Servicio Penitenciario Federal o de la Policía de Seguridad Aeroportuaria con jerarquía no menor de coronel o grado equivalente según la fuerza.
                    <br>l. Rector, Decano o Secretario de las Universidades Nacionales.
                    <br>m. Funcionario o empleado con categoría o función no inferior a la de Director General o Nacional, de la Administración Pública Nacional, centralizada o descentralizada, de entidades autárquicas, bancos y entidades financieras del sistema oficial, de las obras sociales administradas por el Estado, de empresas del Estado, las sociedades del Estado y el personal con similar categoría o función, designado a propuesta del Estado en sociedades de economía mixta, sociedades anónimas con participación estatal o en otros entes del sector público.
                    <br>n. Funcionario o empleado público nacional encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                    <br>o. Funcionario público de algún organismo de control de servicios públicos, con categoría no inferior a la de Director General o Nacional.
                    <br>p. Personal del Poder Legislativo de la Nación, con categoría no inferior a la de Director.
                    <br>q. Personal del Poder Judicial de la Nación o del Ministerio Público de la Nación, con categoría no inferior a Secretario.
                    <br>r. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                    <br>s. Funcionario público responsable de administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                    <br>t. Director o Administrador de alguna entidad sometida al control externo del Honorable Congreso de la Nación, de conformidad con lo dispuesto en el artículo 120 de la Ley Nº 24.156.
                    <br>ARTÍCULO 3º.- PERSONAS EXPUESTAS POLITICAMENTE PROVINCIALES, MUNICIPALES Y DE LA CIUDAD AUTONOMA DE BUENOS AIRES.<br>
                    <br>a. Gobernador o Vicegobernador, Intendente o Vice-intendente, Jefe de Gobierno o Vicejefe de Gobierno.
                    <br>b. Ministro de Gobierno, Secretario, Subsecretario, Ministro de los Tribunales Superiores de Justicia de las provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>c. Juez o Secretario de los Poderes Judiciales Provinciales o de la Ciudad Autónoma de Buenos Aires.
                    <br>d. Magistrado perteneciente al Ministerio Público, o su equivalente, en las provincias o en la Ciudad Autónoma de Buenos Aires.
                    <br>e. Miembro del Consejo de la Magistratura o del Jurado de Enjuiciamiento, o su equivalente, de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>f. Defensor del Pueblo o Defensor del Pueblo Adjunto, en las Provincias o en la Ciudad Autónoma de Buenos Aires.
                    <br>g. Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutivo de las Provincias o de la Ciudad Autónoma de Buenos Aires.
                    <br>h. Legislador provincial, municipal o de la Ciudad Autónoma de Buenos Aires.
                    <br>i. Máxima autoridad de los organismos de control o de los entes autárquicos provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                    <br>j. Máxima autoridad de las sociedades de propiedad de los estados provinciales, municipales o de la Ciudad Autónoma de Buenos Aires.
                    <br>k. Rector, Decano o Secretario de universidades provinciales.
                    <br>l. Funcionario o empleado público encargado de otorgar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; como así también todo funcionario o empleado público encargado de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.
                    <br>m. Funcionario de organismos de control de los servicios públicos provinciales o de la Ciudad de Buenos Aires, con categoría no inferior a la de Director General o Provincial.
                    <br>n. Funcionario o empleado público que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes, o participe en la toma de decisiones de licitaciones o compras.
                    <br>o. Funcionario público que tenga por función administrar un patrimonio público o privado, o controlar o fiscalizar los ingresos públicos cualquiera fuera su naturaleza.
                    <br>ARTÍCULO 4º.- OTRAS PERSONAS EXPUESTAS POLITICAMENTE.<br>
                    <br>a. Autoridad, apoderado, candidato o miembro relevante de partidos políticos o alianzas electorales, ya sea a nivel nacional o distrital, de conformidad con lo establecido en las Leyes N° 23.298 y N° 26.215.
                    <br>b. Autoridad de los órganos de conducción de organizaciones sindicales y empresariales (cámaras, asociaciones y otras formas de agrupación corporativa). El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio sindical.
                    <br>c. Autoridad, representante legal o integrante de la Comisión Directiva de las obras sociales contempladas en la Ley Nº 23.660. El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposición del patrimonio de las obras sociales.
                    <br>d. Las personas humanas con capacidad de decisión, administración, control o disposición del patrimonio de personas jurídicas privadas en los términos del 148 del Código Civil y Comercial de la Nación, que reciban fondos públicos destinados a terceros.
                    <br>ARTÍCULO 5º.- PERSONAS EXPUESTAS POLITICAMENTE POR CERCANÍA O AFINIDAD.<br>
                    <br>a. Cónyuge o conviviente reconocido legalmente.
                    <br>b. Familiares en línea ascendente, descendente, y colateral hasta el tercer grado de consanguinidad o afinidad.
                    <br>c. Personas allegadas o cercanas: debe entenderse como tales a aquellas personas públicas y comúnmente conocidas por su íntima asociación a la persona definida como Persona Expuesta Políticamente.
                    <br>d. Personas con las cuales se hayan establecido relaciones jurídicas de negocios del tipo asociativa, aún de carácter informal, cualquiera fuese su naturaleza.
                    <br>e. Toda otra relación o vínculo que por sus características y en función de un análisis basado en riesgo, a criterio del sujeto obligado, pueda resultar relevante.
                </p>

                <button @click="modalPepShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<transition name="fade">
        <div class="modal-fade" v-show="modalError">
            <div class="modal-content-error">

                <br>

                <p class="error">
                    Por favor, verifique haber completado todos los campos antes de avanzar
                </p>

                <br>

                <button @click="modalErrorShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>






<transition name="fade">
        <div class="modal-fade" v-show="modalExito">
            <div class="modal-content-exito">

                <br>

                <h3>Apoderado agregado exitosamente</h3>

                <br>

                <button @click="okAgregarApoderado()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>



    </div>
</template>

<script>
export default {
    name: "dataNumero",
    props: {
        nombre: {
            type: String
        },
        apellido: {
            type: String
        }
    },
    mounted(){
        
        // if (this.fisrt == false){
        //     this.email = localStorage["emailApoderado"]
        //     this.fisrt == true
        // } else {
        //     this.email = ""
        // }
        
        this.email = localStorage["emailApoderado"]

        localStorage.removeItem('emailApoderado')
    },
    data(){
        return{
            modalPep: false,
            modalError: false,
            codArea: null,
            numero: null,
            email: null,
            estado: null,
            pep: null,
            dropContentEstado: true,
            chevronUpEstado: false,
            chevronDownEstado: true,
            dropContentPep: true,
            chevronUpPep: false,
            chevronDownPep: true,
            modalExito: null,
            errorMailShow: false,
            first: false,
            pepMotivoShow: false,
            motivoPepText: ''
        }
    },
    methods:{
        scrollToTop() {
            window.scrollTo(0,0);
        },
        dropEstado(){
            if (this.dropContentEstado == true){
                this.dropContentEstado = false
                this.chevronUpEstado = true
                this.chevronDownEstado = false
            } else {
                this.dropContentEstado = true
                this.chevronDownEstado = true
                this.chevronUpEstado = false
            }
        },
        dropPep(){
            if (this.dropContentPep == true){
                this.dropContentPep = false
                this.chevronUpPep = true
                this.chevronDownPep = false
            } else {
                this.dropContentPep = true
                this.chevronDownPep = true
                this.chevronUpPep = false
            }
        },
        estadoSelect(e){
            this.$refs.estado1.style.color = "#000";
            this.$refs.estado2.style.color = "#000";
            this.$refs.estado3.style.color = "#000";
            this.$refs.estado4.style.color = "#000";

            this.$refs.preguntaEstado.style.color = "#5BABBE"
            

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.estado = elementValue
        },
        pepSelect(e){
            this.$refs.pep1.style.color = "#000";
            this.$refs.pep2.style.color = "#000";

            this.$refs.preguntaPep.style.color = "#5BABBE"

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.pep = elementValue

            // this.pepMotivoShow = elementValue
            if(elementValue == "true"){
                this.pepMotivoShow = true
            } else {
                this.pepMotivoShow = false
            }
        },
        modalPepShow(){
            if (this.modalPep == true) {
                this.modalPep = false
            } else {
                this.modalPep = true
            }
        },
        modalErrorShow(){
            if (this.modalError == true) {
                this.modalError = false
            } else {
                this.modalError = true
                this.scrollToTop()
            }
        },
        continuar(){
            if(this.codArea == null || this.numero == null || this.email == null || this.estado == null || this.pep == null){
                if (this.modalError == true) {
                    this.modalError = false
                } else {
                    this.modalError = true
                    this.scrollToTop()
                }
            } else {
                const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                const email = this.email
                const matchesEmail = regexEmail.exec(email)

                if (matchesEmail == null){
                    this.errorMailShow = true
                } else {
                    this.errorMailShow = false
                    this.$router.push("/Paso4")
                    alert("Paso siguiente")
                }

                // if (this.pep == true) {
                    
                // }
            }
        },
        agregarApoderado(){

            // AGREGAR APODERADO MEDIANTE API

            this.modalExitoShow()
            
        },
        okAgregarApoderado(){
            const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            const email = this.email
            const matchesEmail = regexEmail.exec(email)


            if(this.codArea == null || this.numero == null || this.email == null || this.estado == null || this.pep == null){
                if (this.modalError == true) {
                    this.modalError = false
                } else {
                    this.modalError = true
                    this.scrollToTop()
                }
            } else {
                if (matchesEmail == null){
                    this.errorMailShow = true
                } else {
                    this.errorMailShow = false
                    // this.$emit("agregarApoderado", {codArea: this.codArea, celular: this.numero, email: this.email, estado_civil: this.estado, esPep: this.pep, motivoPep: this.motivoPepText})
                    if (this.pep == "true") {
                        if(this.motivoPepText == ''){
                            this.modalError = true
                        } else {
                            this.$emit("agregarApoderado", {codArea: this.codArea, celular: this.numero, email: this.email, estado_civil: this.estado, esPep: this.pep, motivoPep: this.motivoPepText})     
                        }
                    } else{
                        this.$emit("agregarApoderado", {codArea: this.codArea, celular: this.numero, email: this.email, estado_civil: this.estado, esPep: this.pep, motivoPep: null})     
                    }
                }
                
            }
            
        },
        modalExitoShow(){
            if(this.codArea == null || this.numero == null || this.email == null || this.estado == null || this.pep == null){
                if (this.modalError == true) {
                    this.modalError = false
                } else {
                    this.modalError = true
                    this.scrollToTop()
                }
            } else {
                const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                const email = this.email
                const matchesEmail = regexEmail.exec(email)

                if (matchesEmail == null){
                    this.errorMailShow = true
                } else {
                    this.errorMailShow = false
                    if (this.modalExito == true) {
                        this.modalExito = false
                    } else {
                        this.modalExito = true
                        this.scrollToTop()
                    }
                }




            }
            
           
        },
        setPersonalData(){
            if(this.codArea == null || this.numero == null || this.email == null || this.estado == null || this.pep == null){
                if (this.modalError == true) {
                    this.modalError = false
                } else {
                    this.modalError = true
                    this.scrollToTop()
                }
            } else {
                const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                const email = this.email
                const matchesEmail = regexEmail.exec(email)

                if (matchesEmail == null){
                    this.errorMailShow = true
                } else {
                    this.errorMailShow = false
                    
                    if (this.pep == "true") {
                        if(this.motivoPepText == ''){
                            this.modalError = true
                        } else {
                            this.$emit("setPersonalData", {codArea: this.codArea, celular: this.numero, email: this.email, estado_civil: this.estado, esPep: this.pep, motivoPep: this.motivoPepText})     
                        }
                    } else{
                        this.$emit("setPersonalData", {codArea: this.codArea, celular: this.numero, email: this.email, estado_civil: this.estado, esPep: this.pep, motivoPep: null})     
                    }
                }
            }
            
            
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
  font-family: 'Lato', sans-serif;
}

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.datos-container{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;
  margin-top: 20px;
}

.nombreApellido{
  display: flex;
  width: 300px;
  margin: 0 auto;
  justify-content: space-between;
}

.data{
  font-size: 14px;
  color: #000000;
  text-align: left;
}

.numero-container{
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.codArea {
    width: 85px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}


.numero {
    width: 200px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.email-container{
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.email {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
}

.motivoPep {
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
    align-self: center;
}
















.estado-civil-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.pregunta-estado{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-estado{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}




.opcion-estado{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-estado:hover{
    text-decoration: underline;
}







.pep-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.preguntaT-pep{
    width: 280px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.pregunta-pep{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-pep{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}




.opcion-pep{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-pep:hover{
    text-decoration: underline;
}













.confError{
  width: 300px;
  margin: 0 auto;
  align-items: flex-start;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: rgba(12, 82, 151, 0.1);
  overflow: visible;
  padding-top:15px;
  padding-bottom:15px;
  margin-top: 20px;
}


.confErrorTest{
  width: 300px;
  color: #e63d3d;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}









.clickAca{
    color: #0C5297;
}


.agregar{
    width: 300px;
    color: #0C5297;
    font-size: 14px;
    text-align: left;
}

.agregar:hover{
    text-decoration: underline;
}


.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.modal-content-pep{
    width: 35%;
    height: 700px;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.pepText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    height: 500px;
    overflow-y: scroll;
}


.modal-content-error{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}


.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.error{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
}



.modal-content-exito{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.exitoText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}






.continuar{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:20px;
}


@media only screen and (max-width: 845px) {
    .comp-container{
      width: 80%;
      min-height: 700px;
    }

    .modal-content-pep{
        width: 90%;
    }
    
    .modal-content-exito{
        width: 90%;
    }
    .modal-content-error{
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){
    
    .datos-container{
      width: 215px;
    }

    .nombreApellido{
      width: 215px;
    }

    .numero-container{
        width: 215px;
    }

    .codArea{
        width: 80px;
    }
    
    .numero{
        width: 130px;
    }

    .email-container{
        width: 215px;
    }

    .email{
        width: 215px;
    }

    .pep-container{
        width: 215px;
    }

    .opciones-pep{
        width: 215px;
        margin: 0 auto;
    }

    .motivoPep{
        width: 215px;
    }

    .estado-civil-container{
        width: 215px;
    }

    .pregunta-estado{
        width: 215px;
    }

    .cerrar-modal{
      width: 200px;
    }
    
    .agregar{
        width: 215px;
    }

    .continuar{
        width: 215px;
    }

    .modal-content-error{
        width: 90%;
    }

    .preguntaT-pep{
        width: 190px;
    }
    .confError{
      width: 215px;
    }
    .confErrorTest{
      width: 215px;
    }

}

</style>