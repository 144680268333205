<template>

  <div class="paso4">


    <div class="comp-container">


      <h1>
        Paso 4
      </h1>

      <span class="titulo">
       {{ titulo }}<!-- Datos de los apoderados -->
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
      </div>





        <div class="expCapital-container">

            <div class="dropdown-button" @click="dropExpCapital()">
                <p ref="preguntaExpCapital" class="pregunta-expCapital">¿Qué conocimiento y experiencia tiene en Mercado de Capitales?</p><fa icon="chevron-down" v-if="chevronDownExpCapital" /><fa icon="chevron-up" v-if="chevronUpExpCapital" />
            </div>

            <div v-show="dropContentExpCapital" class="opciones-expCapital">
                <button ref="expCapital1" @click="expCapitalSelect($event)" class="opcion-expCapital" value="nada">Nada</button>
                <button ref="expCapital2" @click="expCapitalSelect($event)" class="opcion-expCapital" value="poco">Poco</button>
                <button ref="expCapital3" @click="expCapitalSelect($event)" class="opcion-expCapital" value="mucho">Mucho</button>
                <button ref="expCapital4" @click="expCapitalSelect($event)" class="opcion-expCapital" value="profesional">Soy profesional en finanzas</button>
            </div>
        </div>



        <div class="objetivo-container">

            <div class="dropdown-button" @click="dropObjetivo()">
                <p ref="preguntaObjetivo" class="pregunta-objetivo">¿Cuál es su objetivo de inversión?</p><fa icon="chevron-down" v-if="chevronDownObjetivo" /><fa icon="chevron-up" v-if="chevronUpObjetivo" />
            </div>

            <div v-show="dropContentObjetivo" class="opciones-objetivo">
                <button ref="objetivo1" @click="objetivoSelect($event)" class="opcion-objetivo" value="preservar">Preservar el valor de mis activos.</button>
                <button ref="objetivo2" @click="objetivoSelect($event)" class="opcion-objetivo" value="incrementar">Incrementar el valor de mis activos.</button>
            </div>
        </div>


        <div class="horizonte-container">

            <div class="dropdown-button" @click="dropHorizonte()">
                <p ref="preguntaHorizonte" class="pregunta-horizonte">¿Cuál es su horizonte de inversión previsto?</p><fa icon="chevron-down" v-if="chevronDownHorizonte" /><fa icon="chevron-up" v-if="chevronUpHorizonte" />
            </div>

            <div v-show="dropContentHorizonte" class="opciones-horizonte">
                <button ref="horizonte1" @click="horizonteSelect($event)" class="opcion-horizonte" value="corto">Corto (hasta 1 año)</button>
                <button ref="horizonte2" @click="horizonteSelect($event)" class="opcion-horizonte" value="medio">Mediano (1 a 2 años)</button>
                <button ref="horizonte3" @click="horizonteSelect($event)" class="opcion-horizonte" value="largo">Largo (más de 2 años)</button>
            </div>
        </div>


        <div class="porcentaje-container">

            <br>
            
            <div class="dropdown-button" @click="dropPorcentaje()">
                <p ref="preguntaPorcentaje" class="pregunta-porcentaje">¿Qué porcentaje de sus ahorros va a destinar a inversiones?</p><fa icon="chevron-down" v-if="chevronDownPorcentaje" /><fa icon="chevron-up" v-if="chevronUpPorcentaje" />
            </div>

            <div v-show="dropContentPorcentaje" class="opciones-porcentaje">
                <button ref="porcentaje1" @click="porcentajeSelect($event)" value="25" class="opcion-porcentaje">Hasta 25%</button>
                <button ref="porcentaje2" @click="porcentajeSelect($event)" value="50" class="opcion-porcentaje">De 25% a 50%</button>
                <button ref="porcentaje3" @click="porcentajeSelect($event)" value="90" class="opcion-porcentaje">De 50% a 90%</button>
                <button ref="porcentaje4" @click="porcentajeSelect($event)" value="90+" class="opcion-porcentaje">Más de 90%</button>
            </div>
        </div>



        <div class="riesgo-container">

            <br>

            <div class="dropdown-button" @click="dropRiesgo()">
                <p ref="preguntaRiesgo" class="pregunta-riesgo">El riesgo que Ud. asume varía de acuerdo a los instrumentos en que invierte. Por favor, seleccione el suyo.</p><fa icon="chevron-down" v-if="chevronDownRiesgo" /><fa icon="chevron-up" v-if="chevronUpRiesgo" />
            </div>

            <div v-show="dropContentRiesgo" class="opciones-riesgo">
                
                <div style="display:flex; align-items: center;">
                <button ref="riesgo1" @click="riesgoSelect($event)" class="opcion-riesgo" value="bajo">Riesgo Bajo</button>&nbsp;&nbsp;<button><fa icon="question-circle" @click="modalBajoShow()" class="question-circle" /></button>
                </div>

                <div style="display:flex; align-items: center;">
                <button ref="riesgo2" @click="riesgoSelect($event)" class="opcion-riesgo" value="medio">Riesgo Medio</button>&nbsp;&nbsp;<button><fa icon="question-circle" @click="modalMedioShow()" class="question-circle" /></button>
                </div>

                <div style="display:flex; align-items: center;">
                <button ref="riesgo3" @click="riesgoSelect($event)" class="opcion-riesgo" value="alto">Riesgo Alto</button>&nbsp;&nbsp;<button><fa icon="question-circle" @click="modalAltoShow()" class="question-circle" /></button>
                </div>
            </div>
        </div>


        <!-- <div class="paisRiesgo-container">
            <div class="dropdown-button" @click="dropPaisRiesgo()">
                <p ref="preguntaPaisRiesgo" class="pregunta-paisRiesgo">¿Posee accionistas y/o beneficiarios con residencia/nacionalidad en algún país o territorio de riesgo/no colaborador? (Seleccionar lo que corresponda)</p><fa icon="chevron-down" v-if="chevronDownPaisRiesgo" /><fa icon="chevron-up" v-if="chevronUpPaisRiesgo" />
            </div>

            <div v-show="dropContentPaisRiesgo" class="opciones-paisRiesgo">
                <p ref="paisRiesgo1" @click="paisRiesgoSelect($event)" value="true" class="opcion-paisRiesgo">Si</p>
                <p ref="paisRiesgo2" @click="paisRiesgoSelect($event)" value="false" class="opcion-paisRiesgo">No</p>
            </div>
        </div> -->



        <button class="continuar" @click="continuar()">
            Continuar
        </button>







    </div>

    



    <transition name="fade">
        <div class="modal-fade" v-show="modalBajo">
            <div class="modal-content-bajo">
                <br>
                <h2>¿Qué es un riesgo Bajo?</h2>
                <br>
                <br>

                <p class="bajo">
                    Comprende: Compra/Venta de:<br>
                    Títulos Públicos (Nacionales; Provinciales y Municipales), Letras del BCRA, Fideicomisos Financieros, Cheques de Pago Diferido, Fondos Comunes de Inversión de Renta Fija Obligaciones Negociables; Caución Colocadora y Préstamo Colocador.
                </p>
                <br>

                <button @click="modalBajoShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>


    
    <transition name="fade">
        <div class="modal-fade" v-show="modalMedio">
            <div class="modal-content-medio">
                <br>
                <h2>¿Qué es un riesgo Medio?</h2>
                <br>
                <br>

                <p class="medio">
                    Comprende todas las Operaciones de RIESGO BAJO más:<br>
                    Compra/Venta de: acciones ordinarias y preferidas, Fondos Comunes de Inversión de renta variable, Acciones negociables en mercados del exterior y Préstamo tomador.
                </p>
                <br>

                <button @click="modalMedioShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>


    
    <transition name="fade">
        <div class="modal-fade" v-show="modalAlto">
            <div class="modal-content-alto">
                <br>
                <h2>¿Qué es un riesgo Alto?</h2>
                <br>
                <br>

                <p class="alto">
                    Comprende todas las Operaciones de RIESGO MEDIO más:<br>
                    Compra/Venta de futuros (de títulos públicos, de moneda, de títulos privados, de metales o productos agropecuarios), Opciones de títulos privados y públicos; Caución tomadora; compra de activos con apalancamiento sobre garantía de dicho activo y/u otros; préstamo tomador con venta en corto y Fondos Comunes de Inversión Cerrados; Compra/Venta de Opciones de títulos privados y públicos.
                </p>
                <br>

                <button @click="modalAltoShow()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>




    <transition name="fade">
        <div class="modal-fade" v-show="completarError">
            <div class="modal-content-completar">

                <p class="completar">
                    Por favor, verifique haber completado todos los campos antes de avanzar
                </p>

                <button @click="cerrarModalError()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>



    <!-- <transition name="fade">
        <div class="modal-fade" v-show="modalRiesgoSi">
            <div class="modal-content-riesgoSi">
                
                <h3>¿En que país residen en su mayoría los accionistas extranjeros?</h3>

                <residenciaRiesgo v-on:residenciaElegida="residenciaElegida($event)" v-on:modalRiesgoSiShow="modalRiesgoSiShow()" />

            </div>
        </div>
    </transition> -->


    
    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loadingShow">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" src="../assets/loading.gif" alt="">
                </div>
            </div>
        </div> 
    </transition>




  </div>

</template>

<script>
// import residenciaRiesgo from '../components/residenciaRiesgo.vue'

export default {
    data(){
        return{
            titulo: "Perfil de Riesgo de la Persona Jurídica",
            modalBajo: false,
            modalMedio: false,
            modalAlto: false,
            expCapitalValue: null,
            objetivoValue: null,
            horizonteValue: null,
            porcentajelValue: null,
            riesgoValue: null,
            completarError: false,
            dropContentExpCapital: true,
            chevronUpExpCapital: false,
            chevronDownExpCapital: true,
            dropContentObjetivo: true,
            chevronUpObjetivo: false,
            chevronDownObjetivo: true,
            dropContentHorizonte: true,
            chevronUpHorizonte: false,
            chevronDownHorizonte: true,
            dropContentPorcentaje: true,
            chevronUpPorcentaje: false,
            chevronDownPorcentaje: true,
            dropContentRiesgo: true,
            chevronUpRiesgo: false,
            chevronDownRiesgo: true,
            modalRiesgoSi: false,
            residencia: null,
            token: null,
            loadingShow: false,
        }
    },
    // components: {
    //     residenciaRiesgo
    // },
    mounted() {             
      this.token = localStorage["token"]
    },
    methods:{
        dropExpCapital(){
            if (this.dropContentExpCapital == true){
                this.dropContentExpCapital = false
                this.chevronUpExpCapital = true
                this.chevronDownExpCapital = false
            } else {
                this.dropContentExpCapital = true
                this.chevronDownExpCapital = true
                this.chevronUpExpCapital = false
            }
        },
        dropObjetivo(){
            if (this.dropContentObjetivo == true){
                this.dropContentObjetivo = false
                this.chevronUpObjetivo = true
                this.chevronDownObjetivo = false
            } else {
                this.dropContentObjetivo = true
                this.chevronDownObjetivo = true
                this.chevronUpObjetivo = false
            }
        },
        dropHorizonte(){
            if (this.dropContentHorizonte == true){
                this.dropContentHorizonte = false
                this.chevronUpHorizonte = true
                this.chevronDownHorizonte = false
            } else {
                this.dropContentHorizonte = true
                this.chevronDownHorizonte = true
                this.chevronUpHorizonte = false
            }
        },
        dropPorcentaje(){
            if (this.dropContentPorcentaje == true){
                this.dropContentPorcentaje = false
                this.chevronUpPorcentaje = true
                this.chevronDownPorcentaje = false
            } else {
                this.dropContentPorcentaje = true
                this.chevronDownPorcentaje = true
                this.chevronUpPorcentaje = false
            }
        },
        dropRiesgo(){
            if (this.dropContentRiesgo == true){
                this.dropContentRiesgo = false
                this.chevronUpRiesgo = true
                this.chevronDownRiesgo = false
            } else {
                this.dropContentRiesgo = true
                this.chevronDownRiesgo = true
                this.chevronUpRiesgo = false
            }
        },
        // dropPaisRiesgo(){
        //     if (this.dropContentPaisRiesgo == true){
        //         this.dropContentPaisRiesgo = false
        //         this.chevronUpPaisRiesgo = true
        //         this.chevronDownPaisRiesgo = false
        //     } else {
        //         this.dropContentPaisRiesgo = true
        //         this.chevronDownPaisRiesgo = true
        //         this.chevronUpPaisRiesgo = false
        //     }
        // },
        modalBajoShow(){
            if (this.modalBajo == true) {
                this.modalBajo = false
            } else {
                this.modalBajo = true
            }
            this.scrollToTop()
        },
        modalMedioShow(){
            if (this.modalMedio == true) {
                this.modalMedio = false
            } else {
                this.modalMedio = true
            }
            this.scrollToTop()
        },
        modalAltoShow(){
            if (this.modalAlto == true) {
                this.modalAlto = false
            } else {
                this.modalAlto = true
            }
            this.scrollToTop()
        },
        modalRiesgoSiShow(){
            if (this.modalRiesgoSi == true) {
                this.modalRiesgoSi = false
            } else {
                this.modalRiesgoSi = true
            }
            this.scrollToTop()
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        expCapitalSelect(e){
            this.$refs.expCapital1.style.color = "#000";
            this.$refs.expCapital2.style.color = "#000";
            this.$refs.expCapital3.style.color = "#000";
            this.$refs.expCapital4.style.color = "#000";

            this.$refs.preguntaExpCapital.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.expCapital = elementValue
        },
        objetivoSelect(e){
            this.$refs.objetivo1.style.color = "#000";
            this.$refs.objetivo2.style.color = "#000";

            this.$refs.preguntaObjetivo.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.objetivo = elementValue
        },
        horizonteSelect(e){
            this.$refs.horizonte1.style.color = "#000";
            this.$refs.horizonte2.style.color = "#000";
            this.$refs.horizonte3.style.color = "#000";

            this.$refs.preguntaHorizonte.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.horizonte = elementValue
        },
        porcentajeSelect(e){
            this.$refs.porcentaje1.style.color = "#000";
            this.$refs.porcentaje2.style.color = "#000";
            this.$refs.porcentaje3.style.color = "#000";
            this.$refs.porcentaje4.style.color = "#000";

            this.$refs.preguntaPorcentaje.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.porcentaje = elementValue
        },
        riesgoSelect(e){
            this.$refs.riesgo1.style.color = "#000";
            this.$refs.riesgo2.style.color = "#000";
            this.$refs.riesgo3.style.color = "#000";

            this.$refs.preguntaRiesgo.style.color = "#5BABBE";

            let elementValue = e.target.getAttribute('value')
            e.target.style.color = "#5BABBE"
            this.riesgo = elementValue
        },
        // paisRiesgoSelect(e){
        //     this.$refs.paisRiesgo1.style.color = "#000";
        //     this.$refs.paisRiesgo2.style.color = "#000";

        //     this.$refs.preguntaPaisRiesgo.style.color = "#5BABBE";

        //     let elementValue = e.target.getAttribute('value')
        //     e.target.style.color = "#5BABBE"
        //     this.paisRiesgo = elementValue
        // 
        //     if (this.paisRiesgo == "true") {
        //         this.modalRiesgoSiShow()
        //     }
        // },
        residenciaElegida(e){
            this.residencia = e.toString()
        },
        cerrarModalError(){
            this.completarError = false
        },
        continuar(){

            if (this.expCapital == null || this.objetivo == null || this.horizonte == null || this.porcentaje == null || this.riesgo == null){
                this.completarError = true
                this.scrollToTop()
            } else {
                this.completarError = false

                    localStorage.conocimiento_api = this.expCapital
                    localStorage.objetivo_api = this.objetivo
                    localStorage.horizonte_api = this.horizonte
                    localStorage.porcentaje_api = this.porcentaje
                    localStorage.riesgo_api = this.riesgo
                    // localStorage.accionista_riesgo_api = this.paisRiesgo
                    // localStorage.pais_fuera_arg_api = this.residencia

                    var data = {
                        paso_4: 'true'
                    }

                    
    

                    const options = {
                    method: 'POST',
                    data,
                    headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
                    url: `https://bkpj.allaria.com.ar/api/v1/paso-4/`,
                    };
                    this.loadingShow = true
                    this.axios(options)
                    .then((result) => {
                        this.result = result
                        
                        this.$router.push("/Paso5")
                    })
                    .catch((error) => {
                        this.loadingShow = false
                        this.error = error
                    })

                    

            }
            
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
*{
  font-family: 'Lato', sans-serif;
}
.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.dropdown-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.comp-container{
  padding-top: 20px;
  min-height: 700px;
  width: 30%;
  margin: 0 auto;
  background-color: #FBFBFB;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}

.close{
  display: flex;
  justify-content: flex-end;
}

.cross{
  margin-right: 20px;
  margin-top: 15px;
}

h1{
  font-size: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.titulo{
  font-weight: 600;
  letter-spacing: 0.14em;
}

.dot-div{
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dot{
  color: #0C5297;
  opacity: 0.4;
  width: 10px;
}

.this-dot{
  color: #0C5297;
  width: 10px;
}

.expCapital-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-expCapital{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-expCapital{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-expCapital{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-expCapital:hover{
    text-decoration: underline;
}

.objetivo-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-objetivo{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-objetivo{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-objetivo{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-objetivo:hover{
    text-decoration: underline;
}

.horizonte-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-horizonte{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-horizonte{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-horizonte{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-horizonte:hover{
    text-decoration: underline;
}

.porcentaje-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-porcentaje{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-porcentaje{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-porcentaje{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-porcentaje:hover{
    text-decoration: underline;
}

.riesgo-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-riesgo{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-riesgo{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-riesgo{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-riesgo:hover{
    text-decoration: underline;
}

/* .paisRiesgo-container{
    width: 300px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
    margin: 0 auto;
}

.pregunta-paisRiesgo{
    width: 300px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    text-align: left;
}

.opciones-paisRiesgo{
    width: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.opcion-paisRiesgo{
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.018em;
}

.opcion-paisRiesgo:hover{
    text-decoration: underline;
} */

.question-circle{
    color: #0C5297;
    z-index: 100;
}

.continuar{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:20px;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}


.modal-content-bajo{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.bajo{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.modal-content-medio{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.medio{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.modal-content-alto{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.alto{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}


.modal-content-completar{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.completar{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.modal-content-riesgoSi{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.riesgoSi{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}


@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}
@media only screen and (max-width: 845px) {
    .comp-container{
      width: 80%;
    }
    .modal-content-bajo{
        width: 90%;
    }
    .modal-content-medio{
        width: 90%;
    }
    .modal-content-alto{
        width: 90%;
    }
    .modal-content-completar{
        width: 90%;
    }
    .modal-content-riesgoSi{
        width: 90%;
    }
}
@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }
  .close{
    display: none;
  }

}
@media only screen and (max-width: 340px){
    .expCapital-container{
        width: 215px;
    }
    .pregunta-expCapital{
        width: 215px;
    }
    .objetivo-container{
        width: 215px;
    }
    .pregunta-objetivo{
        width: 215px;
    }
    .horizonte-container{
        width: 215px;
    }
    .pregunta-horizonte{
        width: 215px;
    }
    .porcentaje-container{
        width: 215px;
    }
    .pregunta-porcentaje{
        width: 215px;
    }
    .riesgo-container{
        width: 215px;
    }
    .pregunta-riesgo{
        width: 215px;
    }
    /* .paisRiesgo-container{
        width: 215px;
    }
    .pregunta-paisRiesgo{
        width: 215px;
    } */
    .continuar{
        width: 215px;
    }
    .cerrar-modal{
      width: 200px;
    }
    .comp-container{
        overflow-x: hidden;
    }
}
</style>