<template>
        <div>
          <div class="sociedad-container">
            <p class="sociedad">
              Tipo de sociedad: Fideicomiso
            </p>
          </div>
<!-- ---------------------------Contrato Fideicomiso---------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarContratoFideicomiso">
              <input id="contratoFideicomiso" ref="contratoFideicomiso" v-on:change="handleUploadContratoFideicomiso($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinContratoFideicomiso" class="archivo-button" @click="elegirContratoFideicomiso()">
                  {{botonContratoFideicomisoText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>


<!-- -----------balance cpce--------------------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarBalanceCpce">
              <input id="balanceCpce" ref="balanceCpce" v-on:change="handleUploadBalanceCpce($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinBalanceCpce" class="archivo-button" @click="modalBalanceShow()">
                  {{botonBalanceCpceText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<transition name="fade">
        <div class="modal-fade" v-show="modalBalance">
            <div class="modal-content">

                <br>
                <h3>Balance certificado por el CPCE</h3>
                <br>
                <br>

                <p class="modalText">
                    Si todavía no cuenta con su 1er balance cerrado, por favor enviar documentación patrimonial que demuestre sus actividades al día de la fecha.
                </p>
                <br>

                <button @click="elegirBalanceCpce()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>
            

<!-- ---------------------------DDJJ de sujeto obligado----------------------------------------------------------------------------- -->
<!-- 
            <div v-if="cargarDdjj">
              <input id="ddjj" ref="ddjj" v-on:change="handleUploadDdjj($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinDdjj" class="archivo-button" @click="elegirDdjj()">
                  {{botonDdjjText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div> -->



<!-- ---------------------------Contancia de inscripcion ante la UIF del fiduciario---------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarConstanciaUif">
              <input id="constanciaUif" ref="constanciaUif" v-on:change="handleConstanciaUif($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinConstanciaUif" class="archivo-button" @click="elegirConstanciaUif()">
                  {{botonConstanciaUifText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>


<!-- ---------------poder----------------------------------------------------------------------------------------------------------------------------- -->


            <div v-if="cargarPoder">
              <input id="poder" ref="poder" v-on:change="handleUploadPoder($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinPoder" class="archivo-button" @click="modalPoderShow()">
                  {{botonPoderText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<transition name="fade">
        <div class="modal-fade" v-show="modalPoder">
            <div class="modal-content">

                <br>
                <h3>Poder de los firmantes</h3>
                <br>
                <br>

                <p class="modalText">
                    Corresponde en caso de poseer firmantes apoderados.
                </p>
                <br>

                <button @click="elegirPoder()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>

<!-- ---------------estatuto y actas de designacion de directorio----------------------------------------------------------------------------------------------------------------- -->


            <div v-if="cargarEstatutoActa">
              <input id="estatutoActa" ref="estatutoActa" v-on:change="handleUploadEstatutoActa($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinEstatutoActa" class="archivo-button" @click="elegirEstatutoActa()">
                  {{botonEstatutoActaText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>


<!-- ------------------------otros-------------------------------------------------------------------------------------------------------------------- -->

            <div>
              <input id="otros" ref="otros" v-on:change="handleUploadOtros($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinOtros" class="archivo-button" @click="showModalOtros()">
                  {{ otrosText }}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

            <transition name="fade">
              <div class="modal-fade" v-show="modalOtros">
                  <div class="modal-content">
                      <br>
                      <h3>Otros Archivos</h3>
                      <br>
                      <br>

                      <p class="modalText">
                          Por favor, adjunte la documentación complementaria que considere necesaria para la apertura de la cuenta.
                      </p>
                      <br>

                      <button @click="elegirOtros()" class="cerrar-modal">
                          Ok
                      </button>

                  </div>
              </div>
            </transition>





            <transition class="loadingcomponent-container-standard" name="fade-standard">
                <div class="modal-fade-standard" v-if="loadingShow">
                    <div class="modal-content-completar-standard">
                        <div class="loading-container-standard">
                            <img class="loading-standard" src="../assets/loading.gif" alt="">
                        </div>
                    </div>
                </div> 
            </transition>



            <terminos v-on:checkTerminos="checkTerminos($event)" v-on:terminosChequeados="terminosChequeados($event)" />



                  <div class="boton-volver">
                    <button @click="sociedadUnselect()" class="volver">
                      <br>
                      <fa icon="arrow-circle-left" />&nbsp;&nbsp; Volver
                    </button>
                  </div>





            <button ref="continuar" :disabled="desactivar" class="continuar" @click="continuarArchivos()">
                Continuar
            </button>


        </div>
</template>


<script>
import terminos from './terminos.vue'
export default {
  components:{
    terminos
  },
  data(){
    return {
      cargarContratoFideicomiso: true,
      contratoFideicomiso: false,
      sinContratoFideicomiso: true,
      botonContratoFideicomisoText: 'Subir el Contrato de Fideicomiso (*)',
      contratoFideicomisoContador: 0,

      cargarBalanceCpce: true,
      balanceCpce: false,
      sinBalanceCpce: true,
      botonBalanceCpceText: 'Subir el Balance certificado por el CPCE o Liqidación de Expensas (*)',
      balanceCpceContador: 0,

      // cargarDdjj: true,
      // ddjj: false,
      // sinDdjj: true,
      // botonDdjjText: 'Subir la DDJJ de sujeto obligado (*)',
      // ddjjContador: 0,

      cargarConstanciaUif: true,
      constanciaUif: false,
      sinConstanciaUif: true,
      botonConstanciaUifText: 'Subir la Constancia de inscripcion ante la UIF del fiduciario (*)',
      constanciaUifContador: 0,

      cargarPoder: true,
      poder: false,
      sinPoder: true,
      botonPoderText: 'Subir el Poder de los Firmantes',
      poderContador: 0,

      cargarEstatutoActa: true,
      estatutoActa: false,
      sinEstatutoActa: true,
      botonEstatutoActaText: 'Subir el estatuto y actas de designacion de directorio del Fiduciario (*)',
      estatutoActaContador: 0,

      modalBalance: null,
      modalPoder: null,
      desactivar: null,
      todosArchivos: null,
      todosTerminos: false,
      validacion: false,
      tipoSociedad: "fc",

      contratoFideicomiso_api: null,
      contratoFideicomiso2_api: null,
      contratoFideicomiso3_api: null,
      contratoFideicomiso4_api: null,
      contratoFideicomiso5_api: null,
      contratoFideicomiso6_api: null,
      contratoFideicomiso7_api: null,
      contratoFideicomiso8_api: null,
      contratoFideicomiso9_api: null,
      contratoFideicomiso10_api: null,

      balanceCpce_api: null,
      balanceCpce2_api: null,
      balanceCpce3_api: null,
      balanceCpce4_api: null,
      balanceCpce5_api: null,
      balanceCpce6_api: null,
      balanceCpce7_api: null,
      balanceCpce8_api: null,
      balanceCpce9_api: null,
      balanceCpce10_api: null,

      ddjj_api: null,

      constanciaUif_api: null,
      constanciaUif2_api: null,
      constanciaUif3_api: null,
      constanciaUif4_api: null,
      constanciaUif5_api: null,
      constanciaUif6_api: null,
      constanciaUif7_api: null,
      constanciaUif8_api: null,
      constanciaUif9_api: null,
      constanciaUif10_api: null,

      poder_api: null,
      poder2_api: null,
      poder3_api: null,
      poder4_api: null,
      poder5_api: null,
      poder6_api: null,
      poder7_api: null,
      poder8_api: null,
      poder9_api: null,
      poder10_api: null,

      estatutoActa_api: null,
      estatutoActa2_api: null,
      estatutoActa3_api: null,
      estatutoActa4_api: null,
      estatutoActa5_api: null,
      estatutoActa6_api: null,
      estatutoActa7_api: null,
      estatutoActa8_api: null,
      estatutoActa9_api: null,
      estatutoActa10_api: null,
      
      token: null,
      ip_api: null,

      loadingShow: false,
      date: new Date,
      otros: 0,
      otros1_api: null,
      otros2_api: null,
      otros3_api: null,
      otros4_api: null,
      otros5_api: null,
      otros6_api: null,
      otros7_api: null,
      otros8_api: null,
      otros9_api: null,
      otros10_api: null,
      terminos_condiciones_api: false,
      reglamento_gestion_api: false,
      mandato_comafi_api: false,
      mandato_valores_api: false,
      nota_retencion_api: false,
      otrosText: "Otros [0]",
      sinOtros: true,
      modalOtros: false,
      modalYa: 0
    }
  },
  mounted() {             
    this.token = localStorage["token"]

    localStorage.tipo_sociedad = this.tipoSociedad;

    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          this.ip_api = ip
    });

    this.estructura_compleja_api = this.stringToBoolean(localStorage["estructura_compleja"])
    this.entidad_publica_api = this.stringToBoolean(localStorage["entidad_publica"])
    this.grupo_economico_api = this.stringToBoolean(localStorage["grupo_economico"])
    this.pais_residencia_api = localStorage["pais_residencia"]
    this.tipo_sociedad_api = localStorage["tipo_sociedad"]
  },
  methods: {
    formatFechaHora(){
      let date_ob = new Date();

      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
    },
    stringToBoolean(string) {
        switch(string.toLowerCase().trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
      }
    },
    sociedadUnselect(){
      this.$emit("sociedadUnselect")
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    elegirContratoFideicomiso(){
      document.getElementById("contratoFideicomiso").click()
    },
    checkContratoFideicomiso(){
      this.contratoFideicomiso = true
      this.sinContratoFideicomiso = false
      this.validarArchivos()
    },
    elegirBalanceCpce(){
      this.modalBalanceShow()
      document.getElementById("balanceCpce").click()
    },
    checkBalanceCpce(){
      this.balanceCpce = true
      this.sinBalanceCpce = false
      this.validarArchivos()
    },
    elegirDdjj(){
      document.getElementById("ddjj").click()
    },
    checkDdjj(){
      this.ddjj = true
      this.sinDdjj = false
      this.validarArchivos()
    },
    elegirConstanciaUif(){
      document.getElementById("constanciaUif").click()
    },
    checkConstanciaUif(){
      this.constanciaUif = true
      this.sinConstanciaUif = false
      this.validarArchivos()
    },
    elegirPoder(){
      this.modalPoderShow()
      document.getElementById("poder").click()
    },
    checkPoder(){
      this.poder = true
      this.sinPoder = false
    },
    elegirEstatutoActa(){
      document.getElementById("estatutoActa").click()
    },
    checkEstatutoActa(){
      this.estatutoActa = true
      this.sinEstatutoActa = false
      this.validarArchivos()
    },
    modalBalanceShow(){
      if (this.modalBalance == true) {
        this.modalBalance = false
      } else {
        this.modalBalance = true
        this.scrollToTop()
      }
    },
    modalPoderShow(){
      if (this.modalPoder == true) {
        this.modalPoder = false
      } else {
        this.modalPoder = true
        this.scrollToTop()
      }
    },
    validarArchivos(){
      // obligatorios: contratoFideicomiso  balance Cpce estatutoActa ddjj  constanciaUif

      if (this.balanceCpce == true && this.contratoFideicomiso == true && this.estatutoActa == true && this.constanciaUif == true){
        this.todosArchivos = true
        this.validarDatos()
      } else {
        this.todosArchivos = false
        this.validarDatos()
      }
    },
    checkTerminos(e){
      this.todosTerminos = e
      this.validarDatos()
    },
    validarDatos(){
      if (this.todosTerminos == true && this.todosArchivos == true){
        this.validacion = true
        this.desactivar = false

        this.$refs.continuar.style.backgroundColor = "#0C5297";

      } else {
        this.validacion = false
        this.desactivar = true

        this.$refs.continuar.style.backgroundColor = "#89aacc";
      }
    },
    handleUploadContratoFideicomiso(files) {
      let fotos = files.target.files
      if (this.contratoFideicomisoContador == 0){
        this.contratoFideicomiso_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 1){
        this.contratoFideicomiso2_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 2){
        this.contratoFideicomiso3_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 3){
        this.contratoFideicomiso4_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 4){
        this.contratoFideicomiso5_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 5){
        this.contratoFideicomiso6_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 6){
        this.contratoFideicomiso7_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 7){
        this.contratoFideicomiso8_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 8){
        this.contratoFideicomiso9_api = fotos[0];
      }
      if (this.contratoFideicomisoContador == 9){
        this.contratoFideicomiso10_api = fotos[0];
      }

      if (this.contratoFideicomiso_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [1]"
        this.contratoFideicomisoContador = 1
        this.contratoFideicomiso = true
      }
      if (this.contratoFideicomiso2_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [2]"
        this.contratoFideicomisoContador = 2
      }
      if (this.contratoFideicomiso3_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [3]"
        this.contratoFideicomisoContador = 3
      }
      if (this.contratoFideicomiso4_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [4]"
        this.contratoFideicomisoContador = 4
      }
      if (this.contratoFideicomiso5_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [5]"
        this.contratoFideicomisoContador = 5
      }
      if (this.contratoFideicomiso6_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [6]"
        this.contratoFideicomisoContador = 6
      }
      if (this.contratoFideicomiso7_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [7]"
        this.contratoFideicomisoContador = 7
      }
      if (this.contratoFideicomiso8_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [8]"
        this.contratoFideicomisoContador = 8
      }
      if (this.contratoFideicomiso9_api != null){
        this.botonContratoFideicomisoText = "Subir el Contrato de Fideicomiso (*) [9]"
        this.contratoFideicomisoContador = 9
      }
      if (this.contratoFideicomiso10_api != null){
        this.sinContratoFideicomiso = false
      }
      this.validarArchivos()

      // this.checkContratoFideicomiso()
    },
    handleUploadBalanceCpce(files) {
      let fotos = files.target.files
      // this.balanceCpce_api = fotos[0];

      if (this.balanceCpceContador == 0){
        this.balanceCpce_api = fotos[0];
      }
      if (this.balanceCpceContador == 1){
        this.balanceCpce2_api = fotos[0];
      }
      if (this.balanceCpceContador == 2){
        this.balanceCpce3_api = fotos[0];
      }
      if (this.balanceCpceContador == 3){
        this.balanceCpce4_api = fotos[0];
      }
      if (this.balanceCpceContador == 4){
        this.balanceCpce5_api = fotos[0];
      }
      if (this.balanceCpceContador == 5){
        this.balanceCpce6_api = fotos[0];
      }
      if (this.balanceCpceContador == 6){
        this.balanceCpce7_api = fotos[0];
      }
      if (this.balanceCpceContador == 7){
        this.balanceCpce8_api = fotos[0];
      }
      if (this.balanceCpceContador == 8){
        this.balanceCpce9_api = fotos[0];
      }
      if (this.balanceCpceContador == 9){
        this.balanceCpce10_api = fotos[0];
      }

      if (this.balanceCpce_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [1]"
        this.balanceCpceContador = 1
        this.balanceCpce = true
      }
      if (this.balanceCpce2_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [2]"
        this.balanceCpceContador = 2
      }
      if (this.balanceCpce3_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [3]"
        this.balanceCpceContador = 3
      }
      if (this.balanceCpce4_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [4]"
        this.balanceCpceContador = 4
      }
      if (this.balanceCpce5_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [5]"
        this.balanceCpceContador = 5
      }
      if (this.balanceCpce6_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [6]"
        this.balanceCpceContador = 6
      }
      if (this.balanceCpce7_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [7]"
        this.balanceCpceContador = 7
      }
      if (this.balanceCpce8_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [8]"
        this.balanceCpceContador = 8
      }
      if (this.balanceCpce9_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [9]"
        this.balanceCpceContador = 9
      }
      if (this.balanceCpce10_api != null){
        this.sinBalanceCpce = false
      }
      this.validarArchivos()

      // this.checkBalanceCpce()
    },
    handleUploadDdjj(files) {
      let fotos = files.target.files
      this.ddjj_api = fotos[0];

      this.checkDdjj()
    },
    handleConstanciaUif(files) {
      let fotos = files.target.files

      if (this.constanciaUifContador == 0){
        this.constanciaUif_api = fotos[0];
      }
      if (this.constanciaUifContador == 1){
        this.constanciaUif2_api = fotos[0];
      }
      if (this.constanciaUifContador == 2){
        this.constanciaUif3_api = fotos[0];
      }
      if (this.constanciaUifContador == 3){
        this.constanciaUif4_api = fotos[0];
      }
      if (this.constanciaUifContador == 4){
        this.constanciaUif5_api = fotos[0];
      }
      if (this.constanciaUifContador == 5){
        this.constanciaUif6_api = fotos[0];
      }
      if (this.constanciaUifContador == 6){
        this.constanciaUif7_api = fotos[0];
      }
      if (this.constanciaUifContador == 7){
        this.constanciaUif8_api = fotos[0];
      }
      if (this.constanciaUifContador == 8){
        this.constanciaUif9_api = fotos[0];
      }
      if (this.constanciaUifContador == 9){
        this.constanciaUif10_api = fotos[0];
      }

      if (this.constanciaUif_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [1]"
        this.constanciaUifContador = 1
        this.constanciaUif = true
      }
      if (this.constanciaUif2_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [2]"
        this.constanciaUifContador = 2
      }
      if (this.constanciaUif3_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [3]"
        this.constanciaUifContador = 3
      }
      if (this.constanciaUif4_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [4]"
        this.constanciaUifContador = 4
      }
      if (this.constanciaUif5_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [5]"
        this.constanciaUifContador = 5
      }
      if (this.constanciaUif6_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [6]"
        this.constanciaUifContador = 6
      }
      if (this.constanciaUif7_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [7]"
        this.constanciaUifContador = 7
      }
      if (this.constanciaUif8_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [8]"
        this.constanciaUifContador = 8
      }
      if (this.constanciaUif9_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF del fiduciario (*) [9]"
        this.constanciaUifContador = 9
      }
      if (this.constanciaUif10_api != null){
        this.sinConstanciaUif = false
      }



















      this.validarArchivos()

      // this.checkConstanciaUif()
    },
    handleUploadPoder(files) {
      let fotos = files.target.files
      if (this.poderContador == 0){
        this.poder_api = fotos[0];
      }
      if (this.poderContador == 1){
        this.poder2_api = fotos[0];
      }
      if (this.poderContador == 2){
        this.poder3_api = fotos[0];
      }
      if (this.poderContador == 3){
        this.poder4_api = fotos[0];
      }
      if (this.poderContador == 4){
        this.poder5_api = fotos[0];
      }
      if (this.poderContador == 5){
        this.poder6_api = fotos[0];
      }
      if (this.poderContador == 6){
        this.poder7_api = fotos[0];
      }
      if (this.poderContador == 7){
        this.poder8_api = fotos[0];
      }
      if (this.poderContador == 8){
        this.poder9_api = fotos[0];
      }
      if (this.poderContador == 9){
        this.poder10_api = fotos[0];
      }

      if (this.poder_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [1]"
        this.poderContador = 1
        this.poder = true
      }
      if (this.poder2_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [2]"
        this.poderContador = 2
      }
      if (this.poder3_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [3]"
        this.poderContador = 3
      }
      if (this.poder4_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [4]"
        this.poderContador = 4
      }
      if (this.poder5_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [5]"
        this.poderContador = 5
      }
      if (this.poder6_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [6]"
        this.poderContador = 6
      }
      if (this.poder7_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [7]"
        this.poderContador = 7
      }
      if (this.poder8_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [8]"
        this.poderContador = 8
      }
      if (this.poder9_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [9]"
        this.poderContador = 9
      }
      if (this.poder10_api != null){
        this.sinPoder = false
      }
    },
    handleUploadEstatutoActa(files) {
      let fotos = files.target.files

      if (this.estatutoActaContador == 0){
        this.estatutoActa_api = fotos[0];
      }
      if (this.estatutoActaContador == 1){
        this.estatutoActa2_api = fotos[0];
      }
      if (this.estatutoActaContador == 2){
        this.estatutoActa3_api = fotos[0];
      }
      if (this.estatutoActaContador == 3){
        this.estatutoActa4_api = fotos[0];
      }
      if (this.estatutoActaContador == 4){
        this.estatutoActa5_api = fotos[0];
      }
      if (this.estatutoActaContador == 5){
        this.estatutoActa6_api = fotos[0];
      }
      if (this.estatutoActaContador == 6){
        this.estatutoActa7_api = fotos[0];
      }
      if (this.estatutoActaContador == 7){
        this.estatutoActa8_api = fotos[0];
      }
      if (this.estatutoActaContador == 8){
        this.estatutoActa9_api = fotos[0];
      }
      if (this.estatutoActaContador == 9){
        this.estatutoActa10_api = fotos[0];
      }

      if (this.estatutoActa_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [1]"
        this.estatutoActaContador = 1
        this.estatutoActa = true
      }
      if (this.estatutoActa2_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [2]"
        this.estatutoActaContador = 2
      }
      if (this.estatutoActa3_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [3]"
        this.estatutoActaContador = 3
      }
      if (this.estatutoActa4_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [4]"
        this.estatutoActaContador = 4
      }
      if (this.estatutoActa5_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [5]"
        this.estatutoActaContador = 5
      }
      if (this.estatutoActa6_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [6]"
        this.estatutoActaContador = 6
      }
      if (this.estatutoActa7_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [7]"
        this.estatutoActaContador = 7
      }
      if (this.estatutoActa8_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [8]"
        this.estatutoActaContador = 8
      }
      if (this.estatutoActa9_api != null){
        this.botonEstatutoActaText = "Subir el estatuto y actas de designacion de directorio del Fiduciario (*) [9]"
        this.estatutoActaContador = 9
      }
      if (this.estatutoActa10_api != null){
        this.sinEstatutoActa = false
      }





      this.validarArchivos()

      // this.checkEstatutoActa()
    },
    terminosChequeados(e){
      this.terminos_condiciones_api = e["terminos_condiciones"]
      this.reglamento_gestion_api = e["reglamento_gestion"]
      this.mandato_comafi_api = e["mandato_comafi"]
      this.mandato_valores_api = e["mandato_valores"]
      this.nota_retencion_api = e["nota_retencion"]
    },
    handleUploadOtros(files){
      let fotos = files.target.files

      if (this.otros == 0){
        this.otros1_api = fotos[0];
      }
      if (this.otros == 1){
        this.otros2_api = fotos[0];
      }
      if (this.otros == 2){
        this.otros3_api = fotos[0];
      }
      if (this.otros == 3){
        this.otros4_api = fotos[0];
      }
      if (this.otros == 4){
        this.otros5_api = fotos[0];
      }
      if (this.otros == 5){
        this.otros6_api = fotos[0];
      }
      if (this.otros == 6){
        this.otros7_api = fotos[0];
      }
      if (this.otros == 7){
        this.otros8_api = fotos[0];
      }
      if (this.otros == 8){
        this.otros9_api = fotos[0];
      }
      if (this.otros == 9){
        this.otros10_api = fotos[0];
      }


      if (this.otros1_api != null){
        this.otrosText = "Otros [1]"
        this.otros = 1
      }
      if (this.otros2_api != null){
        this.otrosText = "Otros [2]"
        this.otros = 2
      }
      if (this.otros3_api != null){
        this.otrosText = "Otros [3]"
        this.otros = 3
      }
      if (this.otros4_api != null){
        this.otrosText = "Otros [4]"
        this.otros = 4
      }
      if (this.otros5_api != null){
        this.otrosText = "Otros [5]"
        this.otros = 5
      }
      if (this.otros6_api != null){
        this.otrosText = "Otros [6]"
        this.otros = 6
      }
      if (this.otros7_api != null){
        this.otrosText = "Otros [7]"
        this.otros = 7
      }
      if (this.otros8_api != null){
        this.otrosText = "Otros [8]"
        this.otros = 8
      }
      if (this.otros9_api != null){
        this.otrosText = "Otros [9]"
        this.otros = 9
      }
      if (this.otros10_api != null){
        this.sinOtros = false
      }
    },
    elegirOtros(){
      this.modalOtros = false
      document.getElementById("otros").click()
    },
    showModalOtros(){
      if (this.modalYa == 1) {
        this.elegirOtros()
      } else {
        this.modalYa = 1
        this.modalOtros = true
      }
    },
    continuarArchivos(){

      // contratoFideicomiso_api
      // balanceCpce_api
      // ddjj_api
      // constanciaUif_api
      // poder_api
      // estatutoActa_api

      let data = new FormData()
        data.append('contrato_fideicomiso', this.contratoFideicomiso_api)
        if (this.contratoFideicomiso2_api != null) {
          data.append('contrato_fideicomiso_2', this.contratoFideicomiso2_api)
        }
        if (this.contratoFideicomiso3_api != null) {
          data.append('contrato_fideicomiso_3', this.contratoFideicomiso3_api)
        }
        if (this.contratoFideicomiso4_api != null) {
          data.append('contrato_fideicomiso_4', this.contratoFideicomiso4_api)
        }
        if (this.contratoFideicomiso5_api != null) {
          data.append('contrato_fideicomiso_5', this.contratoFideicomiso5_api)
        }
        if (this.contratoFideicomiso6_api != null) {
          data.append('contrato_fideicomiso_6', this.contratoFideicomiso6_api)
        }
        if (this.contratoFideicomiso7_api != null) {
          data.append('contrato_fideicomiso_7', this.contratoFideicomiso7_api)
        }
        if (this.contratoFideicomiso8_api != null) {
          data.append('contrato_fideicomiso_8', this.contratoFideicomiso8_api)
        }
        if (this.contratoFideicomiso9_api != null) {
          data.append('contrato_fideicomiso_9', this.contratoFideicomiso9_api)
        }
        if (this.contratoFideicomiso10_api != null) {
          data.append('contrato_fideicomiso_10', this.contratoFideicomiso10_api)
        }


        data.append('balance_certificado_cpce', this.balanceCpce_api)
        if (this.balanceCpce2_api != null) {
          data.append('balance_certificado_cpce_2', this.balanceCpce2_api)
        }
        if (this.balanceCpce3_api != null) {
          data.append('balance_certificado_cpce_3', this.balanceCpce3_api)
        }
        if (this.balanceCpce4_api != null) {
          data.append('balance_certificado_cpce_4', this.balanceCpce4_api)
        }
        if (this.balanceCpce5_api != null) {
          data.append('balance_certificado_cpce_5', this.balanceCpce5_api)
        }
        if (this.balanceCpce6_api != null) {
          data.append('balance_certificado_cpce_6', this.balanceCpce6_api)
        }
        if (this.balanceCpce7_api != null) {
          data.append('balance_certificado_cpce_7', this.balanceCpce7_api)
        }
        if (this.balanceCpce8_api != null) {
          data.append('balance_certificado_cpce_8', this.balanceCpce8_api)
        }
        if (this.balanceCpce9_api != null) {
          data.append('balance_certificado_cpce_9', this.balanceCpce9_api)
        }
        if (this.balanceCpce10_api != null) {
          data.append('balance_certificado_cpce_10', this.balanceCpce10_api)
        }
        
        data.append('constancia_uif', this.constanciaUif_api)
        if (this.constanciaUif2_api != null) {
          data.append('constancia_uif_2', this.constanciaUif2_api)
        }
        if (this.constanciaUif3_api != null) {
          data.append('constancia_uif_3', this.constanciaUif3_api)
        }
        if (this.constanciaUif4_api != null) {
          data.append('constancia_uif_4', this.constanciaUif4_api)
        }
        if (this.constanciaUif5_api != null) {
          data.append('constancia_uif_5', this.constanciaUif5_api)
        }
        if (this.constanciaUif6_api != null) {
          data.append('constancia_uif_6', this.constanciaUif6_api)
        }
        if (this.constanciaUif7_api != null) {
          data.append('constancia_uif_7', this.constanciaUif7_api)
        }
        if (this.constanciaUif8_api != null) {
          data.append('constancia_uif_8', this.constanciaUif8_api)
        }
        if (this.constanciaUif9_api != null) {
          data.append('constancia_uif_9', this.constanciaUif9_api)
        }
        if (this.constanciaUif10_api != null) {
          data.append('constancia_uif_10', this.constanciaUif10_api)
        }
        
        



        if (this.poder_api != null) {
          data.append('poder', this.poder_api)
        }
        if (this.poder2_api != null) {
          data.append('poder_2', this.poder2_api)
        }
        if (this.poder3_api != null) {
          data.append('poder_3', this.poder3_api)
        }
        if (this.poder4_api != null) {
          data.append('poder_4', this.poder4_api)
        }
        if (this.poder5_api != null) {
          data.append('poder_5', this.poder5_api)
        }
        if (this.poder6_api != null) {
          data.append('poder_6', this.poder6_api)
        }
        if (this.poder7_api != null) {
          data.append('poder_7', this.poder7_api)
        }
        if (this.poder8_api != null) {
          data.append('poder_8', this.poder8_api)
        }
        if (this.poder9_api != null) {
          data.append('poder_9', this.poder9_api)
        }
        if (this.poder10_api != null) {
          data.append('poder_10', this.poder10_api)
        }

        data.append('estatuto', this.estatutoActa_api)
        if (this.estatutoActa2_api != null) {
          data.append('estatuto_2', this.estatutoActa2_api)
        }
        if (this.estatutoActa3_api != null) {
          data.append('estatuto_3', this.estatutoActa3_api)
        }
        if (this.estatutoActa4_api != null) {
          data.append('estatuto_4', this.estatutoActa4_api)
        }
        if (this.estatutoActa5_api != null) {
          data.append('estatuto_5', this.estatutoActa5_api)
        }
        if (this.estatutoActa6_api != null) {
          data.append('estatuto_6', this.estatutoActa6_api)
        }
        if (this.estatutoActa7_api != null) {
          data.append('estatuto_7', this.estatutoActa7_api)
        }
        if (this.estatutoActa8_api != null) {
          data.append('estatuto_8', this.estatutoActa8_api)
        }
        if (this.estatutoActa9_api != null) {
          data.append('estatuto_9', this.estatutoActa9_api)
        }
        if (this.estatutoActa10_api != null) {
          data.append('estatuto_10', this.estatutoActa10_api)
        }

        if (this.otros1_api != null) {
          data.append('archivo_otro_1', this.otros1_api)
        }
        if (this.otros2_api != null) {
          data.append('archivo_otro_2', this.otros2_api)
        }
        if (this.otros3_api != null) {
          data.append('archivo_otro_3', this.otros3_api)
        }
        if (this.otros4_api != null) {
          data.append('archivo_otro_4', this.otros4_api)
        }
        if (this.otros5_api != null) {
          data.append('archivo_otro_5', this.otros5_api)
        }
        if (this.otros6_api != null) {
          data.append('archivo_otro_6', this.otros6_api)
        }
        if (this.otros7_api != null) {
          data.append('archivo_otro_7', this.otros7_api)
        }
        if (this.otros8_api != null) {
          data.append('archivo_otro_8', this.otros8_api)
        }
        if (this.otros9_api != null) {
          data.append('archivo_otro_9', this.otros9_api)
        }
        if (this.otros10_api != null) {
          data.append('archivo_otro_10', this.otros10_api)
        }

        data.append('termino_condiciones', this.terminos_condiciones_api)
        data.append('reglamento_gestion', this.reglamento_gestion_api)
        data.append('mandato_comafi', false)
        data.append('mandato_valores', false)
        data.append('nota_retencion', this.nota_retencion_api)
        // if (this.actaOrgano_api != null) {
        //   data.append('actas_miembros', this.actaOrgano_api)
        // }
        // data.append('actas_miembros', null)
        // data.append('declaracion_sujeto_obligado', null)
        // data.append('constancia_uif', null)
        // data.append('contrato_fideicomiso', null)
        // data.append('documentacion_fiduciaria', null)
        // data.append('contrato_constitutivo', null)
        // data.append('acta_determinacion_titulares', null)
        // data.append('documentacion_nomina_socios', null)
        // data.append('reglamento_copropiedad', null)
        // data.append('acta_administrador', null)
        // data.append('nomina_copropietarios', null)
        data.append('ip', this.ip_api)
        data.append('fecha_hora', this.date.toLocaleDateString())

        data.append('fecha_mandato_comafi', this.formatFechaHora())
        data.append('fecha_reglamento', this.formatFechaHora())
        data.append('fecha_negociacion', this.formatFechaHora())
        data.append('fecha_convenio', this.formatFechaHora())
        data.append('fecha_mandato_valores', this.formatFechaHora())
        data.append('fecha_tyc_allaria', this.formatFechaHora())


        const options = {
          method: 'POST',
          data,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${this.token}` },
          url: `https://bkpj.allaria.com.ar/api/v1/documentos/`,
        };
        this.loadingShow = true
        this.axios(options)
        .then(() => {

            var data = {
                pais_residencia: this.pais_residencia_api,
                grupo_economico: this.grupo_economico_api,
                estructura_compleja: this.estructura_compleja_api,
                entidad_publica: this.entidad_publica_api,
                tipo_sociedad: this.tipo_sociedad_api,
                daruma: false,
                daruma_terminos: false,
                daruma_legajo: false,
                money_market: false,
                finalizar: true,
                dia_hora_daruma: this.date.toLocaleString()
            }

            const options = {
            method: 'POST',
            data,
            headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
            url: `https://bkpj.allaria.com.ar/api/v1/empresa/`,
            };
            this.loadingShow = true
            this.axios(options)
            .then((result) => {
                this.result = result
                this.$router.push("/ultimos-pasos")
            })
            .catch((error) => {
              this.loadingShow = false
              this.error = error
            })
        })
        .catch((error) => {
          this.loadingShow = false
          this.error = error
        })
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
  font-family: 'Lato', sans-serif;
}
.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.modal-content{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}
.modalText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.archivo-button{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;

}

.botonOk{
  background-color: #5BABBE;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
}






.continuar{
  background-color: #89aacc;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 40px;
  margin-bottom:20px;
}

.sociedad-container{
  width: 300px;
  margin: 0 auto;
}

.sociedad{
  font-weight: bolder;
  font-size: 14px;
  text-align: left;
}

  .boton-volver{
    width: 300px;
    margin: 0 auto;
    display:flex;
    justify-content: left;
  }


  .volver{
    background-color: #FBFBFB;
    border: none;
    font-size: 14px;
    color: #0C5297;
  }

@media only screen and (max-width: 845px) {
  .modal-content{
    width: 90%;
  }
}

@media only screen and (max-width: 340px){

  .continuar{
    width: 215px;
  }
  .archivo-button{
    width: 215px;
  }
  .botonOk{
    width: 215px;
  }
  .sociedad-container{
    width: 215px;
  }
  .boton-volver{
    width: 215px;
  }

    .cerrar-modal{
    width: 200px;
  }

}
</style>