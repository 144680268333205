<template>
  <div class="navbar">
      <img src="../assets/allaria-transparent-background.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
.navbar{
    background-color: #fff;
    border-bottom: 1px solid #0C5297;
    padding: 20px;
    text-align: left;
}
img{
    width: 200px;
}
</style>