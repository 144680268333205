<template>

  <div class="paso3">

    <div class="comp-container">


      <h1>
        Paso 3
      </h1>

      <span class="titulo">
       {{ titulo }}<!-- Datos de los apoderados -->
      </span>

      <div class="dot-div"> 
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
      </div>







        <div v-show="primeraParte">
            <div class="dni-container">
              <input v-model="dni" :maxlength="max" class="dni" :placeholder="'Número de DNI'" >
            </div>

            <div class="nroTramite-container">
              <input v-model="nroTramite" :maxlength="11" class="nroTramite" :placeholder="'N° de Trámite DNI (11 dígitos)'" >
              <button><fa @click="cerrarModalTramite()" icon="question-circle" class="nroTramiteInfo" /></button>
            </div>
              
            <div class="renaper-container">
            
              <span class="renaper">
                Por su seguridad verificamos los datos personales en el Registro Nacional de las Personas
              </span>
              
            </div>


            <div class="femMasc-container">
              <span class="femMascSpan">
                Sexo tal como figura en el DNI:
              </span>

              <ToggleFemMasc v-if="primeraParte" v-model="femMasc" v-on:change.passive="toggleFemMasc" />
            </div>


            <div class="botones-container">
              <input ref="uploadFrente" id="frenteUpload" v-on:change="handleUploadFrente($event)" type="file" style="display:none;" accept="image/*,.pdf">
              <button :disabled="disabledFotos" v-if="sinFotoFrente" class="botonFotoFrente" @click="elegirFrente()">
                Subir foto del FRENTE de su DNI*
              </button>

              <button v-else class="botonOkFrente">
                <fa icon="check" class="fa-2x" />
              </button>




              <input ref="uploadDorso" id="dorsoUpload" v-on:change="handleUploadDorso($event)" type="file" style="display:none;" accept="image/*,.pdf">
              <button :disabled="disabledFotos" v-if="sinFotoDorso" class="botonFotoDorso" @click="elegirDorso()">
                Subir foto del DORSO de su DNI*
              </button>

              <button v-else class="botonOkFrente">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

            <button><p class="ejemplo" @click="cerrarModalDni()">*Ejemplo</p></button>

            <div v-if="omitirpaso4" class="boton-omitir">
                <button @click="paso4()" class="omitir">
                  <br>
                  Continuar sin agregar otro apoderado&nbsp;&nbsp;<fa icon="arrow-circle-right" />
                </button>
            </div>

            <div class="errorDni" v-if="errorDni">
              <!-- <span class="errorDniTest">Verifica que tu DNI, número de<br>trámite y sexo sean los correctos</span> -->
              <span class="errorDniTest">{{ errorDniTexto }}</span>
            </div>

            <div class="errorImg" v-if="errorImg">
              <span class="errorImgTest">Adjunte las fotos del FRENTE y DORSO del DNI</span>
            </div>

            <button class="continuar" @click="continuar()">
              Continuar
            </button>
        </div>

        <dataPersonal v-if="segundaParte" v-on:volver="volver()" v-on:triggerNuevoCuit="triggerNuevoCuit($event)" v-on:correcto="correcto()" v-on:cambiarDomicilio="cambiarDomicilio()" v-bind:nombre="nombre" v-bind:apellido="apellido" v-bind:fechaDeNacimiento="fechaDeNacimiento" v-bind:lugarNacimiento="lugarNacimiento" v-bind:nacionalidad="nacionalidad" v-bind:domicilio="domicilio" v-bind:newCuit="newCuit" />
        <dataCambioDeDomicilio v-if="segundaParteDom" v-on:triggerNuevoCuit="triggerNuevoCuit($event)" v-on:domicilioCorrecto="domicilioCorrecto($event)" v-on:cambiarDomicilio="cambiarDomicilio()" v-bind:nombre="nombre" v-bind:apellido="apellido" v-bind:fechaDeNacimiento="fechaDeNacimiento" v-bind:lugarNacimiento="lugarNacimiento" v-bind:nacionalidad="nacionalidad" v-bind:domicilio="domicilio" v-bind:newCuit="newCuit" />
        <dataNumero v-if="terceraParte" v-on:agregarApoderado="agregarApoderado($event)" v-on:setPersonalData="setPersonalData($event)"  v-bind:nombre="nombre" v-bind:apellido="apellido" />






    </div>



















<transition name="fade">
        <div class="modal-fade" v-if="modalEjemploTram">
            <div class="modal-content-tramite">

                <img class="imgEjemploTram" src="../assets/ejemplo-nrotramite.jpg" alt="">

                <button @click="cerrarModalTramite()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<transition name="fade">
        <div class="modal-fade" v-if="modalEjemploDni">
            <div class="modal-content-dni">
                <br>
                <h2>
                    Modelo de Foto DNI
                </h2>
                <br>
                <br>
                <div>
                  <p>Frente</p>
                  <img class="imgEjemploDni" src="../assets/dni-frente.jpg" alt="">
                </div>

                <div>
                  <p>Dorso</p>
                  <img class="imgEjemploDni" src="../assets/dni-dorso.jpg" alt="">
                </div>

                <button @click="cerrarModalDni()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>

<transition name="fade">
        <div class="modal-fade" v-if="imagenError">
            <div class="modal-content">
                <br>
                <br>
                <h4>{{ imagenErrorTexto }}</h4>
                <br>
                <br>
                <button @click="modalImagenError()" class="cerrar-modal">
                    Ok
                </button>
            </div>
        </div>
</transition>

    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loadingShow">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" src="../assets/loading.gif" alt="">
                </div>
            </div>
        </div> 
    </transition>

  </div>
</template>

<script>
import ToggleFemMasc from '../components/ToggleFemMasc.vue'
import dataPersonal from '../components/dataPersonal.vue'
import dataCambioDeDomicilio from '../components/dataCambioDeDomicilio.vue'
import dataNumero from '../components/dataNumero.vue'

export default {
  data() {
        return {
            primeraParte: true,
            segundaParte: false,
            segundaParteDom: false,
            terceraParte: false,
            titulo: "Datos de los apoderados",
            max: 8,
            dni: null,
            nroTramite: null,
            modalEjemploTram: false,
            modalFrente: false,
            modalEjemploDni: false,
            toggleValue: false,
            femMasc: "M",
            disabledFotos: false,
            fotoFrente: null,
            sinFotoFrente: true,
            sinFotoDorso: true,
            fotoDorso: null,
            erroresImg: true,
            errorDni: null,
            errorDniTexto: "Por favor, verifique que su DNI, número de trámite y sexo sean los correctos",
            errorImg: false,
            nombre: null,
            apellido: null,
            fechaDeNacimiento: null,
            lugarNacimiento: null,
            nacionalidad: null,
            domicilio: null,
            cuitValido: true,
            newCuit: false,
            newCalle: null,
            newNumero: null,
            newPiso: null,
            newDpto: null,
            newProvincia: null,
            newLocalidad: null,
            newCP: null,
            imagenError: false,
            errorDuplicado: false,
            imagenErrorTexto: "Por favor, suba un archivo de tipo imagen o PDF",
            nuevoCuit: null,
            dni_api: null,
            nro_tramite_api: null,
            sexo_api: null,
            foto_frente_api: null,
            foto_dorso_api: null,
            cuit_api: null,
            nombre_api: null,
            apellido_api: null,
            fecha_nacimiento_api: null,
            lugar_nacimiento_api: null,
            nacionalidad_api: null,
            calle_api: null,
            nro_api: null,
            piso_api: null,
            departamento_api: null,
            provincia_api: null,
            localidad_api: null,
            codigo_postal_api: null,
            codigo_area_api: null,
            celular_api: null,
            email_api: null,
            estado_civil_api: null,
            es_pep_api: null,
            motivoPep_api: '',
            loadingShow: false,
            apoderadosExistentes: [],
            omitirpaso4: false,
            timelineApoderados: [],
        }
    },
    components: {
      ToggleFemMasc,
      dataPersonal,
      dataCambioDeDomicilio,
      dataNumero
    },
    mounted() {             
      this.token = localStorage["token"]

      
    }, 
    methods: {
      modalImagenError(){
        if (this.imagenError == false){
          this.imagenError = true
        } else {
          this.imagenError = false
        }
      },
      toggleFemMasc() {
          if (this.toggleValue == false) {
            this.femMasc = "F"
            this.toggleValue = true
          } else {
              this.femMasc = "M"
              this.toggleValue = false
          }
          
      },
      cerrarModalTramite(){
          if (this.modalEjemploTram == true) {
              this.modalEjemploTram = false
          } else {
              this.modalEjemploTram = true
          }
      },
      cerrarModalDni(){
          if (this.modalEjemploDni == true) {
              this.modalEjemploDni = false
          } else {
              this.modalEjemploDni = true
          }
      },
      elegirFrente(){
        document.getElementById("frenteUpload").click()
      },
      elegirDorso(){
        document.getElementById("dorsoUpload").click()
      },
      checkDataFrente(event){
        if (event.target.files[0]["type"].startsWith("image") || event.target.files[0]["type"].startsWith("application/pdf")) {
          this.fotoFrente = true
          this.sinFotoFrente = false
        } else {
          this.imagenError = true
          this.fotoFrente = false
          this.sinFotoFrente = true
        }
      },
      checkDataDorso(event){
        if (event.target.files[0]["type"].startsWith("image") || event.target.files[0]["type"].startsWith("application/pdf")) {
          this.fotoDorso = true
          this.sinFotoDorso = false
        } else {
          this.imagenError = true
          this.fotoDorso = false
          this.sinFotoDorso = true
        }
      },
      handleUploadFrente(files) {
        // VALIDACION DE SI FOTO PESA MAS DE 1MG
        // var fotos = files.target.files
        // var foto = fotos[0]
        // if (foto.size > 1024 * 1024) {
        //   this.imagenErrorTexto = "Error la imagen supera 1 megabyte"
        //   this.modalImagenError()
        // } else {
        //   this.imagenErrorTexto = "Por favor, suba un archivo de tipo imagen"
        //   this.foto_frente_api = fotos[0];
      
        //   this.checkDataFrente(files)
        // }

        // SIN VALIDACION DE SI FOTO PESA MAS DE 1MG
        let fotos = files.target.files
        this.foto_frente_api = fotos[0];
        this.checkDataFrente(files)
      },
      handleUploadDorso(files) {
        // VALIDACION DE SI FOTO PESA MAS DE 1MG
        // var fotos = files.target.files
        // var foto = fotos[0]
        // if (foto.size > 1024 * 1024) {
        //   this.imagenErrorTexto = "Error la imagen supera 1 megabyte"
        //   this.modalImagenError()
        // } else {
        //   this.imagenErrorTexto = "Por favor, suba un archivo de tipo imagen"
        //   this.foto_dorso_api = fotos[0];
      
        //   this.checkDataDorso(files)
        // }

        // SIN VALIDACION DE SI FOTO PESA MAS DE 1MG
        let fotos = files.target.files
        this.foto_dorso_api = fotos[0];
        this.checkDataDorso(files)
      },
      domicilioCorrecto(e){
        this.calle_api = e.calle
        this.nro_api = e.numero
        this.piso_api = e.piso
        this.departamento_api = e.dpto
        this.provincia_api = e.provincia
        this.localidad_api = e.localidad
        this.codigo_postal_api = e.cp

        this.segundaParte = false
        this.segundaParteDom = false

        this.terceraParte = true
      },
      volver(){
        this.segundaParte = false
                this.segundaParteDom = false
                this.terceraParte = false

                this.$refs.uploadDorso.value = null;
                this.$refs.uploadFrente.value = null;
                
                this.primeraParte = true
                this.dni = null
                this.nroTramite = null
                this.modalEjemploTram = false
                this.modalFrente = false
                this.modalEjemploDni = false
                this.toggleValue = false
                this.femMasc = "M"
                this.disabledFotos = false
                this.fotoFrente = null
                this.sinFotoFrente = true
                this.sinFotoDorso = true
                this.fotoDorso = null
                this.erroresImg = true
                this.errorDni = null
                this.errorImg = false
                this.nombre = null
                this.apellido = null
                this.fechaDeNacimiento = null
                this.lugarNacimiento = null
                this.nacionalidad = null
                this.domicilio = null
                this.cuitValido = true
                this.newCuit = false
                this.newCalle = null
                this.newNumero = null
                this.newPiso = null
                this.newDpto = null
                this.newProvincia = null
                this.newLocalidad = null
                this.newCP = null
                this.loadingShow = null
      },
      continuar(){ 

        if (this.dni != null && this.dni.length == 8 && !isNaN(this.dni)) {
          this.errorDni = null
        } else {
          this.errorDni = true
          this.errorDniTexto = "Por favor, verifique que su DNI, número de trámite y sexo sean los correctos"
        }

        if (this.nroTramite != null && this.nroTramite.length  == 11 && !isNaN(this.nroTramite)) {
          this.errorDni = null
        } else {
          this.errorDni = true
          this.errorDniTexto = "Por favor, verifique que su DNI, número de trámite y sexo sean los correctos"
        }

        let esta = this.apoderadosExistentes.some(apoderadoDni => apoderadoDni === this.dni)


        if (esta == true) {
          this.errorDni = true
          this.errorDniTexto = "El DNI del apoderado ingresado ya se encuentra asociado a esta apertura"
          this.errorDuplicado = true
        } else {
          this.errorDuplicado = false
        }

        if (this.sinFotoFrente == false && this.sinFotoDorso == false){
          this.errorImg = null
        } else {
          this.errorImg = true
        }

        if (this.errorImg == null && this.errorDni == null){

          let data = {
            number: this.dni,
            order: this.nroTramite,
            gender: this.femMasc
          }
          const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
            data,
            url: `https://bkpj.allaria.com.ar/api/v1/renaper/`,
          };
          this.loadingShow = true
          this.axios(options)
            .then((result) => {
              if(result.data.valid == "No vigente"){
                this.errorDniTexto = "De acuerdo a la validación realizada con el Registro Nacional de las Personas, su DNI no se encuentra vigente. Por favor comníquese con empresas@allaria.com.ar"
              }
              let person = JSON.parse(result.data.person)


              this.primeraParte = false
              this.segundaParte = true
              this.titulo = "Confirmación de los Datos de los Apoderados"

              this.nombre = person.names
              this.apellido = person.lastNames
              this.fechaDeNacimiento = person.birthdate
              this.lugarNacimiento = person.countryBirth
              this.nacionalidad = person.nationality
              this.domicilio = person.streetAddress + " " + person.numberStreet + ", " + person.province


              this.dni_api = person.number
              this.nro_tramite_api = this.nroTramite
              this.sexo_api = person.gender

              this.cuit_api = person.cuil

              this.nombre_api = person.names
              this.apellido_api = person.lastNames
              this.fecha_nacimiento_api = person.birthdate
              this.lugar_nacimiento_api = person.countryBirth
              this.nacionalidad_api = person.nationality
              this.calle_api = person.streetAddress
              this.nro_api = person.numberStreet
              this.piso_api = person.floor
              if (person.department == ""){
                this.departamento_api = null  
              } else {
                this.departamento_api = person.department
              }
              this.provincia_api = person.province
              this.localidad_api = person.municipality
              this.codigo_postal_api = person.zipCode

              if (person.cuil == "" || person.cuil == null){
                this.cuitValido = false
              } else {
                this.cuitValido = true
              }

              if (this.cuitValido == false){
                this.newCuit = true
              } else {
                this.newCuit = false
              }

              
              this.errorDni = false

              this.loadingShow = false
            })
            .catch(() => {
              this.errorDni = true
              this.loadingShow = false
            })




          this.cuitValido = true

          if (this.cuitValido == false){
            this.newCuit = true
          } else {
            this.newCuit = false
          }

        }
      },
      paso4(){
        this.$router.push("/Paso4")
      },
      numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,".");
        return parts.join(",");
      },
      setPersonalData(e){
        this.codigo_area_api = e.codArea
        this.celular_api = e.celular
        this.email_api = e.email
        this.estado_civil_api = e.estado_civil
        this.es_pep_api = e.esPep
        this.motivoPep_api = e.motivoPep

        let data = new FormData()
        data.append('dni', this.dni_api)
        data.append('nro_tramite', this.nro_tramite_api)
        data.append('sexo', this.sexo_api)
        data.append('foto_frente', this.foto_frente_api)
        data.append('foto_dorso', this.foto_dorso_api)
        data.append('cuit', this.cuit_api)
        data.append('nombre', this.nombre_api)
        data.append('apellido', this.apellido_api)
        data.append('fecha_nacimiento', this.fecha_nacimiento_api)
        data.append('lugar_nacimiento', this.lugar_nacimiento_api)
        data.append('nacionalidad', this.nacionalidad_api)
        data.append('calle', this.calle_api)
        data.append('nro', this.nro_api)
        data.append('piso', this.piso_api)
        data.append('departamento', this.departamento_api)
        data.append('provincia', this.provincia_api)
        data.append('localidad', this.localidad_api)
        data.append('codigo_postal', this.codigo_postal_api)
        data.append('codigo_area', this.codigo_area_api)
        data.append('celular', this.celular_api)
        data.append('email', this.email_api)
        data.append('estado_civil', this.estado_civil_api)
        data.append('es_pep', this.es_pep_api)
        data.append('motivo_pep', this.motivoPep_api)


        const options = {
          method: 'POST',
          data,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${this.token}` },
          url: 'https://bkpj.allaria.com.ar/api/v1/apoderados/',
        };

        this.loadingShow = true
        this.axios(options)
          .then(() => {

            let data = new FormData()
            data.append('dni', this.dni_api)
            data.append('nombre', this.nombre_api + " " + this.apellido_api)
            data.append('persona', "INDIVIDUAL")
            
            const worldcheckData = {
              method: 'POST',
              data,
              headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Token ${this.token}` },
              url: `https://bkpj.allaria.com.ar/api/v1/worldcheck/`,
            };
            this.axios(worldcheckData)
              .then((result) => {
                result
                this.loadingShow = true
                this.$router.push("Paso4")
              })
              .catch((error) => {
                this.error = error
                this.loadingShow = false
              })
              })
              .catch((error) => {
                this.loadingShow = false
                this.error = error
              })
      },
      cambiarDomicilio(){
        if (this.segundaParte == true){
          this.segundaParte = false
          this.segundaParteDom = true
        } else {
          this.segundaParte = true
          this.segundaParteDom = false
        }

      },
      triggerNuevoCuit(e){
        this.nuevoCuit = e
        this.cuit_api = e
      },
      correcto(){
        this.segundaParte = false
        this.segundaParteDom = false
        this.terceraParte = true
      },
      agregarApoderado(e){

            this.codigo_area_api = e.codArea
            this.celular_api = e.celular
            this.email_api = e.email
            this.estado_civil_api = e.estado_civil
            this.es_pep_api = e.esPep
            this.motivoPep_api = e.motivoPep


            this.apoderadosExistentes.push(this.dni_api)

            let data = new FormData()
            data.append('dni', this.dni_api)
            data.append('nro_tramite', this.nro_tramite_api)
            data.append('sexo', this.sexo_api)
            data.append('foto_frente', this.foto_frente_api)
            data.append('foto_dorso', this.foto_dorso_api)
            data.append('cuit', this.cuit_api)
            data.append('nombre', this.nombre_api)
            data.append('apellido', this.apellido_api)
            data.append('fecha_nacimiento', this.fecha_nacimiento_api)
            data.append('lugar_nacimiento', this.lugar_nacimiento_api)
            data.append('nacionalidad', this.nacionalidad_api)
            data.append('calle', this.calle_api)
            data.append('nro', this.nro_api)
            data.append('piso', this.piso_api)
            data.append('departamento', this.departamento_api)
            data.append('provincia', this.provincia_api)
            data.append('localidad', this.localidad_api)
            data.append('codigo_postal', this.codigo_postal_api)
            data.append('codigo_area', this.codigo_area_api)
            data.append('celular', this.celular_api)
            data.append('email', this.email_api)
            data.append('estado_civil', this.estado_civil_api)
            data.append('es_pep', this.es_pep_api)
            data.append('motivo_pep', this.motivoPep_api)

            const options = {
              method: 'POST',
              data,
              // headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
              headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${this.token}` },
              url: 'https://bkpj.allaria.com.ar/api/v1/apoderados/',
            };
            
            
            // for (var pair of data.entries()) {
          
            // }


            this.loadingShow = true
            this.axios(options)
              .then(() => {

            let data = new FormData()
            data.append('dni', this.dni_api)
            data.append('nombre', this.nombre_api + " " + this.apellido_api)
            data.append('persona', "INDIVIDUAL")
            
            const worldcheckData = {
              method: 'POST',
              data,
              headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Token ${this.token}` },
              url: `https://bkpj.allaria.com.ar/api/v1/worldcheck/`,
            };

            this.axios(worldcheckData)
              .then((result) => {
                this.omitirpaso4 = true
                this.segundaParte = false
                this.segundaParteDom = false
                this.terceraParte = false

                this.$refs.uploadDorso.value = null;
                this.$refs.uploadFrente.value = null;
                
                this.primeraParte = true
                this.dni = null
                this.nroTramite = null
                this.modalEjemploTram = false
                this.modalFrente = false
                this.modalEjemploDni = false
                this.toggleValue = false
                this.femMasc = "M"
                this.disabledFotos = false
                this.fotoFrente = null
                this.sinFotoFrente = true
                this.sinFotoDorso = true
                this.fotoDorso = null
                this.erroresImg = true
                this.errorDni = null
                this.errorImg = false
                this.nombre = null
                this.apellido = null
                this.fechaDeNacimiento = null
                this.lugarNacimiento = null
                this.nacionalidad = null
                this.domicilio = null
                this.cuitValido = true
                this.newCuit = false
                this.newCalle = null
                this.newNumero = null
                this.newPiso = null
                this.newDpto = null
                this.newProvincia = null
                this.newLocalidad = null
                this.newCP = null
                this.loadingShow = null

                result
              })
              .catch((error) => {
                this.error = error
                this.loadingShow = false
              })
              })
              .catch((error) => {
                this.loadingShow = false
                this.error = error
              })
      },
    },
    watch: {

    }
}







</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }

  /* take away input number arrows Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }



  .fade-enter-from{
      opacity: 0;
  }
  .fade-enter-to{
      opacity: 1;
  }
  .fade-enter-active{
      transition: all 1s ease;
  }
  .fade-leave-from{
      opacity: 1;
  }
  .fade-leave-to{
      opacity: 0;
  }
  .fade-leave-active{
      transition: all 1s ease;
  }

  .fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}



  .comp-container{
    padding-top: 20px;
    min-height: 700px;
    width: 30%;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
  }

  .dot-div{
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dot{
    color: #0C5297;
    opacity: 0.4;
    width: 10px;
  }

  .this-dot{
    color: #0C5297;
    width: 10px;
  }

  .dni-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  

.dni{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
}


.nroTramite-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


.nroTramite{
    width: 270px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
}

.nroTramiteInfo{
    color: rgba(12, 82, 151, 1);
    width:30px;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.modal-content{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.modal-content-tramite{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}


.modal-content-dni{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.imgEjemploDni{
  width: 40%;
}


.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.imgEjemploTram{
    margin-top: 20px;
    width:90%;
}

.renaper-container{
    width: 300px;
    margin: 0 auto;
    margin-top: 20px;
}

.renaper{
  color: #999999;
  font-size: 12px;
}


.femMasc-container{
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.femMascSpan{
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.botones-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.botonFotoFrente{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.botonOkFrente{
  background-color: #5BABBE;;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.botonFotoDorso{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.botonOkDorso{
  background-color: #5BABBE;;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.ejemplo{
    font-weight: bold;
    font-size: 13px;
    /* text-decoration-line: underline; */
    color: #0C5297;
    width: 300px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
    padding-bottom: 5px;
}

.ejemplo:hover{
    text-decoration: underline;
}

.boton-omitir{
  width: 300px;
  margin: 0 auto;
  display:flex;
  justify-content: left;
}

.omitir{
  background-color: #FBFBFB;
  border: none;
  font-size: 14px;
  color: #0C5297;
  font-weight: bold;
  padding-left: 0px;
}

.omitir:hover{
    text-decoration: underline;
}

.errorDni{
  width: 300px;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 20px;
  border-radius: 5px;
  background-color: rgba(12, 82, 151, 0.1);
  overflow: visible;
  padding-top:15px;
  padding-bottom:15px;
}

.errorDniTest{
  width: 100px;
  color: #e63d3d;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  white-space: pre-wrap;
}

.errorImg{
  width: 300px;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 20px;
  border-radius: 5px;
  background-color: rgba(12, 82, 151, 0.1);
  overflow: visible;
  padding-top:15px;
  padding-bottom:15px;
}

.errorImgTest{
  width: 100px;
  color: #e63d3d;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.continuar{
  background-color: rgba(0, 51, 102, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:10px;
}

.segundaParte{
  margin-top: 20px;
}




@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }

  .modal-content{
    width: 90%;
  }

  .modal-content-tramite{
    width: 90%;
  }

  .modal-content-dni{
    width: 90%;
  }

}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){

    .dni-container{
      width: 215px;
      margin: 0 auto;
      margin-top: 20px;
    }

    .dni{
      width: 215px;
    }

    .nroTramite-container{
      width: 215px;
      margin:0 auto;
      margin-top: 20px;
      
    }

    .renaper-container{
      width: 215px;
    }

    .femMasc-container{
      width: 215px;
    }

    .femMascSpan{
      width: 90px;
    }

    .botonFotoFrente{
      width: 215px;
    }

    .botonFotoDorso{
      width: 215px;
    }

    .botonOkFrente{
      width: 215px;
    }

    .botonOkDorso{
      width: 215px;
    }

    .ejemplo{
      width: 215px;
    }

    .continuar{
      width: 215px;
    }

    .cerrar-modal{
      width: 200px;
    }
    
    .nombreApellido{
      width: 215px;
    }

    .correcto{
      width: 215px;
    }
    .errorDni{
      width: 215px;
    }

    .errorDniTest{
      width: 215px;
    }

    .errorImg{
      width: 215px;
      margin: 0 auto;
      align-items: flex-start;
      margin-top: 20px;
      border-radius: 5px;
      background-color: rgba(12, 82, 151, 0.1);
      overflow: visible;
      padding-top:15px;
      padding-bottom:15px;
    }

    .errorImgTest{
      width: 215px;
    }
    .boton-omitir{
        width: 215px;
        display:flex;
    }
    .omitir{
        width: 215px;
        text-align: left;
    }
    .comp-container{
        overflow-x: hidden;
    }


}


</style>
