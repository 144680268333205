<template>
  <div class="paso6">

    <div class="comp-container">

        <div class="header">
            <div class="icon-container" style="width: 80px;">
                <fa icon="check" class="checkCircle"/>
            </div>
            <div >
                <!-- <p class="listo">¡Listo!</p> -->
                <p class="subtitulo">Ha finalizado la carga de datos, procesaremos la información y nos contactaremos con Usted a la brevedad.</p>
            </div>
        </div>


        <div class="content-container">
            <p class="aclaracionDaruma">Con un click más podrá abrir su cuenta en Daruma.  Una plataforma integrada con Allaria para optimizar la administración de su capital de trabajo.</p>

            <img class="allaria-img" src="../assets/iconoallaria.jpg" alt="">
            <img class="daruma-img" src="../assets/daruma_principal-nobg.png" alt="">

            <ul>
                <li>Reciba y realice transferencias desde y hacia cualquier cuenta bancaria o virtual.</li>
                <li>Los saldos de su cuenta Daruma se invierten automáticamente en el FCI Allaria Ahorro. ¡SIEMPRE están disponibles para transferir!</li>
                <li>Sin costos de apertura, mantenimiento, transferencias e inverisón automática.</li>
            </ul>
        </div>

        <button @click="showModalTerminos()" class="abrir-cuenta">
            Quiero abrir una cuenta en Daruma
        </button>

        <button @click="conocer()" class="conocer">
            Quiero conocer más sobre Daruma
        </button>

        <button @click="otroMomento()" class="otro-momento">
            Mejor en otro momento
        </button>

     
    </div>






<transition name="fade">
        <div class="modal-fade" v-if="showTerminos">
            <div class="modal-content">
                <br>
                <p style="text-align: right; margin-right: 10px;"><fa @click="showModalTerminos()" icon="times" /></p>

                <p class="modal-titulo">
                  Ud. está por abrir una cuenta en Daruma.
                </p>
                <br>

                <p class="modal-subtitulo">
                  Utilizaremos los mismos datos que cargó para abrir su cuenta en Allaria.
                </p>
                <br>

                <div class="radio-container">
                    <input @change="checkTerminosDaruma($event)" type="checkbox" name="terminos" value="terminos" ref="aceptaTerminosDaruma">
                    <label class="span-terminos" for="terminos">Acepte los <a class="modal-activator" @click="modalTerminosText()">"Términos y Condiciones"</a> y confirme la apertura.</label>
                </div>
                

                <button v-on:click="listoDaruma()" ref="confirmarDarumaButton" :disabled="confirmarDisabled" class="cerrar-modal">
                    Confirmar
                </button>
            </div>
        </div>
</transition>


<transition name="fade">
        <div class="modal-fade" v-if="showTerminosCondiciones">
            <div class="modal-content">
                <br>
                <br>
                <p class="terminos-modal-titulo">
                  Términos y Condiciones
                </p>
                <br>
                <br>

                <pre class="terminos-modal-subtitulo">
Términos y condiciones de uso

Estas condiciones de uso, constituyen un contrato entre el Cliente y Daruma Pagos S.A. CUIT: 30-
71615568-0 (en adelante, “Daruma”), quedando el Cliente sujeto a estos términos y condiciones (en
adelante, conjuntamente Daruma y el Cliente, las “Partes”).
Se entiende por “Cliente” a toda (i) persona humana, y (ii) persona jurídica o estructura legal que suscribe
los presentes términos y condiciones. En el caso de las personas jurídicas la suscripción podrá ser por
intermedio de su representante legal y/o apoderado/s con facultades suficientes, pudiendo el término
Cliente referirse, indistintamente a la persona jurídica o a sus representantes.
En estos Términos y Condiciones se describen los derechos, responsabilidades y obligaciones del Cliente
y Daruma al utilizar el servicio de gestión de pagos y/o cobranzas, la Plataforma (conforme es definida
en estos Términos y Condiciones) y cualquier producto o servicio relacionado que ofrezca Daruma y/o
prestadores de servicios con quien contrate y/o aliados comerciales del mismo (cualquiera de ellos,
indistintamente, el “Servicio” y, conjuntamente, los “Servicios”, según corresponda).
El Cliente declara haber leído, entender y aceptar todas las condiciones establecidas en estos Términos
y Condiciones y demás políticas y principios incorporados a las mismas por referencia, previo a su registro
como Cliente de Daruma y/o la utilización de un Servicio.
Cualquier persona que no acepte estos Términos y Condiciones generales, los cuales tienen carácter
obligatorio y vinculante, no podrá acceder a los Servicios ofrecidos.
El Cliente accede a estos Términos y Condiciones para:
a) Hacer transferencias de sus cuentas bancarias (CBU) o cuentas virtuales CVU) a la Cuenta abierta
en Daruma,
b) Recibir transferencias de terceros de cuentas bancarias (CBU) o cuentas virtuales (CVU) en su
Cuenta en Daruma,
c) Hacer transferencias de saldo de su Cuenta a cuentas bancarias (CBU) o cuentas virtuales (CVU)
de Clientes, propias o a quien instruya.
d) Transferir los saldos depositados en su Cuenta para la acreditación en una cuenta comitente de su
titularidad y ser aplicados a la realización de operaciones con fondos comunes de inversión.
e) Recibir cheques en su Cuenta en Daruma.

1- Registro/Alta de cuenta

a) Registro/Alta. Todo Cliente que desee utilizar el Servicio debe registrarse en Daruma y aceptar estos
Términos y Condiciones generales.
Asimismo, para registrarse es obligatorio que el Cliente autorice de forma expresa a Allaria Ledesma &
Cía., que comparta toda información y documentación aportada por él mismo, contenida en su legajo
relativa a su identificación y en virtud del conocimiento del cliente. La documentación compartida se
considerará válida y la información exacta, precisa y verdadera.
El Cliente asume el compromiso de actualizar la información solicitada cuando la misma fuera modificada.
Daruma no se responsabiliza por la certeza de la información y/o documentación provista por Allaria
Ledesma & Cía. y por el Cliente. El Cliente garantiza y responde, en cualquier caso, por la veracidad,
exactitud, integridad, vigencia y autenticidad de la información y/o documentación proporcionada.
Toda la información y datos ingresados por el Cliente tienen carácter de declaración jurada.
Asimismo, el Cliente declara bajo juramento, en cumplimiento de las normas vigentes en materia de
Prevención de Lavado de Activos y Financiación del Terrorismo, que desarrolla una actividad lícita y
genuina que es la declarada en la apertura de la cuenta.
Daruma se reserva el derecho de solicitar comprobantes, documentación y/o información adicional a
efectos de corroborar o actualizar la información entregada por un Cliente la cual deberá ser
proporcionada en las condiciones establecidas por Daruma. De no poder comprobarse la información,
Daruma podrá suspender temporal o definitivamente a aquellos Clientes cuyos datos no hayan podido ser
confirmados y/o faltara información o documentación.
Por otra parte, Daruma se reserva el derecho de rechazar una solicitud de registro o de cancelar o
suspender, temporal o definitivamente una Cuenta (conforme es definida a continuación), en caso de
detectar incongruencias o inconsistencias en la información provista por un Cliente o en caso de contar
con documentación insuficiente, sin que tal decisión genere para el Cliente derechos de indemnización o
resarcimiento alguno.
Confidencialidad de la información: La información de los Clientes no será otorgada a terceras personas,
sin consentimiento libre y expreso del Cliente de conformidad con lo dispuesto en la Ley Nº 25.326 y sus
modificatorias. Sin perjuicio de ello, el Cliente suscribiendo estos Términos y Condiciones, hace expresa
su autorización para que Daruma comparta su información y documentación relativa a su identificación,
y destinada al inicio de la relación comercial o a la apertura de su cuenta, con prestadores de servicios
y/o sus aliados comerciales, en el marco de acuerdos de servicios suscriptos.
Asimismo, Daruma podrá compartir con BIND y Compensadora Electrónica S.A. o aquella otra que la
reemplace en el futuro, la Clave Bancaria Uniforme (en adelante, “CBU y la Clave Virtual Uniforme (en
adelante, “CVU”) de las cuentas bancarias virtuales y a la vista a nombre del Cliente y demás información
y datos personales relacionados que tenga en su poder.
Daruma mantendrá la confidencialidad e inalterabilidad de la información obtenida en el proceso de
identificación, pero no se hace responsable si terceros la obtienen por sus propios medios y/o de forma
ilegal.
b) Cuenta. Al registrarse en Daruma, el Cliente abrirá una cuenta (en adelante, la “Cuenta”), la cual
tendrá asociados sólo aquellos instrumentos de pago o cuentas cuyo titular (o alguno de los cotitulares)
coincida con el titular de la Cuenta. La Cuenta permitirá al Cliente contratar un Servicio de Gestión de
Pago, incluyendo pero no limitando, la gestión de recepción y envió de fondos de cuenta propia o de
cuenta de terceros, y solicitar dinero a terceros, mediante el débito o acreditación de dinero en la
Cuenta, el que estará depositado en cuentas a la vista y encontrándose disponible en todo momento,
como también permitirá utilizar o aplicar cualquier otra operatividad que en un futuro se ofrezca al
Cliente, sujeto a los términos y condiciones que se establezcan para cada funcionalidad.
Al efecto, el Cliente creará su Cuenta a través de la plataforma de Daruma (en adelante, la “Plataforma”
o el “Sitio”), la que cuenta con fuertes mecanismos de identificación y autenticación de datos. El Cliente
ingresa su dirección de e-mail y elegirá su clave de seguridad personal e intransferible (en adelante,
“Clave de Ingreso”) la que deberá ser utilizada por el Cliente para operar. Asimismo, el Cliente se obliga
a mantener la confidencialidad de su Clave de Ingreso. En virtud de ello, el Cliente será el único y
exclusivo responsable por todas las operaciones y movimiento de fondos efectuados en su Cuenta, así
como también sobre toda información y documentación enviada desde su dirección de e-mail registrado.
El Cliente se compromete a notificar a Daruma en forma inmediata y por medio idóneo y fehaciente, de
cualquier uso no autorizado de su Cuenta, así como del ingreso o del intento de ingreso por terceros no
autorizados a la misma.

2- Gestión de pagos.

a) Gestión de Pagos. El Cliente solicita a Daruma el servicio de gestión de pago con sólo registrarse y
crear la Cuenta. En este sentido, a través de una instrucción de gestión de pago del Cliente, Daruma
brindará los Servicios de acuerdo a la instrucción recibida, utilizando la Plataforma, la cual opera
utilizando el servicio de API BANK/CVU provisto por BIND.
El Cliente podrá: (i) recibir sumas de dinero mediante transferencia directa a su Cuenta sin necesidad de
emisión de instrucción de gestión de pago alguna; (ii) recibir sumas de dinero mediante transferencia a
la cuenta bancaria a la vista en pesos de Daruma –CBU– y/o mediante cheque, para lo cual será necesaria
la emisión de una instrucción de gestión de pago; y (iii) enviar sumas de dinero para lo cual será necesaria
la emisión de una instrucción de gestión de pago que se emite automáticamente al momento de generar
la transferencia desde la Plataforma.
Las Partes acuerdan que las instrucciones de gestión de pago se regirán por estos Términos y Condiciones.
b) Mandato Irrevocable. Al solicitar el Servicio de gestión de pago, el Cliente otorgará un mandato
irrevocable a Daruma para: (i) pagar por su cuenta y orden una determinada suma de dinero (en adelante,
los “Fondos”) mediante transferencia, cheque o cualquier otro medio de pago admitido por el Banco
Central de la República Argentina y habilitado por la Administración Federal de Ingresos Públicos, en
relación a la instrucción de gestión de pago (incluyendo sin limitar, facturas de servicios públicos,
impuestos y otros servicios); y/o (ii) cobrar los Fondos por su cuenta y orden, sujeto a los Términos y
Condiciones del Servicio de gestión de pago y/o (iii) transferir el saldo disponible, para ser aplicado a la
suscripción y rescate de fondos comunes de inversión . El mandato irrevocable otorgado por el Cliente
implica una autorización a favor de Daruma para disponer en su nombre de los Fondos de su Cuenta y
transferirlos a ciertos destinatarios mediante su acreditación en una Cuenta de Daruma de acuerdo con
las instrucciones y/o autorizaciones previas del Cliente.
El mandato irrevocable implica una autorización del Cliente a favor de Daruma para pagar (de forma
inmediata o diferida), cobrar y acreditar en su Cuenta los Fondos, de acuerdo con sus instrucciones.
Daruma mantendrá los Fondos del Cliente en su Cuenta en las condiciones y plazos establecidos en estos
Términos y Condiciones y/u otras condiciones especiales o particulares que se establezcan para otros
productos o Servicios de Daruma, en todo de acuerdo a lo establecido en la Comunicación “A” 6859 y
6885 del Banco Central de la República Argentina.
Asimismo, el Cliente otorga mandato irrevocable a Daruma para debitar de su Cuenta y transferir en su
nombre, su saldo disponible a su cuenta comitente abierta en el Agente de Colocación y Distribución de
Fondos Comunes de Inversión, para su aplicación en uno o más fondos comunes de dinero autorizados por
la Comisión Nacional de Valores (en adelante, la “CNV”), de conformidad con lo establecido en la
Comunicación “A” 6859 y 6885 del Banco Central de la República Argentina.
Este mandato comprende una instrucción precisa de parte del Cliente para suscribir y rescatar fondos
comunes de inversión, siempre que existan saldos disponibles en la Cuenta y de acuerdo con las políticas
y principios de inversión incorporados a estos Términos y Condiciones por referencia. 
El Cliente declara conocer y aceptar que, al consentir los Términos y Condiciones, estará instruyendo a
Daruma Pagos, invertir su saldo disponible en la Cuenta, en cuotapartes de Fondos Comunes de Inversión
[T+0] (en adelante, los “FCI”).
Los FCI son comercializados por Allaria Ledesma & Cía. (en adelante, “Allaria”) en su carácter de Agente
de Colocación y Distribución de Fondos Comunes de Inversión; son administrados por Allaria Ledesma
Fondos Administrados Sociedad Gerente de Fondos Comunes de Inversión S.A. (en adelante, “Allaria
Fondos”) en su carácter de Agente de Administración de Productos de Inversión Colectiva y la custodia
de los activos que integran el FCI está a cargo de la sociedad depositaria Banco Comafi S.A. y/o Banco de
Valores S.A. en su carácter de Agente de Custodia de Productos de Inversión Colectiva de Fondos Comunes
de Inversión, todos agentes debidamente autorizados y registrados en CNV.
Por tal motivo, que el Cliente tenga abierta una cuenta comitente en Allaria, le permitirá utilizar los
saldos en Cuenta para realizar suscripciones y rescates en los FCI, según los requisitos que Allaria exija
para tal fin. Para ello, declara conocer y aceptar el texto vigente del reglamento de gestión del FCI (en
adelante, el “Reglamento”) consultado, leído y comprendido íntegramente a través de los siguientes
links; www.allariafondos.com.ar y/o en www.cnv.gob.ar y/o en www.fondosargentina.org.ar
Asimismo, declara conocer que el uso de la Plataforma importará prueba suficiente de la lectura y
aceptación del Reglamento del FCI, obrando como recibo de entrega del Reglamento.
Dado que este Reglamento puede ser modificado (previa autorización de la CNV y publicidad
correspondiente), en cuyo caso las nuevas versiones regirán la operatoria del FCI, tanto el texto vigente
del Reglamento como la información adicional sobre el FCI, podrán ser consultados en forma gratuita y
en todo momento a través de www.allariafondos.com.ar y/o en www.cnv.gob.ar y/o en
www.fondosargentina.org.ar.
Por último, y respecto a las modalidades de colocación de los fondos comunes de inversión, Allaria y
Daruma acuerdan que la instrucción de solicitudes que haga Daruma por cuenta y orden del Cliente, se
hará a través de internet, cumpliendo con los requisitos y exigencias de aprobación de la CNV.
c) Instrucciones para la Gestión de pago. La instrucción para la gestión de pago (en adelante la
“Instrucción de Gestión de pago”) se ejecutará a través del envío de un formulario on-line que completará
el Cliente, instruyendo a Daruma respecto de los fondos disponibles en su Cuenta. Sin perjuicio de ello,
Daruma se reserva el derecho de no procesar aquellas solicitudes de gestión de pago incompletas; o en
las cuales haya discrepancias entre los datos provistos por el Cliente y los efectivamente ingresados a
Daruma, pudiendo al efecto solicitar documentación respaldatoria de la causa u obligación que originó
tal instrucción de pago; o simplemente porque Daruma lo considere necesario, sin necesidad, en ningún
caso, de justificar la decisión.
La Instrucción de Gestión de pago, sólo podrá efectuarse, en principio, a través de la Plataforma. Sin
embargo, a modo de plan de contingencia, cualquier instrucción cursada por fuera de la Plataforma podrá
ser procesada por Daruma a su total y completa discreción, siempre que se haya enviado desde la
dirección de e-mail registrada por el Cliente y demás requisitos que Daruma considere pertinentes.
El Cliente es el único y exclusivo responsable por la Instrucción de Gestión de pago impartida y sus
consecuencias. Por tanto, se obliga irrevocablemente a indemnizar y mantener indemne a Daruma,
Allaria, Allaria Fondos, demás prestadores de servicios y/o aliados comerciales presentes y futuros (y a
cualquiera de sus respectivos directores, funcionarios, accionistas, asesores y empleados), en forma
inmediata y completa, contra cualquier reclamo, daño, obligación, lucro cesante o gasto que sufran, total
o parcialmente, como consecuencia de cualquier acción y/u omisión en relación con las operaciones
instruidas, incluyendo, pero sin limitar, multas, costos, gastos, intereses, honorarios y demás
desembolsos derivados de posibles incumplimientos del Cliente.
d) Perfeccionamiento de la Instrucción de Gestión de pago. La Instrucción de Gestión de pago no se
considerará perfeccionada y Daruma no asumirá responsabilidad u obligación alguna hasta tanto: (i)
Daruma no haya aceptado la Instrucción de Gestión de pago del Cliente (y tal como se mencionara),
pudiendo negarse a cumplir con la Instrucción de Gestión de pago sin justificativo, y sin que ello implique
responsabilidad alguna); y (ii) Daruma no haya recibido los fondos y/o no se encuentren disponibles la
totalidad de los mismos.
La ejecución de la Instrucción de Gestión de pago se entenderá como un acto de aceptación de dicha
instrucción por parte de Daruma.
e) Responsabilidad de Daruma por las Instrucciones de Gestión de pago. Daruma utilizará técnicas o
métodos tecnológicos rigurosos, almacenables (con constancia de fecha y hora) y no manipulables.
Por tanto, no será responsable por los datos instruidos por el Cliente, considerándolos ciertos y exactos,
sin perjuicio de verificarlos cuando Daruma lo considere necesario. Tampoco responderá por pagos
equivocados que tengan su causa u origen en instrucciones erróneas o incompletas por parte del Cliente.
Incluso el Cliente asume, reconoce y acepta su total responsabilidad por las pérdidas originadas, si a
través de su usuario se impartieran instrucciones falsas, duplicadas, no autorizadas o meramente
erróneas, inclusive responderá por los eventuales perjuicios que esto pueda causar a Daruma.
En línea con lo anterior, el Cliente declara conocer y aceptar los riesgos de que terceros no autorizados
accedan a la información correspondiente a su Cuenta, por lo cual exonera a Daruma de cualquier tipo
de responsabilidad, reconociendo en tal sentido que Daruma ha realizado sus mejores esfuerzos técnicos
a fin de garantizar la seguridad y confidencialidad de los datos.
f) Responsabilidad de Daruma por las Instrucciones de Gestión de pago frente a terceros. Daruma no
será responsable ni garantizará el cumplimiento de las obligaciones que hubieren asumido los Clientes
con terceros, en relación a los pagos y cobros a través de la Plataforma.
En consecuencia, el Cliente reconoce y acepta que al realizar transacciones con terceros lo hace por su
propia voluntad, prestando su consentimiento libremente y bajo su propio riesgo y responsabilidad. El
Cliente es el único y exclusivo responsable por la Instrucción de Gestión de pago frente a terceros y sus
consecuencias. En ningún caso Daruma será responsable por daño directo, lucro cesante, o por cualquier
otro daño y/o perjuicio que haya podido sufrir el Cliente, debido a las transacciones realizadas o no a
través de la Plataforma de Daruma. De la misma manera, Daruma tampoco será responsable en caso de
que uno o más Clientes o algún tercero inicien cualquier tipo de reclamo o acción legal contra otro u
otros Clientes. Por tanto, todos y cada uno de los Clientes involucrados en dichos reclamos o acciones se
obliga irrevocablemente a indemnizar y mantener indemne a Daruma, Allaria, Allaria Fondos, demás
prestadores de servicios y aliados comerciales presentes y futuros (y a cualquiera de sus respectivos
directores, funcionarios, accionistas, asesores y empleados), en forma inmediata y completa, contra
cualquier reclamo, daño, obligación, lucro cesante o gasto que sufran, total o parcialmente, como
consecuencia de cualquier acción y/u omisión en relación con las operaciones instruidas, incluyendo, pero
sin limitar, multas, costos, gastos, intereses, honorarios y demás desembolsos derivados de posibles
incumplimientos del Cliente.
En virtud de que Daruma es ajeno a la obligación que dio origen a la Instrucción de Gestión de pago,
Daruma no será responsable ni verificará la causa que dio origen a la obligación, importe o cualquier otra
circunstancia relativa a dicha Instrucción de Gestión de pago, ni respecto de la existencia, calidad,
cantidad, funcionamiento, estado, integridad o legitimidad de los bienes o servicios ofrecidos, adquiridos
o enajenados por el Cliente y pagados utilizando Daruma, así como tampoco sobre de la capacidad para
contratar de aquellos con los Clientes y la veracidad de los datos identificatorios por ellos ingresados.

3- Entrega de los Fondos, Ejecución de la Instrucción de Gestión de pago, Retiro de los
Fondos, Anulación, Cancelación o Desconocimiento de los Fondos y Responsabilidad por los
Fondos.

a) Entrega de los Fondos por el Cliente. Daruma procederá a cumplir con la Instrucción de Gestión de
pago, una vez que el Cliente, envíe los fondos a través de cualquiera de los medios disponibles a tal fin y
autorizados por Daruma, o cuando tenga los fondos disponibles en su Cuenta, caso contrario no se
procesará dicha solicitud.
La acreditación de los Fondos en la Cuenta del Cliente se realizará dentro de los cuatro (4) días hábiles
contados desde que Daruma reciba la autorización del medio de pago utilizado en la transacción.
Daruma realizará los esfuerzos razonables para asegurar el cumplimiento del plazo antes indicado. No
obstante ello, el Cliente entiende que, por determinados factores, en su mayoría externos a Daruma, se
pueden originar retrasos, motivo por el cual el Cliente exime a Daruma de toda responsabilidad por los
inconvenientes o perjuicios derivados.
Daruma ingresará en la Cuenta del Cliente el importe resultante de, descontar del valor que
efectivamente haya sido acreditado por el medio de pago utilizado, la tarifa por servicios de gestión y
demás gastos, ello independientemente del monto declarado por el Cliente en aquellos supuestos en que
el medio de pago elegido permita realizar una declaración por un monto de dinero distinto al realmente
ingresado.
En su Cuenta, el Cliente sólo puede recibir envíos de dinero o realizar pagos a través de las herramientas
habilitadas en la Plataforma. Si Daruma considera que posiblemente un Cliente podría estar utilizando de
manera irregular los mecanismos para agregar fondos, Daruma podrá aplicar lo dispuesto en el apartado
l) de la Sección 4 de estos Términos y Condiciones.
En igual sentido, por razones de seguridad, los Fondos que se acrediten en la Cuenta del Cliente y/o que
estén en proceso de ser acreditados (sin importar el medio de pago por el que se hubiera efectuado la
transacción) podrán permanecer indeterminadamente indisponibles cuando, a exclusivo criterio de
Daruma, existan irregularidades, cualquier otro acto contrario a los presentes Términos y Condiciones
y/o sospechas de violación de preceptos legales por los cuales Daruma deba responder.
Toda información sobre los fondos disponibles y acreditados en la Cuenta debe ser siempre verificada por
el Cliente a través de la Plataforma de Daruma, accediendo a www.app.midaruma.com.ar con su
dirección de e-mail y Clave de Ingreso personal e intransferible. Daruma y/o prestadores de servicios con
quien contrate y/o aliados comerciales, no serán responsables por las conductas del Cliente que se
originen o vinculen con información contenida en correos electrónicos falsos o que aparenten provenir de
Daruma y que sean enviados por terceros sin relación con Daruma, ni con prestadores de servicios con
quien contrate, ni con sus aliados comerciales; como así tampoco responderán por cualquier consecuencia
dañosa derivada de
tales conductas, ni por la circulación de los mencionados correos electrónicos falsos.
b) Ejecución de las Instrucciones respecto a los Fondos. Daruma ejecutará la Instrucción ingresada por
el Cliente, según se trate de: (i) gestión de cobro y acreditación de los Fondos en su Cuenta; o (ii) gestión
de pago (sea ésta inmediato o diferido) y débito de su cuenta; u (iii) otros servicios de gestión.
Respecto a la Instrucción de Gestión de pago Diferido, el hecho de que el Cliente pueda cargar la orden
a través de la plataforma, no implica que tal gestión de pago sea autorizada y ejecutada por Daruma el
día del efectivo pago. Para que dicho pago se efectúe deberá cumplir con lo indicado en el inciso anterior
(esto es Fondos suficientes y disponibilidad de los mismos).
En cuanto a la carga de la Instrucción de Gestión de pago, el Cliente podrá instruir en cualquier momento,
sin embargo, Daruma las admitirá hasta cierta hora del día en que deba ejecutarse la operación (en
adelante, “horario de corte”), quedando aquellas instrucciones enviadas con posterioridad al horario de
corte, pendientes de gestión hasta el día hábil posterior, sin perjuicio de que Daruma pueda rechazarlas
sin necesidad de justificación de causa.
c) Retiros. Una vez que los Fondos acreditados en la Cuenta del Cliente estén disponibles, el Cliente
podrá optar por: (i) retirar todo o parte del saldo disponible en su Cuenta; o (ii) mantener los Fondos para
utilizarlos en otra oportunidad.
El Cliente reconoce y acepta que: (a) el retiro de los Fondos quedará supeditado al previo pago de la
tarifa por servicio, cualquier gasto, impuesto o deuda que el Cliente mantuviere con Daruma, cualquiera
fuera su causa, (b) los retiros de Fondos se harán mediante transferencia a una cuenta bancaria o virtual
abierta en la República Argentina e instruida oportunamente por el Cliente (sin perjuicio de ello, Daruma
podrá limitar retiros a cuentas bancarias o virtuales abiertas en la República Argentina que no sean de
titularidad del Cliente que solicite el retiro), y (c) cualquier gasto originado por la transferencia estará a
cargo del Cliente.
La transferencia puede tardar hasta dos (2) días hábiles desde que el cliente ingrese la instrucción de
retiro hasta que Daruma efectivamente la ejecuta. En consecuencia, y debido a demoras originadas por
el banco, el e-mail de Daruma que confirma el dinero transferido de la cuenta del cliente hacia la cuenta
bancaria indicada, puede ser recibido por el Cliente al tercer (3°) día hábil desde que se ejecutó tal
instrucción de retiro de dinero.
La acreditación de los Fondos en la Cuenta bancaria indicada por el Cliente en la Instrucción de Gestión
de pago se realizará dentro de los cuatro (4) días hábiles contados desde que Daruma reciba la
autorización del medio de pago utilizado en la transacción.
Daruma realizará los esfuerzos razonables para asegurar el cumplimiento del plazo antes indicado. No
obstante, el Cliente entiende que, por determinados factores, en su mayoría externos a Daruma, se
pueden originar retrasos, motivo por el cual el Cliente exime a Daruma de toda responsabilidad por los
inconvenientes o perjuicios derivados.
Por razones de seguridad, Daruma podrá bloquear los retiros de Fondos de la cuenta de aquellos Clientes,
sobre los que hayan recibido reclamos y/o se haya detectado y/o haya fuertes indicios o sospechas de
que los mismos han utilizado los Servicios de Daruma para realizar actividades irregulares, y/o en contra
de los Términos y Condiciones y/o sospechas de violación de normas legales por los cuales Daruma deba
responder.
d) Anulaciones o desconocimientos de cargos efectuados por cualquier medio de pago. Pese lo
detallado en esta sección, se informa que para el caso que un envío de Fondos sea cancelado, anulado o
desconocido por el tercero, dichos importes serán descontados y debitados de la Cuenta del Cliente
destinatario de los Fondos; y en caso de que no dispusiera de los Fondos suficientes, quedará un saldo
negativo en la cuenta del Cliente destinatario de los mismos, que se deberá saldar dentro de las 24 horas
siguientes, caso contrario Daruma debitará el mencionado importe, gastos y tarifas que se hayan
originado, del o los ingreso/s futuro/s de Fondos a su cuenta. En virtud de ello, el Cliente autoriza de
forma expresa a Daruma a debitar de su cuenta los Fondos necesarios para cubrir la cancelación, anulación
o desconocimiento de transferencias de tercero, tarifas y demás gastos que se hayan generado.
Se establece que, a efectos de impugnar la devolución de los Fondos, será importante cualquier prueba
que el Cliente destinatario de los mismos pudiera aportar a Daruma en su defensa, sin que ello implique
que los Fondos sean acreditados nuevamente, ni que Daruma se comprometa a impugnar la devolución
del cargo incluso si el Cliente ha proporcionado pruebas.
El Cliente declara conocer y aceptar que el medio de pago utilizado podrá retener un pago o liquidación
ante incumplimientos del Cliente a estos Términos y Condiciones.
Sin perjuicio de que el Cliente receptor de los Fondos podrá responder ante Daruma por el importe
involucrado más cualquier otro costo o gasto aplicable, Daruma se reserva el derecho de iniciar las
medidas judiciales o extrajudiciales que estime pertinentes.
e) Responsabilidad por los Fondos. Se establece que, de conformidad con la Comunicación “A” 6859 y
6885 del Banco Central de la República Argentina, Daruma mantendrá los Fondos de las Cuentas
depositados en cuentas bancarias, a la vista, en pesos, a su nombre en los principales bancos del sistema
financiero argentino, incluyendo pero no limitado a Banco Santander Rio S.A, Citibank Argentina, Banco
Industrial S.A., Banco Hipotecario S.A., Industrial and Commercial Bank of China (Argentina) S.A., Banco
de la Nación Argentina, Banco Patagonia S.A., Banco de la Provincia de Buenos Aires, Banco de Valores
de S.A., Banco Comafi S.A. etc. (en adelante, el/los "Banco/s") y utilizará diversos agentes de
transferencia de dinero. Daruma no será responsable en ningún caso por cualquier cambio legal o
regulatorio que afecte la cuenta en la cual los Fondos sean depositados por Daruma. En dicho caso, el
Cliente deberá entregar a Daruma todas las cantidades adicionales necesarias para que Daruma pueda
cumplir con su mandato.
Los Clientes reconocen que Daruma depositará los Fondos entregados bajo la Instrucción de Gestión de
pago en la/s cuenta/s bancaria/s en los Bancos mencionados. Por lo tanto, en las situaciones descriptas
en el apartado g) de la Sección 3 de estos Términos y Condiciones, Daruma no estará obligado a seguir las
instrucciones del Cliente en caso de existir distintas opciones de reprogramación, canje, disposición,
conversión o devolución de los Fondos. En esas situaciones, Daruma tendrá el derecho a optar, a su solo
criterio, por la opción más conveniente pudiendo incluso liquidar de manera rápida, sencilla y ordenada
los Fondos depositados en el Banco aun cuando implique reducciones, quitas o esperas en los montos
depositados.
Asimismo, declaran conocer que los fondos depositados en Cuenta no constituyen depósitos en una
entidad financiera, a los fines de la Ley de Entidades Financieras, ni cuentan con ninguna de las garantías
que tales depósitos puedan gozar de acuerdo a la legislación y reglamentación aplicables en materia de
depósitos en entidades financieras.
f) Responsabilidad por el Impuesto al Débito y crédito. A fin de lograr la eficiencia del servicio de
Daruma, la cuenta recaudadora de su titularidad se encuentra exenta del impuesto sobre los créditos y 
débitos en tanto se trata de una cuenta utilizada en forma exclusiva para el desarrollo específico del
servicio electrónico de pagos y/o cobranzas por cuenta y orden de terceros.
No obstante lo anterior, las Partes acuerdan que el pago del impuesto sobre los créditos y débitos que
pudiera gravar operaciones derivadas de la prestación del Servicio, en caso de corresponder, será
considerado un impuesto a cargo del Cliente. En virtud de lo antes mencionado, el Cliente se obliga a
mantener indemne a Daruma y sus respectivos accionistas, directores, gerentes y empleados frente a
cualquier eventual reclamo que pudiera recibir por parte de la Administración Federal de Ingresos
Públicos o de cualquier otra entidad como consecuencia del impuesto antes mencionado.
g) Indemnidad. Los Clientes se obligan irrevocablemente y en virtud de los presentes Términos y
Condiciones, a indemnizar y mantener indemne a Daruma, Allaria, Allaria Fondos, demás prestadores de
servicios y aliados comerciales presentes y futuros (y a cualquiera de sus respectivos accionistas,
directores, funcionarios, asesores y empleados), en forma inmediata y completa, contra cualquier
reclamo, daño, obligación, lucro cesante o gasto que sufran, total o parcialmente, como consecuencia de
cualquier acción y/u omisión en relación con las operaciones instruidas, incluyendo, pero sin limitar,
multas, costos, gastos, intereses, honorarios y demás desembolsos derivados de posibles incumplimientos
del Cliente, que Daruma pueda sufrir de manera directa o que la Administración Federal de Ingresos
Públicos o cualquier otra entidad pudieran interponer en su contra como consecuencia y/o a causa de los
Servicios derivados de los presentes Términos y Condiciones.
Asimismo, los Clientes eximen de responsabilidad y se obligan a mantener indemne a Daruma, Allaria,
Allaria Fondos, demás prestadores de servicios y aliados comerciales presentes y futuros (y a cualquiera
de sus respectivos accionistas, directores, funcionarios, asesores y empleados) por situaciones que
pudieran afectar a los Bancos donde se encuentren depositados los Fondos de las Cuentas, como así
también por situaciones políticas y económicas que pudieran presentarse en el país y que sean ajenas a
Daruma. En estos casos, los Clientes no podrán imputarles responsabilidad alguna, ni exigir el reintegro
del dinero o pago por lucro cesante, en virtud de perjuicios resultantes de este tipo de situaciones.
4- Condiciones generales de contratación
a) Capacidad. Sólo podrán celebrar Instrucciones de Gestión de pago aquellas personas que tengan
capacidad legal para contratar y estén debidamente registrados como Clientes de Daruma. No podrán
registrarse ni utilizar los servicios menores de edad, personas que no tengan capacidad para contratar o
aquellos Clientes que hayan sido suspendidos o inhabilitados del sistema de Daruma, temporal o
definitivamente.
b) Usos Prohibidos. Si Daruma considera que hay una sospecha o indicio de la utilización de sus servicios
para alguna actividad prohibida por la ley o sus Términos y Condiciones, Daruma podrá rechazar, cancelar
o suspender una Instrucción de Gestión de pago, y/o bloquear temporalmente el acceso y uso de una
Cuenta y/o la utilización o disponibilidad de funcionalidades y/o cancelar definitivamente una Cuenta.
En tal caso, el Cliente se obliga irrevocable e ilimitadamente, a indemnizar y mantener indemne a
Daruma, Allaria, Allaria Fondos, demás prestadores de servicios y aliados comerciales presentes y futuros
(y a cualquiera de sus respectivos accionistas, directores, funcionarios, asesores y empleados), en forma
inmediata y completa, contra cualquier reclamo, daño y perjuicio que pueda ocasionar, a cuyos efectos
Daruma se reserva el derecho de iniciar las acciones judiciales o extrajudiciales que estime pertinentes.
En igual sentido, el Cliente será exclusiva e ilimitadamente responsable por los perjuicios que su conducta
pueda causar a los restantes Clientes de los servicios de Daruma.
c) Utilización del servicio por cuenta propia. Solamente se permite utilizar los Servicios en nombre
propio y no en representación y/o por cuenta de terceros. El Cliente no revenderá los Servicios a terceros.
d) Daruma no es entidad financiera. Se deja expresamente aclarado que Daruma no es una entidad
financiera ni presta al Cliente ningún servicio bancario o cambiario. Daruma sólo brinda un servicio de
gestión de pagos por cuenta y orden de los Clientes según las condiciones establecidas en los Términos y
Condiciones.
e) Intereses. Los Fondos acreditados en la Cuenta no generan intereses y el Cliente podrá disponer
libremente de ellos para realizar las operaciones descriptas en el presente, ello una vez que los Fondos
resulten acreditados en su Cuenta conforme los plazos y mecanismos indicados, estén disponibles y en
general cumplan con las reglas determinadas y/o a determinarse por Daruma.
f) Tarifa por Servicio de gestión de pagos. Por la utilización del Servicio de gestión de pago, el Cliente
acepta pagar a Daruma una tarifa por el Servicio, la que podrá ser fija o variable (en adelante, la
"Tarifa"). La Tarifa vigente será informada en un documento al correo electrónico registrado por el
Cliente. En virtud de ello, el Cliente autoriza a Daruma a descontar y retener la Tarifa o cualquier otro
importe adeudado de los Fondos disponibles en su Cuenta. Inclusive a descontar y retener de los Fondos
disponibles en su Cuenta cualquier importe adeudado a prestadores de servicios y/o aliados comerciales
de Daruma, en el marco de acuerdos de servicios suscriptos.
g) Seguridad y Confidencialidad de datos personales. Sin perjuicio de lo establecido en el Apartado a)
de la Sección 1 de los presentes Términos y Condiciones, Daruma no venderá, alquilará ni negociará con
otras empresas la información personal de los Clientes. Sin embargo, Daruma podrá compartir dicha
información con proveedores de servicios de valor agregado que se integren dentro del sitio o mediante
links a otros sitios de Internet, para atender necesidades de los Clientes relacionadas con los Servicios
que suministra Daruma. Toda la información personal transmitida se hace a través de una página de
Internet segura que protege y encripta la información.
La información personal se almacena en servidores o medios magnéticos que mantienen altos estándares
de seguridad e integridad y aseguran la trazabilidad de las operaciones.
h) Limitación de Responsabilidad por el Servicio y/o la Plataforma. Daruma no garantiza el acceso y
uso continuado o ininterrumpido de su Plataforma y/o del Servicio. El sistema puede eventualmente no
estar disponible debido a dificultades técnicas o fallas de Internet en los links o por cualquier otra
circunstancia ajena a Daruma. Los Clientes no podrán imputarle responsabilidad alguna a Daruma, ni
exigir resarcimiento alguno, en virtud de perjuicios resultantes de las mencionadas dificultades, así como
por cualquier otra clase de daños, incluyendo daños indirectos, especiales o consecuentes que surjan o
experimenten los Clientes, incluso en el caso que dichas fallas afecten los montos que deban ser pagados,
acreditados o transferidos.
i) Licencia Limitada. Todos los derechos intelectuales e industriales, sobre el Sitio, Códigos, desarrollo,
software, hardware, dominio, logos, emblemas, logotipos, diseños, estructura, contenidos, información,
etc. son de propiedad de Daruma y/o sus accionistas, sociedades controlantes y/o controladas. En ningún
caso se entenderá que el Cliente tendrá algún tipo de derecho sobre los mismos.
Los logos, emblemas, logotipos, diseños, estructura etc. de los medios de pago permitidos por Daruma
son de propiedad exclusiva de dicho medio de pago. Los Clientes declaran conocer y aceptar que su uso
está permitido únicamente con el objeto de servir como medio de pago, y en ningún caso se entenderá 
que el Cliente tendrá algún tipo de derecho sobre los mismos. Los Clientes no utilizarán las marcas, logos,
emblemas, logotipos, diseños, estructuras, etc. Asimismo, los Clientes deberán abstenerse de realizar
cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del valor de dichas
marcas, o utilizarlas en forma contraria a la moral y buenas costumbres.
j) Modificaciones de los Términos y Condiciones del Servicio de gestión de pago. Daruma podrá
modificar en cualquier momento los Términos y Condiciones del Servicio de gestión de pago, notificando
al Cliente a través de la publicación en el sitio de una versión actualizada de dichos Términos y
Condiciones, con expresión de la fecha de la última modificación. Todos los términos modificados
entrarán en vigor a los 10 (diez) días corridos de su publicación, excepto que en ese plazo el Cliente
comunicara por e-mail que no acepta los nuevos Términos y Condiciones quedando entonces disuelto el
vínculo contractual. Caso contrario y vencido este plazo, se considerará que el Cliente acepta los nuevos
Términos y Condiciones que regirán el vínculo entre las partes.
k) Cesión. El Cliente no podrá ceder, transferir, delegar o disponer de los derechos u obligaciones
derivados del presente Contrato ni total ni parcialmente. Daruma queda expresamente autorizado por el
Cliente, a ceder, transferir, delegar o disponer de los derechos u obligaciones derivados del presente
Contrato o de su posición contractual, total o parcialmente, sin restricciones de ningún tipo, ni necesidad
de otra autorización particular del Cliente.
l) Terminación del Contrato. Daruma y el Cliente podrán en cualquier oportunidad terminar la relación
contractual sin expresión de causa alguna, lo que implicará el cierre de la Cuenta del Cliente, pero no
suspenderá el cumplimiento a todas las solicitudes de Gestión de pago ya instruidas por el Cliente y
autorizadas por Daruma al momento de notificar la rescisión. A los efectos de ejercer esta facultad, es
necesario que la parte que pretende la terminación del contrato no adeude a la otra ni a terceros
involucrados en las operaciones el cumplimiento de alguna obligación.
Asimismo, Daruma podrá, en caso de incumplimiento del Cliente a estos Términos y Condiciones o a la
legislación aplicable en la materia, dar por terminada la prestación del Servicio sin ningún tipo de aviso
previo, reservándose el derecho de reclamar los daños y perjuicios que tal incumplimiento le haya
causado.
m) Notificaciones. Serán válidas y el Cliente acepta expresamente que todas las notificaciones realizadas
a los Clientes por Daruma sean dirigidas (i) a la dirección de correo electrónico registradas de todos los
firmantes (domicilio especial electrónico), y/o (ii) a la Cuenta del Cliente.
n) Domicilios. Se fija como domicilio de Daruma en 25 de mayo 277, Piso 12, Ciudad de Buenos Aires. El
domicilio constituido por los Clientes a los fines del de la solicitud de Gestión de pago es el domicilio
registrado por los Clientes ante Daruma a través de la Plataforma.
o) Jurisdicción y ley aplicable. Este acuerdo estará regido en todos sus puntos por las leyes vigentes en
la Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez,
interpretación, alcance o cumplimiento, será sometida a los tribunales Ordinarios en lo Comercial de la
Ciudad Autónoma de Buenos Aires.


Nota a Allaria Ledesma & Cía para compartir legajo con Daruma e Instrucción


Sres. Allaria Ledesma y Cía.
                            A través de la presente, solicito expresamente tengan a bien
compartir a Daruma Pagos S.A. CUIT: 30-71615568-0 mi legajo de cliente obrante en vuestra entidad, así
como la información y documentación en él contenido. El motivo de esta solicitud radica simplificar la
gestión de la apertura, evitar la duplicación en la información aportada y optimizar la tarea de
cumplimiento, atento ser cliente de ambas compañías y al poder Uds dar fé de mi identidad y facultades
al ser sujetos obligados UIF.
Asimismo, informo por este medio que aquellas instrucciones de
Rescate o Suscripción en FCI T+0 que reciban de parte de Daruma, para mi cuenta comitente, son por mi
cuenta y orden, y en virtud del mandato permanente que otorgué a ellos para colocar aquellos fondos
disponibles de mi cuenta de Daruma Pagos S.A.
                              Desde ya muchas gracias.
                              Saluda Atte
                </pre>
                <br>
                

                <button @click="aceptarModalTerminos()" class="aceptoLosTerminos">
                    Ok
                </button>
            </div>
        </div>
</transition>





<transition class="loadingcomponent-container-standard" name="fade-standard">
    <div class="modal-fade-standard" v-if="loadingShow">
        <div class="modal-content-completar-standard">
            <div class="loading-container-standard">
                <img class="loading-standard" src="../assets/loading.gif" alt="">
            </div>
        </div>
    </div> 
</transition>







</div>

</template>

<script>
// import terminosDarumaMarket from '../components/terminosDarumaMarket.vue'
// import terminosDarumaSin from '../components/terminosDarumaSin.vue'
export default {
    data(){
        return{
            preguntaDaruma: true,
            daruma: false,
            preguntaFondo: false,
            modalFondo: false,
            moneyMarket: false,
            sinMoneyMarket: false,
            token: null,
            estructura_compleja_api: null,
            entidad_publica_api: null,
            grupo_economico_api: null,
            pais_residencia_api: null,
            tipo_sociedad_api: null,
            daruma_api: false,
            daruma_terminos_api: false,
            daruma_legajo_api: false,
            money_market_api: false,
            loadingShow: false,
            logoContainerFirst: true,
            showTerminos: false,
            showTerminosCondiciones: false,
            confirmarDisabled: true,
            date: new Date
        }
    },
    mounted() {             
      this.token = localStorage["token"]

      this.estructura_compleja_api = this.stringToBoolean(localStorage["estructura_compleja"])
      this.entidad_publica_api = this.stringToBoolean(localStorage["entidad_publica"])
      this.grupo_economico_api = this.stringToBoolean(localStorage["grupo_economico"])
      this.pais_residencia_api = localStorage["pais_residencia"]
      this.tipo_sociedad_api = localStorage["tipo_sociedad"]

      
    },
    components:{
    //   terminosDarumaMarket,
    //   terminosDarumaSin
    },
    methods:{
        stringToBoolean(string) {
          switch(string.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
          }
        },
        reload(){
          this.$router.go(0)
        },
        checkTerminosDaruma(e){
            var checked = e.target.checked

            if (checked == true){
                this.confirmarDisabled = false
                this.$refs.confirmarDarumaButton.style.backgroundColor = "#0C5297";
            } else {
                this.confirmarDisabled = true
                this.$refs.confirmarDarumaButton.style.backgroundColor = "#89AACC";            
            }
            // confirmarDisabled
        },
        listoDaruma(){

            var data = {
                pais_residencia: this.pais_residencia_api,
                grupo_economico: this.grupo_economico_api,
                estructura_compleja: this.estructura_compleja_api,
                entidad_publica: this.entidad_publica_api,
                tipo_sociedad: this.tipo_sociedad_api,
                daruma: true,
                daruma_terminos: true,
                daruma_legajo: true,
                money_market: true,
                finalizar: false,
                dia_hora_daruma: this.date.toLocaleString()
            }

            const options = {
            method: 'POST',
            data,
            headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
            url: `https://bkpj.allaria.com.ar/api/v1/empresa/`,
            };
            this.loadingShow = true
            this.axios(options)
            .then((result) => {
                this.result = result
                this.$router.push("/listo")
            })
            .catch((error) => {
                this.error = error
                this.loadingShow = false
            })
        },
        showModalTerminos(){
          if (this.showTerminos == false){
            this.showTerminos = true
          } else {
            this.showTerminos = false
          }
        },
        conocer(){
            // window.open = 'https://landing.darumapagos.com/';

            window.open("https://landing.darumapagos.com/", "_blank");
        },
        modalTerminosText(){
            this.showModalTerminos()
            this.showTerminosCondiciones = true
        },
        aceptarModalTerminos(){
            this.showTerminosCondiciones = false
            this.showModalTerminos()
        },
        otroMomento(){
            var data = {
                pais_residencia: this.pais_residencia_api,
                grupo_economico: this.grupo_economico_api,
                estructura_compleja: this.estructura_compleja_api,
                entidad_publica: this.entidad_publica_api,
                tipo_sociedad: this.tipo_sociedad_api,
                daruma: false,
                daruma_terminos: false,
                daruma_legajo: false,
                money_market: false,
                finalizar: false,
                dia_hora_daruma: this.date.toLocaleString() 
            }

            const options = {
            method: 'POST',
            data,
            headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
            url: `https://bkpj.allaria.com.ar/api/v1/empresa/`,
            };
            // this.loadingShow = true
            this.axios(options)
            .then((result) => {
                this.result = result
                this.$router.push("/listo")
            })
            .catch((error) => {
                this.error = error
                // this.loadingShow = false
            })
        },        
    }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *, p, li{
    font-family: 'Lato', sans-serif;
  }


  .fade-enter-from{
      opacity: 0;
  }
  .fade-enter-to{
      opacity: 1;
  }
  .fade-enter-active{
      transition: all 1s ease;
  }
  .fade-leave-from{
      opacity: 1;
  }
  .fade-leave-to{
      opacity: 0;
  }
  .fade-leave-active{
      transition: all 1s ease;
  }



.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.modal-content{
    width: 25%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.modalText{
  width: 80%;
  font-size: 14px;
  font-weight: bolder;
  padding: 10px;
  margin: 0 auto;
}

  .comp-container{
    /* padding-top: 20px; */
    width: 30%;
    min-height: 700px;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

.header{
    background-color: #5BABBE;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}


.checkCircle{
    font-size:40px;
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right:10px;
    margin-left:15px;
}


.listo{
    text-align: left;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
     font-size: 16px;
    font-weight: 600;
}


.subtitulo{
    text-align: left;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 5px;
    letter-spacing: 0.14em;
    font-size: 15px;
}

.aclaracionDaruma{
    font-family: Lato;
    font-style: Bold;
    font-size: 13px;
    line-height: 17px;
    line-height: 100%;
    vertical-align: Top;
    color: #000000;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 10px;
}

  .allaria-img{
      width: 23%;
      margin-top: 40px;
      margin-bottom: 40px;
      margin-right: 25px;
  }

  .daruma-img{
      width: 35%;
      margin-top: 40px;
      margin-bottom: 40px;
  }

  ul{
      list-style: none;
      padding-left: 30px;
      padding-right: 30px;
  }

  li{
    font-style: Regular;
    font-size: 14px;
    line-height: 17px;
    line-height: 100%;
    vertical-align: Top;
    margin-bottom: 25px;
    margin-right: 30px;
    margin-left: 20px;
    color: #000000;
    font-weight: 600;
    text-align: left;
  }

  ul li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: red; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-size:25px;
  }

  .abrir-cuenta{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
  }

  .conocer{
    background-color: #FBFBFB;
    color: #0C5297;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: solid 1px #0C5297;
    margin-bottom: 20px;
  }

  .otro-momento{
    background-color: #FBFBFB;
    color: #0C5297;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
  }

.cerrar-modal{
    background-color: #89AACC;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.aceptoLosTerminos{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.modal-titulo{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
}

.modal-subtitulo{
    font-size: 18px;
    color: #000000;
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
}

.terminos-modal-titulo{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
}

.terminos-modal-subtitulo{
    font-size: 18px;
    color: #000000;
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
    max-height: 700px;
    overflow-y: scroll;
    white-space: pre-wrap;
}

.span-terminos{
    font-size: 14px;
}

.modal-activator{
    color: #2200cc;
}

.modal-activator:hover{
    text-decoration: underline;
}

.radio-container{
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
}



@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
  .modal-content{
    width: 90%;
  }
  /* .cerrar-modal{
    width: 150px;
  } */
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
  .modal-content{
    width: 90%;
  }
  .cerrar-modal{
    width: 300px;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

}

@media only screen and (max-width: 340px){
  .logo-container{
      width: 190px;
  }
  .dropdown-button{
    display: flex;
    justify-content: center;
  }
  .pregunta{
    width: 200px;
    margin: 0;
  }
  .opciones{
    width: 215px;
  }

  .abrir-cuenta{
    width: 200px;
  }
  .conocer{
    width: 200px;
  }
  .otro-momento{
    width: 200px;
  }

  .boton-volver{
    width: 215px;
  }
  .comp-container{
      overflow-x: hidden;
  }
  .allaria-logo-img{
    width: 50%;
  }

  .daruma-logo-img{
    width: 50%;
  }
  .cerrar-modal{
    width: 150px;
  }

  .aceptoLosTerminos{
    width: 200px;
  }
}

</style>