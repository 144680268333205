<template>

  <div class="paso2">


    <div class="comp-container">



      <h1>
        Paso 2
      </h1>

      <span class="titulo">
        Datos iniciales
      </span>

      <div class="dot-div">
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="this-dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
        <fa icon="circle" class="dot" />
      </div>



        <div class="cuitCuil-container">
          <span class="cuitInvalido">{{ cuitInvalido }}</span>
          <input :disabled="desactivarInputCuit" type="number" v-model="cuitCuil" class="cuitCuil" :placeholder="'CUIT'" @change="buscarCuitCuil()" >
          <h6 class="aclaracion">Introducir el CUIT sin guiones ni puntos</h6>
        </div>

        <div v-if="juridica">

        <div v-show="cuitValido" class="razonSocial-container">
          <input :disabled="desactivarRC" v-model="razonSocial" class="razonSocial" :placeholder="'Razon Social'" >
        </div>

        <div v-show="cuitValido" class="rubro-container">
          <span class="text-rubro">
            ¿Cuál es el rubro o actividad principal de la empresa?
          </span>

          <input v-model="rubroSearch" class="rubroSearch" :placeholder="'Buscar...'" >
        </div>

        <div v-show="cuitValido" class="actividad-container">
          <div style="display: flex;" v-for="actividad in filteredActividades" :key="actividad.descripcion">
            <button @click="elegirActividad($event)" ref="{{ actividad.descripcion }}" class="actividad">{{ actividad.descripcion }}</button>
          </div>
        </div>

        <input class="otraActividad" v-if="showInputOtros" v-model="otraActividad" type="text" placeholder='Escriba la actividad de la empresa...'>

        <!-- <div class="cuitError" v-show="cuitError">
          <span class="cuitErrorTest">Su CUIT no se encuentra registrado<br>en nuestras bases de datos, por favor,<br> vuelva a intentarlo o comuníquese con<br>empresas@allaria.com.ar</span>
        </div> -->
      </div>

        <div class="cuitError" v-show="personaError">
          <span class="cuitErrorTest">De acuerdo a nuestras bases de datos, el CUIT ingresado corresponde a una persona humana, para continuar con la apertura de cuenta, diríjase a la <a @click="link()" class="link">apertura de persona física</a></span>
        </div>


        <div class="cuitError" v-show="entradaDuplicadaCuit">
          <span class="cuitErrorTest">Este CUIT ya se encuentra en nuestras bases, por favor comuníquese con <a @click="mailto()" class="link">empresas@allaria.com.ar</a></span>
        </div>

      
        <div class="cuitError" v-show="cuitError">
          <span class="cuitErrorTest">Su CUIT no se encuentra registrado<br>en nuestras bases de datos, por favor,<br> vuelva a intentarlo o comuníquese con<br><a @click="mailtoAllaria()" class="link">empresas@allaria.com.ar</a></span>
        </div>


        <button :disabled="desactivar" @click="continuar()" class="continuar">
          Continuar
        </button>



    </div>

    <transition name="fade">
        <div class="modal-fade" v-show="completarError">
            <div class="modal-content-completar">

                <p class="completar">
                    Por favor, verifique haber completado todos los campos antes de avanzar
                </p>

                <button @click="cerrarModalError()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
    </transition>

    <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loadingShow">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" :src="'/images/loading.gif'" alt="">
                </div>
            </div>
        </div>
    </transition>

  </div>




</template>

<script>
export default {
  data(){
    return {
      cuitCuil: null,
      cuitValido: true,
      cuitInvalido: null,
      cuitError: false,
      personaError: null,
      razonSocial: null,
      desactivarRC: true,
      desactivar: false,
      rubroSearch: '',
      esPersona: false,
      juridica: true,
      token: null,
      actividades: [],
      loadingShow: false,
      desactivarInputCuit: false,
      completarError: false,
      entradaDuplicadaCuit: false,
      showInputOtros: false,
      otraActividad: null,
    }
  },
  components:{

  },
  mounted() {


    this.token = localStorage.getItem('token')


      const options = {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
        url: `https://bkpj.allaria.com.ar/api/v1/lista-actividades/`,
      };
      this.axios(options)
        .then((result) => {
          this.actividades = result.data.results
      })
      .catch(() => {
        this.loadingShow = false
      })
  },
  computed:{
    filteredActividades(){
      return this.actividades.filter(actividad => {
        return actividad.descripcion.toLowerCase().match(this.rubroSearch.toLowerCase())
      })
    }
  },
  methods: {
    link(){
      window.location.href = "https://apertura.allaria.com.ar/"
    },
    validarCuit(sCUIT){
            var aMult = '5432765432';
            aMult = aMult.split('');


            if (sCUIT)
            {
                var aCUIT = sCUIT.split('');
                // aCUIT = aCUIT.filter(function(value, index, arr){
                aCUIT = aCUIT.filter(function(value){
                    return value != '-';
                });
            }

            if (sCUIT && aCUIT.length == 11) {
                var iResult = 0;
                var i = 0
                for (i = 0; i <= 9; i++)
                {
                    iResult += aCUIT[i] * aMult[i];
                }
                iResult = (iResult % 11);
                iResult = 11 - iResult;

                if (iResult == 11)
                    iResult = 0;
                if (iResult == 10)
                    iResult = 9;

                if (iResult == aCUIT[10] || sCUIT == "___")
                {
                    return true;
                }
            }
            return false;
    },
    mailto(){
      window.location.href = 'mailto:empresas@allaria.com.ar';
    },
    async buscarCuitCuil(){


      this.cuitValido = this.validarCuit(this.cuitCuil)

      if(this.cuitValido == false){
          this.loadingShow = true
          this.cuitInvalido = "CUIT inválido"
          this.desactivar = true
          this.loadingShow = false
      } else {
            this.loadingShow = true

            this.cuitInvalido = null
            this.desactivar = false

            this.cuitValido = true
            this.cuitError = false
            this.desactivar = false

            let data = {
              cuit: this.cuitCuil
            }


            const options = {
              method: 'POST',
              data,
              headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
              url: `https://bkpj.allaria.com.ar/api/v1/validar-cuit/`,
            };


            this.loadingShow = true
            this.axios(options)
              .then((result) => {

                // this.razonSocial = result.data["razon_social"]
                if (result.data["razon_social_afip"] == ""){
                  this.razonSocial = result.data["razon_social_nosis"]
                  this.desactivarInputCuit = true
                } else {
                  this.razonSocial = result.data["razon_social_afip"]
                  this.desactivarInputCuit = true
                }

                if(result.data["tipo_persona_afip"] != "J" || result.data["tipo_persona_afip"] == ""){
                  if (result.data["tipo_persona_nosis"] != "J" || result.data["tipo_persona_nosis"] == "") {
                    this.esPersona = true
                  } else {
                    this.esPersona = false
                  }

                } else {
                  this.esPersona = false
                }

                if (this.esPersona == true) {
                  this.personaError = true
                  this.juridica = false
                  this.desactivar = true
                  this.loadingShow = false

                } else {
                  this.personaError = false
                  this.juridica = true
                  this.desactivar = false
                  this.loadingShow = false
                  this.cuitError = false
                }

                this.loadingShow = false

                let data = new FormData
                    data.append('dni', this.cuitCuil)
                    data.append('nombre', this.razonSocial)
                    data.append('persona', 'ORGANISATION')

                const worldcheckData = {
                  method: 'POST',
                  data,
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Token ${this.token}` },
                  url: `https://bkpj.allaria.com.ar/api/v1/worldcheck/`,
                };
                
                this.axios(worldcheckData)
                  .then(() => {
                  })
                  .catch(() => {
                    console.warn("error worldcheck")
                  })

            })
            .catch((error) => {

              if (error.response.status === 406){
                // this.esPersona = true
                this.personaError = true
                this.juridica = false
                this.loadingShow = false
                this.desactivar = true

                var l = Math.pow(10, Math.floor(Math.log(this.cuitCuil)/Math.log(10)));
                var b = Math.floor(this.cuitCuil/l);
                var digit = b-Math.floor(b/10)*10

                if(digit == 3){
                  // this.esPersona = true
                  this.personaError = false
                  this.juridica = false
                  this.loadingShow = false
                  this.desactivar = true
                  this.cuitError = true
                }
              }

              if (error.response.data.error == "Entrada duplicada") {
                this.esPersona = false
                this.personaError = false
                this.cuitError = false
                // this.entradaDuplicadaCuit = true
                this.juridica = false
                this.loadingShow = false
                this.desactivar = true
              }


              this.loadingShow = false

            })
              if (this.esPersona == true) {
                this.personaError = true
                this.juridica = false
              } else {
                this.personaError = false
                this.juridica = true
              }
        // } else {
        //   if (result.data.error) {
        //         this.esPersona = false
        //         this.personaError = false
        //         // this.entradaDuplicadaCuit = true
        //         this.juridica = false
        //         this.loadingShow = false
        //         this.desactivar = true
        //   }
        // }

















        // })
        // .catch((error) => {
      
        // })

      }





    },
    mailtoAllaria(){
      window.location.href = 'mailto:empresas@allaria.com.ar';
    },
    elegirActividad(e){
      let actividadElegida = e.target.innerText
      this.rubroSearch = actividadElegida


      if (this.rubroSearch.toLowerCase() == "otros") {
        this.showInputOtros = true
      } else {
        this.showInputOtros = false
      }
    },
    validarDatos(){

      if (this.cuitCuil == null || this.cuitCuil == ""){
        this.completarError = true
      }

      if (this.rubroSearch == null || this.rubroSearch == ""){
        this.completarError = true
      }

      if (this.rubroSearch.toLowerCase() == "otros") {
        if (this.otraActividad == null || this.otraActividad == ""){
          this.completarError = true
        }
      }

      if (this.completarError == true) {
        return false
      } else {
        return true
      }
    },
    continuar(){
      if (this.validarDatos() == true) {

      let data = {
        descripcion: this.rubroSearch,
        // actividad: this.rubroSearch,
        otra_actividad: this.otraActividad
      }


      const options = {
        method: 'POST',
        data,
        headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
        url: `https://bkpj.allaria.com.ar/api/v1/actividad/`,
      };
      this.loadingShow = true
      this.axios(options)
        .then(() => {
          this.$router.push("/Paso3")
          this.loadingShow = false
      })
      .catch(() => {
        this.loadingShow = false
      })


      }
    },
    cerrarModalError(){
      this.completarError = false
    },
  },

  watch: {
    cuitCuil: function(){
      var validarCuitVivo = this.validarCuit(this.cuitCuil)

      if (validarCuitVivo == true || validarCuitVivo == 0){
        this.personaError = false
        this.juridica = true
        this.desactivar = false
        this.loadingShow = false
        this.entradaDuplicadaCuit = false
        this.cuitError = false
      }
    },
  }
}
</script>

<style scoped>

/* take away input number arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}


.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}

.modal-content-completar{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.completar{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

  .comp-container{
    padding-top: 20px;
    min-height: 700px;
    width: 30%;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
  }

  .dot-div{
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dot{
    color: #0C5297;
    opacity: 0.4;
    width: 10px;
  }

  .this-dot{
    color: #0C5297;
    width: 10px;
  }


  .cuitCuil-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .cuitCuil{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
  }

  .otraActividad{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
  }

  .cuitInvalido{
    color: red;
    margin: 0 auto;
    width: 300px;
    font-size: 14px;
    text-align: left;
  }

  .aclaracion{
    margin: 0 auto;
    width: 300px;
    text-align: left;
    margin-top: 10px;
  }

  .razonSocial-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .razonSocial{
    width: 300px;
    height: 45px;
    background: rgba(153, 153, 153, 0.2);
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
  }

  .rubro-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  .text-rubro{
    width: 320px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .rubroSearch{
    width: 300px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
  }

  .actividad-container{
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100px;
    overflow-y: scroll;
  }

  .actividad{
    /* width: 320px; */
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

  .actividad:hover{
    text-decoration: underline;
  }

.actividad-container::-webkit-scrollbar {
    width: 12px;
}

.actividad-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
    border-radius: 10px;
}

.actividad-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.continuar{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom:20px;
}



.cuitError{
  width: 300px;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 20px;
  border-radius: 5px;
  background-color: rgba(12, 82, 151, 0.1);
  overflow: visible;
  padding-top:15px;
  padding-bottom:15px;
}


.cuitErrorTest{
  width: 320px;
  color: #e63d3d;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}




.link{
  color: #0C5297;
}


.link:hover{
  text-decoration: underline;
}










@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
  .modal-content-completar{
      width: 90%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

  .close{
    display: none;
  }

}

@media only screen and (max-width: 340px){

  .cuitCuil{
    width: 215px;
  }

  .razonSocial{
    width: 215px;
  }

  .text-rubro{
    width: 215px;
  }

  .rubroSearch{
    width: 215px;
  }

  .actividad-container{
    width: 215px;
  }

  .continuar{
    width: 215px;
  }
  .cuitError{
    width: 215px;
  }
  .cuitErrorTest{
    width: 215px;
  }
  .aclaracion{
    width: 215px;
  }
  .cerrar-modal{
    width: 200px;
  }
  .comp-container{
      overflow-x: hidden;
  }

}


</style>