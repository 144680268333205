<template>
  <div class="paso6">

    <div class="comp-container">



      <h1>
        ¡Listo!
      </h1>

      
      <p class="titulo">
        Ha finalizado la carga de datos, procesaremos la información y nos contactaremos con Usted a la brevedad.
      </p> 


      <div class="logo-container">
          <img class="allaria-img" src="../assets/iconoallaria.jpg" alt="">
      </div>


      <button ref="allaria" class="allaria" @click="irAllaria()">
          Volver a la Página de Allaria
      </button>


      <br>
      <br>



      <span class="subtitulo">
        Síganos en nuestras redes sociales
      </span> 



      <div class="logos-container">
        <button><fa class="icono-fb" :icon="{ prefix: 'fab', iconName: 'facebook' }" @click="facebook()" /></button>
        <button><fa class="icono-tw" :icon="{ prefix: 'fab', iconName: 'twitter' }" @click="twitter()" /></button>
        <button><fa class="icono-li" :icon="{ prefix: 'fab', iconName: 'linkedin' }" @click="linkedin()" /></button>
        <button><fa class="icono-in" :icon="{ prefix: 'fab', iconName: 'instagram' }" @click="instagram()" /></button>

      </div>


    </div>

</div>

</template>

<script>
export default {
  mounted () {
    localStorage.accionistas = false;
  },
  methods:{
    irAllaria(){
      window.location.href = 'https://www.allaria.com.ar';
      // window.open("https://www.allaria.com.ar", "_blank");
    },
    facebook(){
      // window.location.href = 'https://www.facebook.com/allarialedesmaycia/';
      window.open("https://www.facebook.com/allarialedesmaycia/", "_blank");
    },
    twitter(){
      // window.location.href = 'https://twitter.com/allarialedesma';
      window.open("https://twitter.com/allarialedesma", "_blank");
    },
    linkedin(){
      // window.location.href = 'https://ar.linkedin.com/company/allarialedesma';
      window.open("https://ar.linkedin.com/company/allarialedesma", "_blank");
    },
    instagram(){
      // window.location.href = 'https://instagram.com/allarialedesma';
      window.open("https://instagram.com/allarialedesma", "_blank");
    },
  }
}
</script>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

  *{
    font-family: 'Lato', sans-serif;
  }

  .comp-container{
    padding-top: 20px;
    width: 30%;
    min-height: 700px;
    margin: 0 auto;
    background-color: #FBFBFB;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }

  .close{
    display: flex;
    justify-content: flex-end;
  }

  .cross{
    margin-right: 20px;
    margin-top: 15px;
  }

  h1{
    font-size: 38px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .titulo{
    font-weight: 600;
    letter-spacing: 0.14em;
    font-size: 14px;
    font-weight: bolder;
    margin-left: 50px;
    margin-right: 50px;
  }

  .subtitulo{
    font-weight: 600;
    letter-spacing: 0.14em;
    font-size: 12px;
    font-weight: bolder;
  }

  .logo-container{
      width: 300px;
      margin: 0 auto;
      margin-top: 40px;
  }

  .allaria-img{
      width: 25%;
  }

  .allaria{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-top: 40px;
    margin-bottom:20px;
  }

  .logos-container{
    width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    padding-bottom: 40px;
  }

  .icono-fb{
    font-size: 40px;
    color: #3b5998;
  }

  .icono-tw{
    font-size: 40px;
    color: #1DA1F2;
  }

  .icono-li{
    font-size: 40px;
    color: #0E76A8;
  }

  .icono-in{
    font-size: 40px;
    color: #de1b85;
  }


@media only screen and (max-width: 1170px) {
  .comp-container{
    width: 50%;
  }
}




@media only screen and (max-width: 845px) {
  .comp-container{
    width: 80%;
  }
}



@media only screen and (max-width: 600px) {
  .comp-container{
    width: 100%;
    margin-top: 0;
  }

}

@media only screen and (max-width: 340px){
  .logos-container{
    width: 215px;
  }
  .allaria{
    width: 215px;
  }

  .comp-container{
      overflow-x: hidden;
  }
}

</style>