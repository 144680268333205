<template>
        <div>



          <div class="sociedad-container">
            <p class="sociedad">
              Tipo de sociedad: Asociaciones / Fundaciones / Cooperativas / Cámaras
            </p>
          </div>



<!-- ------------------------acta estatuto / contrato social-------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarContratoSocial">
              <input id="contratoSocial" ref="contratoSocial" v-on:change="handleContratoSocial($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinContratoSocial" class="archivo-button" @click="elegirContratoSocial()">
                  {{botonContratoSocialText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<!-- -----------balance cpce--------------------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarBalanceCpce">
              <input id="balanceCpce" ref="balanceCpce" v-on:change="handleUploadBalanceCpce($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinBalanceCpce" class="archivo-button" @click="modalBalanceShow()">
                  {{botonBalanceCpceText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<transition name="fade">
        <div class="modal-fade" v-show="modalBalance">
            <div class="modal-content">

                <br>
                <h3>Balance certificado por el CPCE</h3>
                <br>
                <br>

                <p class="modalText">
                    Si todavía no cuenta con su 1er balance cerrado, por favor enviar documentación patrimonial que demuestre sus actividades al día de la fecha.
                </p>
                <br>

                <button @click="elegirBalanceCpce()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>


<!-- ---Actas con determinación de miembros del Órgano de Administración y Control y distribución de cargos------------------------------------------------------------------------------------- -->

            <div v-if="cargarActaOrgano">
              <input id="actaOrgano" ref="actaOrgano" v-on:change="handleUploadActaOrgano($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinActaOrgano" class="archivo-button" @click="elegirActaOrgano()">
                  {{botonActaOrganoText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>



<!-- ---------------poder----------------------------------------------------------------------------------------------------------------------------- -->


            <div v-if="cargarPoder">
              <input id="poder" ref="poder" v-on:change="handleUploadPoder($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinPoder" class="archivo-button" @click="modalPoderShow()">
                  {{botonPoderText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<transition name="fade">
        <div class="modal-fade" v-show="modalPoder">
            <div class="modal-content">
                <br>
                <h3>Poder de los firmantes</h3>
                <br>
                <br>

                <p class="modalText">
                    Corresponde en caso de poseer firmantes apoderados.
                </p>
                <br>

                <button @click="elegirPoder()" class="cerrar-modal">
                    Ok
                </button>

            </div>
        </div>
</transition>



<!-- ---------------------------DDJJ de sujeto obligado---------------------------------------------------------------------------------------------------------------------- -->

            <!-- <div v-if="cargarDdjj">
              <input id="ddjj" ref="ddjj" v-on:change="handleUploadDdjj($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinDdjj" class="archivo-button" @click="elegirDdjj()">
                  Subir la DDJJ de sujeto obligado
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div> -->



<!-- ---------------------------Contancia de inscripcion ante la UIF del fiduciario---------------------------------------------------------------------------------------------------------------------- -->

            <div v-if="cargarConstanciaUif">
              <input id="constanciaUif" ref="constanciaUif" v-on:change="handleConstanciaUif($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinConstanciaUif" class="archivo-button" @click="elegirConstanciaUif()">
                  {{botonConstanciaUifText}}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

<!-- ------------------------otros-------------------------------------------------------------------------------------------------------------------- -->

            <div>
              <input id="otros" ref="otros" v-on:change="handleUploadOtros($event)" type="file" style="display:none;" accept=".pdf,.docx,.doc,.docm,.txt,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg">
              <button v-if="sinOtros" class="archivo-button" @click="showModalOtros()">
                  {{ otrosText }}
              </button>
              <button v-else class="botonOk">
                <fa icon="check" class="fa-2x" />
              </button>
            </div>

            <transition name="fade">
              <div class="modal-fade" v-show="modalOtros">
                  <div class="modal-content">
                      <br>
                      <h3>Otros Archivos</h3>
                      <br>
                      <br>

                      <p class="modalText">
                          Por favor, adjunte la documentación complementaria que considere necesaria para la apertura de la cuenta.
                      </p>
                      <br>

                      <button @click="elegirOtros()" class="cerrar-modal">
                          Ok
                      </button>

                  </div>
              </div>
            </transition>







            <transition class="loadingcomponent-container-standard" name="fade-standard">
                <div class="modal-fade-standard" v-if="loadingShow">
                    <div class="modal-content-completar-standard">
                        <div class="loading-container-standard">
                            <img class="loading-standard" src="../assets/loading.gif" alt="">
                        </div>
                    </div>
                </div> 
            </transition>




            <terminos v-on:checkTerminos="checkTerminos($event)" v-on:terminosChequeados="terminosChequeados($event)" />



                  <div class="boton-volver">
                    <button @click="sociedadUnselect()" class="volver">
                      <br>
                      <fa icon="arrow-circle-left" />&nbsp;&nbsp; Volver
                    </button>
                  </div>




            <button ref="continuar" :disabled="desactivar" class="continuar" @click="continuarArchivos()">
                Continuar
            </button>


        </div>
</template>

<script>
import terminos from './terminos.vue'
export default {
  components:{
    terminos
  },
  data(){
    return {
      cargarContratoSocial: true,
      contratoSocial: false,
      sinContratoSocial: true,
      botonContratoSocialText: 'Subir el Estatuto / Contrato Social (*)',
      contratoSocialContador: 0,

      cargarBalanceCpce: true,
      balanceCpce: false,
      sinBalanceCpce: true,
      botonBalanceCpceText: 'Subir el Balance certificado por el CPCE o aclaracion (*)',
      balanceCpceContador: 0,

      cargarPoder: true,
      poder: false,
      sinPoder: true,
      botonPoderText: 'Subir el Poder de los Firmantes',
      poderContador: 0,

      cargarActaOrgano: true,
      actaOrgano: false,
      sinActaOrgano: true,
      botonActaOrganoText: 'Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*)',
      actaOrganoContador: 0,

      cargarDdjj: true,
      ddjj: false,
      sinDdjj: true,
      botonDdjjText: 'Subir el Poder de los Firmantes',
      ddjjContador: 0,

      cargarConstanciaUif: true,
      constanciaUif: false,
      sinConstanciaUif: true,
      botonConstanciaUifText: 'Subir la Constancia de inscripcion ante la UIF',
      constanciaUifContador: 0,

      modalBalance: null,
      modalPoder: null,
      desactivar: null,
      todosArchivos: null,
      todosTerminos: false,
      validacion: false,
      tipoSociedad: "afcc",

      contratoSocial_api: null,
      contratoSocial2_api: null,
      contratoSocial3_api: null,
      contratoSocial4_api: null,
      contratoSocial5_api: null,
      contratoSocial6_api: null,
      contratoSocial7_api: null,
      contratoSocial8_api: null,
      contratoSocial9_api: null,
      contratoSocial10_api: null,

      balanceCpce_api: null,
      balanceCpce2_api: null,
      balanceCpce3_api: null,
      balanceCpce4_api: null,
      balanceCpce5_api: null,
      balanceCpce6_api: null,
      balanceCpce7_api: null,
      balanceCpce8_api: null,
      balanceCpce9_api: null,
      balanceCpce10_api: null,

      poder_api: null,
      poder2_api: null,
      poder3_api: null,
      poder4_api: null,
      poder5_api: null,
      poder6_api: null,
      poder7_api: null,
      poder8_api: null,
      poder9_api: null,
      poder10_api: null,

      actaOrgano_api: null,
      actaOrgano2_api: null,
      actaOrgano3_api: null,
      actaOrgano4_api: null,
      actaOrgano5_api: null,
      actaOrgano6_api: null,
      actaOrgano7_api: null,
      actaOrgano8_api: null,
      actaOrgano9_api: null,
      actaOrgano10_api: null,

      ddjj_api: null,

      constanciaUif_api: null,
      constanciaUif2_api: null,
      constanciaUif3_api: null,
      constanciaUif4_api: null,
      constanciaUif5_api: null,
      constanciaUif6_api: null,
      constanciaUif7_api: null,
      constanciaUif8_api: null,
      constanciaUif9_api: null,
      constanciaUif10_api: null,
      
      token: null,
      ip_api: null,
      loadingShow: false,
      date: new Date,
      otros: 0,
      otros1_api: null,
      otros2_api: null,
      otros3_api: null,
      otros4_api: null,
      otros5_api: null,
      otros6_api: null,
      otros7_api: null,
      otros8_api: null,
      otros9_api: null,
      otros10_api: null,
      terminos_condiciones_api: false,
      reglamento_gestion_api: false,
      mandato_comafi_api: false,
      mandato_valores_api: false,
      nota_retencion_api: false,
      otrosText: "Otros [0]",
      sinOtros: true,
      modalOtros: false,
      modalYa: 0
    }
  },
  mounted() {             
    this.token = localStorage["token"]

    localStorage.tipo_sociedad = this.tipoSociedad;

    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          this.ip_api = ip
    });

    this.estructura_compleja_api = this.stringToBoolean(localStorage["estructura_compleja"])
    this.entidad_publica_api = this.stringToBoolean(localStorage["entidad_publica"])
    this.grupo_economico_api = this.stringToBoolean(localStorage["grupo_economico"])
    this.pais_residencia_api = localStorage["pais_residencia"]
    this.tipo_sociedad_api = localStorage["tipo_sociedad"]
  },
  methods: {
    formatFechaHora(){
      let date_ob = new Date();

      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
    },
    stringToBoolean(string) {
        switch(string.toLowerCase().trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
      }
    },
    sociedadUnselect(){
      this.$emit("sociedadUnselect")
    },
    scrollToTop() {
        window.scrollTo(0,0);
    },
    elegirContratoSocial(){
      document.getElementById("contratoSocial").click()
    },
    checkContratoSocial(){
      this.contratoSocial = true
      this.sinContratoSocial = false
      this.validarArchivos()
    },
    elegirBalanceCpce(){
      this.modalBalanceShow()
      document.getElementById("balanceCpce").click()
    },
    checkBalanceCpce(){
      this.balanceCpce = true
      this.sinBalanceCpce = false
      this.validarArchivos()
    },
    elegirPoder(){
      this.modalPoderShow()
      document.getElementById("poder").click()
    },
    checkPoder(){
      this.poder = true
      this.sinPoder = false
    },
    elegirActaOrgano(){
      document.getElementById("actaOrgano").click()
    },
    checkActaOrgano(){
      this.actaOrgano = true
      this.sinActaOrgano = false
      this.validarArchivos()
    },
    elegirDdjj(){
      document.getElementById("ddjj").click()
    },
    checkDdjj(){
      this.ddjj = true
      this.sinDdjj = false
    },
    elegirConstanciaUif(){
      document.getElementById("constanciaUif").click()
    },
    checkConstanciaUif(){
      this.constanciaUif = true
      this.sinConstanciaUif = false
    },
    modalBalanceShow(){
      if (this.modalBalance == true) {
        this.modalBalance = false
      } else {
        this.modalBalance = true
        this.scrollToTop()
      }
    },
    modalPoderShow(){
      if (this.modalPoder == true) {
        this.modalPoder = false
      } else {
        this.modalPoder = true
        this.scrollToTop()
      }
    },
    validarArchivos(){
      // obligatorios: contratoSocial y balance Cpce
      if (this.balanceCpce == true && this.contratoSocial == true &&  this.actaOrgano == true){
        this.todosArchivos = true
        this.validarDatos()
      } else {
        this.todosArchivos = false
        this.validarDatos()
      }
    },
    checkTerminos(e){
      this.todosTerminos = e
      this.validarDatos()
    },
    validarDatos(){
      if (this.todosTerminos == true && this.todosArchivos == true){
        this.validacion = true
        this.desactivar = false

        this.$refs.continuar.style.backgroundColor = "#0C5297";

      } else {
        this.validacion = false
        this.desactivar = true

        this.$refs.continuar.style.backgroundColor = "#89aacc";
      }
    },
    handleContratoSocial(files) {
      let fotos = files.target.files
      if (this.contratoSocialContador == 0){
        this.contratoSocial_api = fotos[0];
      }
      if (this.contratoSocialContador == 1){
        this.contratoSocial2_api = fotos[0];
      }
      if (this.contratoSocialContador == 2){
        this.contratoSocial3_api = fotos[0];
      }
      if (this.contratoSocialContador == 3){
        this.contratoSocial4_api = fotos[0];
      }
      if (this.contratoSocialContador == 4){
        this.contratoSocial5_api = fotos[0];
      }
      if (this.contratoSocialContador == 5){
        this.contratoSocial6_api = fotos[0];
      }
      if (this.contratoSocialContador == 6){
        this.contratoSocial7_api = fotos[0];
      }
      if (this.contratoSocialContador == 7){
        this.contratoSocial8_api = fotos[0];
      }
      if (this.contratoSocialContador == 8){
        this.contratoSocial9_api = fotos[0];
      }
      if (this.contratoSocialContador == 9){
        this.contratoSocial10_api = fotos[0];
      }

      if (this.contratoSocial_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [1]"
        this.contratoSocialContador = 1
        this.contratoSocial = true
      }
      if (this.contratoSocial2_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [2]"
        this.contratoSocialContador = 2
      }
      if (this.contratoSocial3_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [3]"
        this.contratoSocialContador = 3
      }
      if (this.contratoSocial4_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [4]"
        this.contratoSocialContador = 4
      }
      if (this.contratoSocial5_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [5]"
        this.contratoSocialContador = 5
      }
      if (this.contratoSocial6_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [6]"
        this.contratoSocialContador = 6
      }
      if (this.contratoSocial7_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [7]"
        this.contratoSocialContador = 7
      }
      if (this.contratoSocial8_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [8]"
        this.contratoSocialContador = 8
      }
      if (this.contratoSocial9_api != null){
        this.botonContratoSocialText = "Subir Contrato Social (modif. y sesiones de cuotas) (*) [9]"
        this.contratoSocialContador = 9
      }
      if (this.contratoSocial10_api != null){
        this.sinContratoSocial = false
      }
      this.validarArchivos()

      // this.checkContratoSocial()
    },
    handleUploadBalanceCpce(files) {
      let fotos = files.target.files
      if (this.balanceCpceContador == 0){
        this.balanceCpce_api = fotos[0];
      }
      if (this.balanceCpceContador == 1){
        this.balanceCpce2_api = fotos[0];
      }
      if (this.balanceCpceContador == 2){
        this.balanceCpce3_api = fotos[0];
      }
      if (this.balanceCpceContador == 3){
        this.balanceCpce4_api = fotos[0];
      }
      if (this.balanceCpceContador == 4){
        this.balanceCpce5_api = fotos[0];
      }
      if (this.balanceCpceContador == 5){
        this.balanceCpce6_api = fotos[0];
      }
      if (this.balanceCpceContador == 6){
        this.balanceCpce7_api = fotos[0];
      }
      if (this.balanceCpceContador == 7){
        this.balanceCpce8_api = fotos[0];
      }
      if (this.balanceCpceContador == 8){
        this.balanceCpce9_api = fotos[0];
      }
      if (this.balanceCpceContador == 9){
        this.balanceCpce10_api = fotos[0];
      }

      if (this.balanceCpce_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [1]"
        this.balanceCpceContador = 1
        this.balanceCpce = true
      }
      if (this.balanceCpce2_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [2]"
        this.balanceCpceContador = 2
      }
      if (this.balanceCpce3_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [3]"
        this.balanceCpceContador = 3
      }
      if (this.balanceCpce4_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [4]"
        this.balanceCpceContador = 4
      }
      if (this.balanceCpce5_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [5]"
        this.balanceCpceContador = 5
      }
      if (this.balanceCpce6_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [6]"
        this.balanceCpceContador = 6
      }
      if (this.balanceCpce7_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [7]"
        this.balanceCpceContador = 7
      }
      if (this.balanceCpce8_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [8]"
        this.balanceCpceContador = 8
      }
      if (this.balanceCpce9_api != null){
        this.botonBalanceCpceText = "Subir el Balance certificado por el CPCE o aclaracion (*) [9]"
        this.balanceCpceContador = 9
      }
      if (this.balanceCpce10_api != null){
        this.sinBalanceCpce = false
      }
      this.validarArchivos()

      // this.checkBalanceCpce()
    },
    handleUploadPoder(files) {
      let fotos = files.target.files
      if (this.poderContador == 0){
        this.poder_api = fotos[0];
      }
      if (this.poderContador == 1){
        this.poder2_api = fotos[0];
      }
      if (this.poderContador == 2){
        this.poder3_api = fotos[0];
      }
      if (this.poderContador == 3){
        this.poder4_api = fotos[0];
      }
      if (this.poderContador == 4){
        this.poder5_api = fotos[0];
      }
      if (this.poderContador == 5){
        this.poder6_api = fotos[0];
      }
      if (this.poderContador == 6){
        this.poder7_api = fotos[0];
      }
      if (this.poderContador == 7){
        this.poder8_api = fotos[0];
      }
      if (this.poderContador == 8){
        this.poder9_api = fotos[0];
      }
      if (this.poderContador == 9){
        this.poder10_api = fotos[0];
      }

      if (this.poder_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [1]"
        this.poderContador = 1
        this.poder = true
      }
      if (this.poder2_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [2]"
        this.poderContador = 2
      }
      if (this.poder3_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [3]"
        this.poderContador = 3
      }
      if (this.poder4_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [4]"
        this.poderContador = 4
      }
      if (this.poder5_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [5]"
        this.poderContador = 5
      }
      if (this.poder6_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [6]"
        this.poderContador = 6
      }
      if (this.poder7_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [7]"
        this.poderContador = 7
      }
      if (this.poder8_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [8]"
        this.poderContador = 8
      }
      if (this.poder9_api != null){
        this.botonPoderText = "Subir el Poder de los Firmantes [9]"
        this.poderContador = 9
      }
      if (this.poder10_api != null){
        this.sinPoder = false
      }
    },
    handleUploadActaOrgano(files) {
      let fotos = files.target.files

      if (this.actaOrganoContador == 0){
        this.actaOrgano_api = fotos[0];
      }
      if (this.actaOrganoContador == 1){
        this.actaOrgano2_api = fotos[0];
      }
      if (this.actaOrganoContador == 2){
        this.actaOrgano3_api = fotos[0];
      }
      if (this.actaOrganoContador == 3){
        this.actaOrgano4_api = fotos[0];
      }
      if (this.actaOrganoContador == 4){
        this.actaOrgano5_api = fotos[0];
      }
      if (this.actaOrganoContador == 5){
        this.actaOrgano6_api = fotos[0];
      }
      if (this.actaOrganoContador == 6){
        this.actaOrgano7_api = fotos[0];
      }
      if (this.actaOrganoContador == 7){
        this.actaOrgano8_api = fotos[0];
      }
      if (this.actaOrganoContador == 8){
        this.actaOrgano9_api = fotos[0];
      }
      if (this.actaOrganoContador == 9){
        this.actaOrgano10_api = fotos[0];
      }

      if (this.actaOrgano_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [1]"
        this.actaOrganoContador = 1
        this.actaOrgano = true
      }
      if (this.actaOrgano2_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [2]"
        this.actaOrganoContador = 2
      }
      if (this.actaOrgano3_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [3]"
        this.actaOrganoContador = 3
      }
      if (this.actaOrgano4_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [4]"
        this.actaOrganoContador = 4
      }
      if (this.actaOrgano5_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [5]"
        this.actaOrganoContador = 5
      }
      if (this.actaOrgano6_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [6]"
        this.actaOrganoContador = 6
      }
      if (this.actaOrgano7_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [7]"
        this.actaOrganoContador = 7
      }
      if (this.actaOrgano8_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [8]"
        this.actaOrganoContador = 8
      }
      if (this.actaOrgano9_api != null){
        this.botonActaOrganoText = "Subir el Acta con determinación de miembros del órgano de administración, control y distribución de cargos (*) [9]"
        this.actaOrganoContador = 9
      }
      if (this.actaOrgano10_api != null){
        this.sinActaOrgano = false
      }

      this.validarArchivos()

      // this.checkActaOrgano()
    },
    handleUploadDdjj(files) {
      let fotos = files.target.files
      this.ddjj_api = fotos[0];

      this.checkDdjj()
    },
    handleConstanciaUif(files) {
      let fotos = files.target.files
      if (this.constanciaUifContador == 0){
        this.constanciaUif_api = fotos[0];
      }
      if (this.constanciaUifContador == 1){
        this.constanciaUif2_api = fotos[0];
      }
      if (this.constanciaUifContador == 2){
        this.constanciaUif3_api = fotos[0];
      }
      if (this.constanciaUifContador == 3){
        this.constanciaUif4_api = fotos[0];
      }
      if (this.constanciaUifContador == 4){
        this.constanciaUif5_api = fotos[0];
      }
      if (this.constanciaUifContador == 5){
        this.constanciaUif6_api = fotos[0];
      }
      if (this.constanciaUifContador == 6){
        this.constanciaUif7_api = fotos[0];
      }
      if (this.constanciaUifContador == 7){
        this.constanciaUif8_api = fotos[0];
      }
      if (this.constanciaUifContador == 8){
        this.constanciaUif9_api = fotos[0];
      }
      if (this.constanciaUifContador == 9){
        this.constanciaUif10_api = fotos[0];
      }

      if (this.constanciaUif_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [1]"
        this.constanciaUifContador = 1
        this.constanciaUif = true
      }
      if (this.constanciaUif2_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [2]"
        this.constanciaUifContador = 2
      }
      if (this.constanciaUif3_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [3]"
        this.constanciaUifContador = 3
      }
      if (this.constanciaUif4_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [4]"
        this.constanciaUifContador = 4
      }
      if (this.constanciaUif5_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [5]"
        this.constanciaUifContador = 5
      }
      if (this.constanciaUif6_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [6]"
        this.constanciaUifContador = 6
      }
      if (this.constanciaUif7_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [7]"
        this.constanciaUifContador = 7
      }
      if (this.constanciaUif8_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [8]"
        this.constanciaUifContador = 8
      }
      if (this.constanciaUif9_api != null){
        this.botonConstanciaUifText = "Subir la Constancia de inscripcion ante la UIF [9]"
        this.constanciaUifContador = 9
      }
      if (this.constanciaUif10_api != null){
        this.sinConstanciaUif = false
      }
    },
    terminosChequeados(e){
      this.terminos_condiciones_api = e["terminos_condiciones"]
      this.reglamento_gestion_api = e["reglamento_gestion"]
      this.mandato_comafi_api = e["mandato_comafi"]
      this.mandato_valores_api = e["mandato_valores"]
      this.nota_retencion_api = e["nota_retencion"]
    },
    handleUploadOtros(files){
      let fotos = files.target.files

      if (this.otros == 0){
        this.otros1_api = fotos[0];
      }
      if (this.otros == 1){
        this.otros2_api = fotos[0];
      }
      if (this.otros == 2){
        this.otros3_api = fotos[0];
      }
      if (this.otros == 3){
        this.otros4_api = fotos[0];
      }
      if (this.otros == 4){
        this.otros5_api = fotos[0];
      }
      if (this.otros == 5){
        this.otros6_api = fotos[0];
      }
      if (this.otros == 6){
        this.otros7_api = fotos[0];
      }
      if (this.otros == 7){
        this.otros8_api = fotos[0];
      }
      if (this.otros == 8){
        this.otros9_api = fotos[0];
      }
      if (this.otros == 9){
        this.otros10_api = fotos[0];
      }


      if (this.otros1_api != null){
        this.otrosText = "Otros [1]"
        this.otros = 1
      }
      if (this.otros2_api != null){
        this.otrosText = "Otros [2]"
        this.otros = 2
      }
      if (this.otros3_api != null){
        this.otrosText = "Otros [3]"
        this.otros = 3
      }
      if (this.otros4_api != null){
        this.otrosText = "Otros [4]"
        this.otros = 4
      }
      if (this.otros5_api != null){
        this.otrosText = "Otros [5]"
        this.otros = 5
      }
      if (this.otros6_api != null){
        this.otrosText = "Otros [6]"
        this.otros = 6
      }
      if (this.otros7_api != null){
        this.otrosText = "Otros [7]"
        this.otros = 7
      }
      if (this.otros8_api != null){
        this.otrosText = "Otros [8]"
        this.otros = 8
      }
      if (this.otros9_api != null){
        this.otrosText = "Otros [9]"
        this.otros = 9
      }
      if (this.otros10_api != null){
        this.sinOtros = false
      }
    },
    elegirOtros(){
      this.modalOtros = false
      document.getElementById("otros").click()
    },
    showModalOtros(){
      if (this.modalYa == 1) {
        this.elegirOtros()
      } else {
        this.modalYa = 1
        this.modalOtros = true
      }
    },
    continuarArchivos(){

        let data = new FormData()

        data.append('contrato_social', this.contratoSocial_api)
        if (this.contratoSocial2_api != null) {
          data.append('contrato_social_2', this.contratoSocial2_api)
        }
        if (this.contratoSocial3_api != null) {
          data.append('contrato_social_3', this.contratoSocial3_api)
        }
        if (this.contratoSocial4_api != null) {
          data.append('contrato_social_4', this.contratoSocial4_api)
        }
        if (this.contratoSocial5_api != null) {
          data.append('contrato_social_5', this.contratoSocial5_api)
        }
        if (this.contratoSocial6_api != null) {
          data.append('contrato_social_6', this.contratoSocial6_api)
        }
        if (this.contratoSocial7_api != null) {
          data.append('contrato_social_7', this.contratoSocial7_api)
        }
        if (this.contratoSocial8_api != null) {
          data.append('contrato_social_8', this.contratoSocial8_api)
        }
        if (this.contratoSocial9_api != null) {
          data.append('contrato_social_9', this.contratoSocial9_api)
        }
        if (this.contratoSocial10_api != null) {
          data.append('contrato_social_10', this.contratoSocial10_api)
        }

        data.append('balance_certificado_cpce', this.balanceCpce_api)
        if (this.balanceCpce2_api != null) {
          data.append('balance_certificado_cpce_2', this.balanceCpce2_api)
        }
        if (this.balanceCpce3_api != null) {
          data.append('balance_certificado_cpce_3', this.balanceCpce3_api)
        }
        if (this.balanceCpce4_api != null) {
          data.append('balance_certificado_cpce_4', this.balanceCpce4_api)
        }
        if (this.balanceCpce5_api != null) {
          data.append('balance_certificado_cpce_5', this.balanceCpce5_api)
        }
        if (this.balanceCpce6_api != null) {
          data.append('balance_certificado_cpce_6', this.balanceCpce6_api)
        }
        if (this.balanceCpce7_api != null) {
          data.append('balance_certificado_cpce_7', this.balanceCpce7_api)
        }
        if (this.balanceCpce8_api != null) {
          data.append('balance_certificado_cpce_8', this.balanceCpce8_api)
        }
        if (this.balanceCpce9_api != null) {
          data.append('balance_certificado_cpce_9', this.balanceCpce9_api)
        }
        if (this.balanceCpce10_api != null) {
          data.append('balance_certificado_cpce_10', this.balanceCpce10_api)
        }

        if (this.poder_api != null) {
          data.append('poder', this.poder_api)
        }
        if (this.poder2_api != null) {
          data.append('poder_2', this.poder2_api)
        }
        if (this.poder3_api != null) {
          data.append('poder_3', this.poder3_api)
        }
        if (this.poder4_api != null) {
          data.append('poder_4', this.poder4_api)
        }
        if (this.poder5_api != null) {
          data.append('poder_5', this.poder5_api)
        }
        if (this.poder6_api != null) {
          data.append('poder_6', this.poder6_api)
        }
        if (this.poder7_api != null) {
          data.append('poder_7', this.poder7_api)
        }
        if (this.poder8_api != null) {
          data.append('poder_8', this.poder8_api)
        }
        if (this.poder9_api != null) {
          data.append('poder_9', this.poder9_api)
        }
        if (this.poder10_api != null) {
          data.append('poder_10', this.poder10_api)
        }


        if (this.actaOrgano_api != null) {
          data.append('actas_miembros', this.actaOrgano_api)
        }
        if (this.actaOrgano2_api != null) {
          data.append('actas_miembros_2', this.actaOrgano2_api)
        }
        if (this.actaOrgano3_api != null) {
          data.append('actas_miembros_3', this.actaOrgano3_api)
        }
        if (this.actaOrgano4_api != null) {
          data.append('actas_miembros_4', this.actaOrgano4_api)
        }
        if (this.actaOrgano5_api != null) {
          data.append('actas_miembros_5', this.actaOrgano5_api)
        }
        if (this.actaOrgano6_api != null) {
          data.append('actas_miembros_6', this.actaOrgano6_api)
        }
        if (this.actaOrgano7_api != null) {
          data.append('actas_miembros_7', this.actaOrgano7_api)
        }
        if (this.actaOrgano8_api != null) {
          data.append('actas_miembros_8', this.actaOrgano8_api)
        }
        if (this.actaOrgano9_api != null) {
          data.append('actas_miembros_9', this.actaOrgano9_api)
        }
        if (this.actaOrgano10_api != null) {
          data.append('actas_miembros_10', this.actaOrgano10_api)
        }









        if (this.ddjj_api != null) {
          data.append('declaracion_sujeto_obligado', this.ddjj_api)
        }

        if (this.constanciaUif_api != null) {
          data.append('constancia_uif', this.constanciaUif_api)
        }
        if (this.constanciaUif2_api != null) {
          data.append('constancia_uif_2', this.constanciaUif2_api)
        }
        if (this.constanciaUif3_api != null) {
          data.append('constancia_uif_3', this.constanciaUif3_api)
        }
        if (this.constanciaUif4_api != null) {
          data.append('constancia_uif_4', this.constanciaUif4_api)
        }
        if (this.constanciaUif5_api != null) {
          data.append('constancia_uif_5', this.constanciaUif5_api)
        }
        if (this.constanciaUif6_api != null) {
          data.append('constancia_uif_6', this.constanciaUif6_api)
        }
        if (this.constanciaUif7_api != null) {
          data.append('constancia_uif_7', this.constanciaUif7_api)
        }
        if (this.constanciaUif8_api != null) {
          data.append('constancia_uif_8', this.constanciaUif8_api)
        }
        if (this.constanciaUif9_api != null) {
          data.append('constancia_uif_9', this.constanciaUif9_api)
        }
        if (this.constanciaUif10_api != null) {
          data.append('constancia_uif_10', this.constanciaUif10_api)
        }



        if (this.otros1_api != null) {
          data.append('archivo_otro_1', this.otros1_api)
        }
        if (this.otros2_api != null) {
          data.append('archivo_otro_2', this.otros2_api)
        }
        if (this.otros3_api != null) {
          data.append('archivo_otro_3', this.otros3_api)
        }
        if (this.otros4_api != null) {
          data.append('archivo_otro_4', this.otros4_api)
        }
        if (this.otros5_api != null) {
          data.append('archivo_otro_5', this.otros5_api)
        }
        if (this.otros6_api != null) {
          data.append('archivo_otro_6', this.otros6_api)
        }
        if (this.otros7_api != null) {
          data.append('archivo_otro_7', this.otros7_api)
        }
        if (this.otros8_api != null) {
          data.append('archivo_otro_8', this.otros8_api)
        }
        if (this.otros9_api != null) {
          data.append('archivo_otro_9', this.otros9_api)
        }
        if (this.otros10_api != null) {
          data.append('archivo_otro_10', this.otros10_api)
        }

        data.append('termino_condiciones', this.terminos_condiciones_api)
        data.append('reglamento_gestion', this.reglamento_gestion_api)
        data.append('mandato_comafi', false)
        data.append('mandato_valores', false)
        data.append('nota_retencion', this.nota_retencion_api)


        // data.append('actas_designacion_gerentes', null)
        // data.append('actas_miembros', null)
        // data.append('declaracion_sujeto_obligado', null)
        // data.append('constancia_uif', null)
        // data.append('contrato_fideicomiso', null)
        // data.append('documentacion_fiduciaria', null)
        // data.append('contrato_constitutivo', null)
        // data.append('acta_determinacion_titulares', null)
        // data.append('documentacion_nomina_socios', null)
        // data.append('reglamento_copropiedad', null)
        // data.append('acta_administrador', null)
        // data.append('nomina_copropietarios', null)
        data.append('ip', this.ip_api)
        data.append('fecha_hora', this.date.toLocaleDateString())

        data.append('fecha_mandato_comafi', this.formatFechaHora())
        data.append('fecha_reglamento', this.formatFechaHora())
        data.append('fecha_negociacion', this.formatFechaHora())
        data.append('fecha_convenio', this.formatFechaHora())
        data.append('fecha_mandato_valores', this.formatFechaHora())
        data.append('fecha_tyc_allaria', this.formatFechaHora())

        const options = {
          method: 'POST',
          data,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${this.token}` },
          url: `https://bkpj.allaria.com.ar/api/v1/documentos/`,
        };
        this.loadingShow = true
        this.axios(options)
        .then(() => {

            var data = {
                pais_residencia: this.pais_residencia_api,
                grupo_economico: this.grupo_economico_api,
                estructura_compleja: this.estructura_compleja_api,
                entidad_publica: this.entidad_publica_api,
                tipo_sociedad: this.tipo_sociedad_api,
                daruma: false,
                daruma_terminos: false,
                daruma_legajo: false,
                money_market: false,
                finalizar: true,
                dia_hora_daruma: this.date.toLocaleString()
            }

            const options = {
            method: 'POST',
            data,
            headers: { 'Content-Type': 'application/json', Authorization: `Token ${this.token}` },
            url: `https://bkpj.allaria.com.ar/api/v1/empresa/`,
            };
            this.loadingShow = true
            this.axios(options)
            .then((result) => {
                this.result = result
                this.$router.push("/ultimos-pasos")
            })
            .catch(() => {
              this.loadingShow = false
            })
        })
        .catch(() => {
          this.loadingShow = false
        })
    }
    
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
  font-family: 'Lato', sans-serif;
}



.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    transition: all 1s ease;
}
.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: all 1s ease;
}

.modal-fade{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
}

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


.modal-content{
    width: 35%;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cerrar-modal{
    background-color: #0C5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}
.modalText{
    padding-left: 5%;
    padding-right: 5%;
    color: #000000;
    text-align: left;
    line-height: 20px;
    font-size: 13px;
}

.archivo-button{
  background-color: #0C5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;

}

.botonOk{
  background-color: #5BABBE;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
}






.continuar{
  background-color: #89aacc;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 40px;
  margin-bottom:20px;
}

.sociedad-container{
  width: 300px;
  margin: 0 auto;
}

.sociedad{
  font-weight: bolder;
  font-size: 14px;
  text-align: left;
}

  .boton-volver{
    width: 300px;
    margin: 0 auto;
    display:flex;
    justify-content: left;
  }


  .volver{
    background-color: #FBFBFB;
    border: none;
    font-size: 14px;
    color: #0C5297;
  }

  @media only screen and (max-width: 845px) {
  .modal-content{
    width: 90%;
  }
}

@media only screen and (max-width: 340px){

  .continuar{
    width: 215px;
  }
  .archivo-button{
    width: 215px;
  }
  .botonOk{
    width: 215px;
  }
  .sociedad-container{
    width: 215px;
  }
  .boton-volver{
    width: 215px;
  }
  .cerrar-modal{
    width: 200px;
  }

}
</style>